import {
  Id,
  Schedule,
  Security,
  SignalName,
  Stage,
  Status,
  TeamLead,
} from "../../../../../utils/Constant";

const HoverableRowsTableHead = () => {
  return (
    <thead>
      <tr>
        <th scope="col">{Id}</th>
        <th scope="col">Product Name</th>
        <th scope="col">Delivery Address</th>
        <th scope="col">Sample Quantity</th>
        <th scope="col">Contact Name</th>
        <th scope="col">Contact Number</th>
      </tr>
    </thead>
  );
};

export default HoverableRowsTableHead;
