import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import BuyerForm from "../Forms/FormsControl/FormValidition/BuyerForm/BrowserDefault";
import SampleRequestForm from "../Forms/FormsControl/FormValidition/SampleRequestForm/BrowserDefault";
import WorkOrderForm from "../Forms/FormsControl/FormValidition/WorkOrderForm/BrowserDefault";

const WorkOrderPage = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Work order"}
          parent={"Report"}
          title={"work order"}
        />
        {/* <Row></Row> */}
        <WorkOrderForm />
      </Container>
    </>
  );
};

export default WorkOrderPage;
