import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import SellerForm from "../Forms/FormsControl/FormValidition/SellerForm/BrowserDefault";
import SellerTable from "./SellerTable/HoverableRowsClass";
import DropdownAddForm from "../Forms/FormsControl/FormValidition/DropdownAddForm/BrowserDefault";
import DropdownTable from "./DropdownMenuTable/HoverableRowsClass";

const QuantityFormPage = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Quantity Form"}
          parent={"Manage"}
          title={"quantity"}
        />
        {/* <Row></Row> */}
        <DropdownAddForm />
        <DropdownTable
          data={[
            { name: "10M", status: "active" },
            { name: "20M", status: "inactive" },
          ]}
        />
      </Container>
    </>
  );
};

export default QuantityFormPage;
