export const productsData = [
    {
      "id": 1,
      "image": "ecommerce/02.jpg",
      "name": "Men's Jacket",
      "note": "Gray Denim Regular Men's Denim Jacket",
      "discription": "Lorate Solid men's Fashion Full Sleeves Latest Jacket for women With Button Closure Long Sleeve Casual Torn Lycra Women's Top..",
      "discountPrice": "35.00",
      "status": "none",
      "price": 120.0,
      "stock": "Out of stock",
      "review": "(120 review)",
      "category": "Men's Shirt",
      "rating": 4,
      "colors": ["White", "gray"],
      "size": ["M", "L", "XL"],
      "tags": ["Diesel", "Hudson", "Lee"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "black",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "pink",
          "images": "ecommerce/03.jpg"
        }
      ]
    },
    {
      "id": 2,
      "image": "ecommerce/14.jpg",
      "name": "Men's T-shirt",
      "note": "Solid men's T-shirt",
      "discription": "Lorate Solid Men's Fashion Full Sleeves Latest Jacket for Men With Button Closure Long Sleeve Casual Torn Lycra Women's Top.",
      "discountPrice": "320.00",
      "status": "Sale",
      "ribbonClassName": "ribbon ribbon-danger",
      "price": 260.0,
      "stock": "In stock",
      "review": "(120 review)",
      "rating": 3,
      "category": "Women's Top",
      "colors": ["green", "gray"],
      "size": ["M", "L", "XL"],
      "tags": ["Levis", "Hudson", "Lee"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/03.jpg"
        }
      ]
    },
    {
      "id": 3,
      "image": "ecommerce/01.jpg",
      "name": "Women's Top",
      "note": "Polyester Blend Women's Fit Dress",
      "discription": "Ravaiyaa - Attitude is everything Cotton Women's Dresses One Piece Button Dress.",
      "discountPrice": "110.00",
      "price": 60.0,
      "status": "50%",
      "stock": "In stock",
      "review": "(200 review)",
      "rating": 2,
      "category": "Men's Jacket",
      "colors": ["White", "gray", "blue"],
      "size": ["M", "L", "XL"],
      "tags": ["Diesel", "Spykar", "Lee"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/03.jpg"
        }
      ]
    },
    {
      "id": 4,
      "image": "ecommerce/03.jpg",
      "name": "Women's T-shirt",
      "note": "Regular Fit women's Casual Shirt",
      "discription": "Symbol women's Solid Regular Fit Full Sleeve Formal Shirt, Cotton Regular Fit women's Casual Shirt.",
      "discountPrice": "610.00",
      "price": 526.0,
      "status": "none",
  
      "stock": "In stock",
      "review": "(410 review)",
      "rating": 1,
      "category": "Men's Shirt",
      "colors": ["red", "gray", "blue"],
      "size": ["M", "L", "XL"],
      "tags": ["Lee", "Levis", "Hudson"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/03.jpg"
        }
      ]
    },
    {
      "id": 5,
      "image": "ecommerce/05.jpg",
      "name": "Women's T-shirt",
      "note": "Silk Cotton Regular Fit T-Shirt",
      "discription": "Rock Paper Scissors Womens Tank Top High Neck Cotton Top Stylish Women Top..",
      "discountPrice": "230.00",
      "price": 206.0,
      "stock": "In stock",
      "ribbonClassName": "ribbon ribbon-success ribbon-right",
      "status": "50%",
      "review": "(120 review)",
      "rating": 5,
      "category": "Women's Party Wear",
      "colors": ["green", "White", "blue"],
      "size": ["M", "L", "XL"],
      "tags": ["Diesel", "Spykar", "Denien"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/04.jpg"
        }
      ]
    },
    {
      "id": 6,
      "image": "ecommerce/06.jpg",
      "name": "T-shirt",
      "note": "Regular Fit Women's Casual Shirt",
      "discription": "Symbol women's Solid Regular Fit Full Sleeve Formal Shirt, Cotton Regular Fit women's Casual Shirt.",
      "discountPrice": "560.00",
      "price": 126.0,
      "status": "Hot",
      "ribbonClassName": "ribbon ribbon-warning",
      "stock": "Out of stock",
      "review": "(200 review)",
      "rating": 4,
      "category": "Jacket",
      "colors": ["purple", "orange", "blue"],
      "size": ["M", "L", "XL"],
      "tags": ["Lee", "Diesel", "Levis"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/04.jpg"
        }
      ]
    },
    {
      "id": 7,
      "image": "ecommerce/07.jpg",
      "name": "Men's T-shirt",
      "note": "Solid men's Dress",
      "discription": "Lorate Solid Men's Fashion Full Sleeves Latest Jacket for Men With Button Closure Long Sleeve Casual Torn Lycra men's Top.",
      "discountPrice": "210.00",
      "price": 105.0,
      "status": "50%",
      "ribbonClassName": "ribbon ribbon-warning",
      "stock": "In stock",
      "review": "(300 review)",
      "rating": 3,
      "category": "T-shirt",
      "colors": ["orange", "pink", "blue"],
      "size": ["M", "L", "XL"],
      "tags": ["Lee", "Diesel"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "blue",
          "images": "ecommerce/04.jpg"
        }
      ]
    },
    {
      "id": 8,
      "image": "ecommerce/14.jpg",
      "name": "Men's T-shirt",
      "note": "Red Regular Men's Jacket",
      "discription": "Glamcci Women's Cotton Blend Printed TreckSuit Dress, Rayon Women's Regular Top.",
      "discountPrice": "700.00",
      "price": 500.0,
      "status": "none",
      "stock": "In stock",
      "review": "(120 review)",
      "rating": 5,
      "category": "Men's Jacket",
      "colors": ["purple", "pink", "blue"],
      "size": ["M", "L", "XL"],
      "tags": ["Hudson", "Lee"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/03.jpg"
        }
      ]
    },
    {
      "id": 9,
      "image": "ecommerce/02.jpg",
      "name": "Men's Jacket",
      "note": "Gray Denim Regular Men's Denim Shirt",
      "discription": "Mylo Essentials Pre & Post Maternity/Nursing Maxi Dress with Both Sides Zipper for Easy Feeding – Garden Flowers.",
      "discountPrice": "780.00",
      "price": 626.0,
      "status": "none",
      "stock": "Out of stock",
      "review": "(200 review)",
      "rating": 5,
      "category": "Men's Shirt",
      "colors": ["White", "pink", "blue"],
      "size": ["M", "L", "XL"],
      "tags": ["Spykar"],
      "variants": [
        {
          "color": "purple",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "pink",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "blue",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "White",
          "images": "ecommerce/03.jpg"
        }
      ]
    },
    {
      "id": 10,
      "image": "ecommerce/14.jpg",
      "name": "Men's T-shirt",
      "note": "Denim Regular Men's Women's Top",
      "discription": "Symbol Men's Solid Regular Fit Full Sleeve Formal Shirt, Cotton Regular Fit Men's Casual Shirt.",
      "discountPrice": "680.00",
      "price": 500.0,
      "status": "none",
      "stock": "Out of stock",
      "review": "(410 review)",
      "rating": 4,
      "category": "Women's Top",
      "colors": ["purple", "pink", "blue"],
      "size": ["M", "L", "XL"],
      "tags": ["Hudson", "Lee"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/03.jpg"
        }
      ]
    },
    {
      "id": 11,
      "image": "ecommerce/03.jpg",
      "name": "Women's Top",
      "note": "Cotton Blend Women's Skirt",
      "discription": "Women's Classic Stretchy All Time Trendy Pleated Skirt|Western Skirt |midi Skirt| plited Lehenga.",
      "discountPrice": "480.00",
      "price": 426.0,
      "status": "none",
      "stock": "In stock",
      "review": "(120 review)",
      "rating": 5,
      "category": "Men's Jacket",
      "colors": ["White", "pink", "blue"],
      "size": ["M", "L", "XL"],
      "tags": ["Diesel", "Hudson", "Lee"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "pink",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "blue",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "gray",
          "images": "ecommerce/03.jpg"
        }
      ]
    },
    {
      "id": 12,
      "image": "ecommerce/05.jpg",
      "name": "Women's T-shirt",
      "note": "Regular Fit women's Casual Shirt",
      "discription": "Symbol Men's Solid Regular Fit Full Sleeve Formal Shirt, Cotton Regular Fit Men's Casual Shirt.",
      "discountPrice": "820.00",
      "price": 626.0,
      "status": "none",
      "stock": "In stock",
      "review": "(200 review)",
      "rating": 3,
      "category": "Man's Shirt",
      "colors": ["White", "pink", "blue"],
      "size": ["M", "L", "XL"],
      "tags": ["Diesel", "Hudson", "Lee"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "pink",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "blue",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "purple",
          "images": "ecommerce/03.jpg"
        }
      ]
    },
    {
      "id": 13,
      "image": "ecommerce/05.jpg",
      "name": "Women's T-shirt",
      "note": "Cotton Regular Fit T-Shirt",
      "discription": "Rock Paper Scissors Womens Tank Top High Neck Cotton Top Stylish Women Top.",
      "discountPrice": "120.00",
      "price": 50.0,
      "status": "none",
      "stock": "In stock",
      "review": "(300 review)",
      "rating": 3,
      "category": "Women's Party Wear",
      "colors": ["White", "pink", "blue"],
      "size": ["M", "L", "XL"],
      "tags": ["Diesel", "Hudson", "Lee"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "pink",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "blue",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "black",
          "images": "ecommerce/03.jpg"
        }
      ]
    },
    {
      "id": 14,
      "image": "ecommerce/07.jpg",
      "name": "Men's T-shirt",
      "note": "Cotton Regular Fit Men's jacket",
      "discription": "Lorate Solid Men's Fashion Full Sleeves Latest Jacket for Men With Button Closure Long Sleeve Casual Torn Lycra Women's Top..",
      "discountPrice": "180.00",
      "price": 145.0,
      "status": "50%",
      "ribbonClassName": "ribbon  ribbon-success",
      "stock": "In stock",
      "review": "(120 review)",
      "rating": 2,
      "category": "T-shirt",
      "colors": ["White", "blue", "yellow"],
      "size": ["M", "L", "XL"],
      "tags": ["Diesel", "Lee"],
      "variants": [
        {
          "color": "White",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "blue",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "yellow",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "pink",
          "images": "ecommerce/03.jpg"
        }
      ]
    },
    {
      "id": 15,
      "image": "ecommerce/08.jpg",
      "name": "Men's T-shirt",
      "note": "Regular Cotton Fit T-Shirt",
      "discription": "Rock Paper Scissors Womens Tank Top High Neck Cotton Top Stylish Women Top.",
      "discountPrice": "260.00",
      "price": 206.0,
      "status": "Hot",
      "ribbonClassName": "ribbon ribbon-right ribbon-warning",
      "stock": "In stock",
      "review": "(200 review)",
      "rating": 1,
      "category": "Men's Jacket",
      "colors": ["black", "blue", "yellow"],
      "size": ["M", "L", "XL"],
      "tags": ["Lee"],
      "variants": [
        {
          "color": "black",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "blue",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "yellow",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "yellow",
          "images": "ecommerce/03.jpg"
        }
      ]
    },
    {
      "id": 16,
      "image": "ecommerce/06.jpg",
      "name": "T-shirt",
      "note": "Denim Regular women's Jeans",
      "discription": "Mylo Essentials Pre & Post Maternity/Nursing Maxi jeans with Both Sides Zipper..",
      "discountPrice": "920.00",
      "price": 550.0,
      "status": "none",
      "stock": "Out of stock",
      "review": "(300 review)",
      "rating": 2,
      "category": "Jacket",
      "colors": ["black", "pink", "yellow"],
      "size": ["M", "L", "XL"],
      "tags": ["Hudson"],
      "variants": [
        {
          "color": "black",
          "images": "ecommerce/01.jpg"
        },
        {
          "color": "pink",
          "images": "ecommerce/04.jpg"
        },
        {
          "color": "yellow",
          "images": "ecommerce/02.jpg"
        },
        {
          "color": "blue",
          "images": "ecommerce/02.jpg"
        }
      ]
    }
  ]

  export const STAR_DATA = (
    <span>
      <i className="fa fa-star font-warning me-1"></i>
      <i className="fa fa-star font-warning me-1"></i>
      <i className="fa fa-star font-warning me-1"></i>
      <i className="fa fa-star-half-o font-warning me-1"></i>
      <i className="fa fa-star-half-o font-warning"></i>
    </span>
  );
  
  export const STAR_DATAS = (
    <span>
      <i className="fa fa-star font-warning me-1"></i>
      <i className="fa fa-star font-warning me-1"></i>
      <i className="fa fa-star font-warning me-1"></i>
      <i className="fa fa-star font-warning me-1"></i>
      <i className="fa fa-star font-warning"></i>
    </span>
  );
  
  export const PRODUCT_SLIDE_1 = [
    {
      image: "01.jpg",
      title: "Woman T-shirt",
      text: "$100.00",
      rowClass: "mb-4",
    },
    {
      image: "02.jpg",
      title: "Dream Beauty Fashion",
      text: "$150.00",
    },
    {
      image: "03.jpg",
      title: "Womens's Dress",
      text: "$200.00",
    },
  ];
  
  export const PRODUCT_SLIDE_2 = [
    {
      image: "01.jpg",
      title: "Fancy Shirt",
      text: "$100.00",
      rowClass: "mb-4",
    },
    {
      image: "02.jpg",
      title: "Fancy Shirt",
      text: "$100.00",
      rowClass: "mb-4",
    },
    {
      image: "03.jpg",
      title: "Fancy Shirt",
      text: "$100.00",
    },
  ];
  
  export const FILTER_PRODUCT_DATA = [
    {
      id: 2,
      colClass: "col-xl-6 col-sm-6",
      filterData: ["1", "2"],
    },
    {
      id: 3,
      colClass: "col-xl-4 col-sm-4",
      filterData: ["3", "4", "5"],
    },
    {
      id: 4,
      colClass: "col-xl-3 col-md-4 col-sm-5",
      filterData: ["6", "7", "8", "9"],
    },
    {
      id: 6,
      colClass: "col-xl-2 col-md-4 col-sm-5",
      filterData: ["10", "11", "12", "13", "14", "15"],
    },
  ];

  export const productSizeArray: string[] = ["M", "L", "Xl"];