import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Submit } from "../../../../../utils/Constant";
import { H6 } from "../../../../../AbstractElements";

import { useState } from "react";
import DropdownForm from "./DropdownForm";
import { Box } from "react-feather";

const INCOTerms = [
  { name: "EXW", value: "EXW" },
  { name: "FCA", value: "FCA" },
  { name: "CPT", value: "CPT" },
  { name: "CIP", value: "CIP" },
  { name: "DAP", value: "DAP" },
  { name: "DPU", value: "DPU" },
  { name: "DDP", value: "DDP" },
];

const paymentTerms = ["net 10", "net 15", "net 30", "net 60", "net 90"];

let borderedCheckBoxData = ["primary", "secondary", "success"];
const BrowserDefaultForm = () => {
  // Define a single state object to store all input values
  const [formData, setFormData] = useState({
    buyerName: "",
    productName: "",
    portofDeliver: "",
    sku: "",
    quantity: 0,
    primary: "",
    secondary: "",
    tertiary: "",
    incoterms: "",
    paymentTerms: "",
    additional: "",
    documentsRequired: null,
  });

  const handleInputChange = (fieldName: string, value: string | number) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Access the form data from the state
    console.log(formData);
    // Perform other actions, such as sending data to a server
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="g-3">
        <Col xs={12}>
          <Label>Buyer Name</Label>
          <Input
            id="exampleSelect"
            name="select"
            type="select"
            className="form-select"
            value={formData.buyerName}
            onChange={(e) => handleInputChange("buyerName", e.target.value)}
            required
          >
            <option>Choose...</option>
            <option>A</option>
            <option>B</option>
            <option>C</option>
          </Input>
        </Col>
        <Col xs={12}>
          <Label htmlFor="productName">Product Name</Label>
          <Input
            type="text"
            placeholder="Product Name"
            value={formData.productName}
            onChange={(e) => handleInputChange("productName", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label>Packaging</Label>
          <Input
            id="exampleSelect"
            name="select"
            type="select"
            className="form-select"
            value={formData.buyerName}
            onChange={(e) => handleInputChange("buyerName", e.target.value)}
            required
          >
            <option>Choose packaging...</option>
            <option>A</option>
            <option>B</option>
            <option>C</option>
          </Input>
        </Col>
        <Col xs={12}>
          <Label>Quantity (in metric tons)</Label>
          <Input
            type="number"
            placeholder="Quantity"
            value={formData.quantity}
            onChange={(e) =>
              handleInputChange("quantity", Number(e.target.value))
            }
            required
          />
        </Col>
        <Col xs={12}>
          <Label>Port of Deliver</Label>
          <Input
            type="text"
            placeholder="Port of Deliver"
            value={formData.portofDeliver}
            onChange={(e) => handleInputChange("portofDeliver", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label>SKU</Label>
          <Input
            type="text"
            placeholder="SKU"
            value={formData.sku}
            onChange={(e) => handleInputChange("sku", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label htmlFor="primary">Primary</Label>
          <Input
            type="text"
            placeholder="primary"
            value={formData.primary}
            onChange={(e) => handleInputChange("primary", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label htmlFor="secondary">Secondary</Label>
          <Input
            type="text"
            placeholder="primary"
            value={formData.secondary}
            onChange={(e) => handleInputChange("secondary", e.target.value)}
          />
        </Col>
        <Col xs={12}>
          <Label htmlFor="tertiary">Tertiary</Label>
          <Input
            type="text"
            placeholder="primary"
            value={formData.tertiary}
            onChange={(e) => handleInputChange("tertiary", e.target.value)}
          />
        </Col>

        <Col xs={12}>
          <div className="card-wrapper border rounded-3 checkbox-checked">
            <h6 className="sub-title">{"Payment Terms"}</h6>
            <div className="radio-form">
              {paymentTerms.map((term, index) => (
                <FormGroup check key={index}>
                  <Input
                    type="radio"
                    id={`flexRadioDefault${index + 1}`}
                    name="flexRadioDefault"
                    required
                  />
                  <Label check htmlFor={`flexRadioDefault${index + 1}`}>
                    {term}
                  </Label>
                </FormGroup>
              ))}
            </div>
          </div>
        </Col>
        <Col xs={12}>
          <div className="card-wrapper border rounded-3 checkbox-checked">
            <h6 className="sub-title">{"INCO Terms"}</h6>
            <div className="radio-form">
              {INCOTerms.map((term, index) => (
                <FormGroup check key={index}>
                  <Input
                    type="radio"
                    id={`flexRadioDefault${index + 1}`}
                    name="flexRadioDefault"
                    required
                  />
                  <Label check htmlFor={`flexRadioDefault${index + 1}`}>
                    {term.name}
                  </Label>
                </FormGroup>
              ))}
            </div>
          </div>
        </Col>

        <Col xs={12}>
          <Label>Documents Required</Label>
          {borderedCheckBoxData.map((data, index) => (
            <FormGroup
              key={index}
              check
              className={`checkbox checkbox-${data} mb-0`}
            >
              <Input
                type="checkbox"
                id={`BorderedCheck${data}`}
                defaultChecked={data === "primary" ? true : false}
              />
              <Label htmlFor={`BorderedCheck${data}`}>
                {data}- checkbox-{data}
              </Label>
            </FormGroup>
          ))}
        </Col>
        {/* onChange={(e) => handleInputChange("documentsRequired", e?.target?.files[0])} */}
        <Col xs={12}>
          <Label htmlFor="exampleFormControlTextarea1">Additional Info</Label>
          <Input
            type="textarea"
            id="exampleFormControlTextarea1"
            rows={3}
            defaultValue={""}
            value={formData.additional}
            onChange={(e) => handleInputChange("additional", e.target.value)}
          />
        </Col>
        <Col xs={12}>
          <Label>Brands</Label>
          <Input
            id="exampleSelect"
            name="select"
            type="select"
            className="form-select"
            value={formData.buyerName}
            onChange={(e) => handleInputChange("buyerName", e.target.value)}
            required
          >
            <option>Choose brand...</option>
            <option>A</option>
            <option>B</option>
            <option>C</option>
          </Input>
        </Col>
        <Col xs={12}>
          <Button color="primary" type="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BrowserDefaultForm;
