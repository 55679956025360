import { Row } from 'reactstrap'
import VideoTab1 from './VideoTab1'
import VideoTab2 from './VideoTab2'

const VideoTabs = () => {
  return (
    <Row>
      <VideoTab1 />
      <VideoTab2 />
    </Row>
  )
}

export default VideoTabs