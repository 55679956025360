import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import BuyerForm from "../Forms/FormsControl/FormValidition/BuyerForm/BrowserDefault";
import SampleRequestForm from "../Forms/FormsControl/FormValidition/SampleRequestForm/BrowserDefault";
import ReportRequirementForm from "../Forms/FormsControl/FormValidition/ReportRequirementForm/BrowserDefault";

const SampleRequirementFormPage = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Requirement Form"}
          parent={"Report"}
          title={"Requirement"}
        />
        {/* <Row></Row> */}
        <ReportRequirementForm />
      </Container>
    </>
  );
};

export default SampleRequirementFormPage;
