import { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, TabContent, TabPane, Table, Nav, NavItem, NavLink, Button, Modal, ModalHeader, ModalBody, Label, ModalFooter, Input } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import axios from "axios";
import { API_BASE_URL, API_KEY, S3_BUCKET_NAME, S3_REGION, S3_ACCESS_KEY, S3_SECRET_KEY, getCookie, sessionExpired, API_SINGZYS_BASE_URL, API_SIGNZYS_ACCESS_TOKEN } from "../Helper/utils";
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faLink } from '@fortawesome/free-solid-svg-icons';
import AWS from 'aws-sdk';
import Select from 'react-select';
import PdfDownload from "../../CommonElements/PdfDownload/PdfDownload.tsx";
import {
  Address,
  CreditCheck,
  Criminal,
  DrugTest,
  Education,
  Employment,
  Identity,
  PoliceVerification,
  Reference,
  cyptoKey,
} from "../../utils/Constant";
import CryptoJS from "crypto-js"

const config = {
  accessKeyId: S3_ACCESS_KEY,
  secretAccessKey: S3_SECRET_KEY,
  region: S3_REGION,
};

const s3 = new AWS.S3(config);
const userType = localStorage.getItem("type")

const customStyles1 = {
  control: base => ({
    ...base,
    height: 53,
    minHeight: 35,
    backgroundColor: "white",
    overflow: "hidden",
  })
};


const Verification = () => {
  const [pendingVerificationData, setPendingVerificationData] = useState([]);
  const [inProgressVerificationData, setInProgressVerificationData] = useState([]);
  const [completedVerificationData, setCompletedVerificationData] = useState([]);
  const [rejectedVerificationData, setRejectedVerificationData] = useState([]);
  const [clientName, setClientName] = useState([]);
  const [clientId, setClientId] = useState([]);

  const [educationDetails, setEducationDetails] = useState([]);
  const [educationModal, setEducationModal] = useState(false);

  const [employmentDetails, setEmploymentDetails] = useState([]);
  const [employmentModal, setEmploymentModal] = useState(false);

  const [addressDetails, setAddressDetails] = useState([]);
  const [addressModal, setAddressModal] = useState(false);

  const [criminalDetails, setCriminalDetails] = useState([]);
  const [criminalModal, setCriminalModal] = useState(false);

  const [identityDetails, setIdentityDetails] = useState([]);
  const [identityModal, setIdentityModal] = useState(false);

  const [referenceDetails, setReferenceDetails] = useState([]);
  const [referenceModal, setReferenceModal] = useState(false);

  const [drugTestDetails, setDrugTestDetails] = useState([]);
  const [drugTestModal, setDrugTestModal] = useState(false);

  const [creditCheckDetails, setCreditCheckDetails] = useState([]);
  const [creditCheckModal, setCreditCheckModal] = useState(false);

  const [policeVerificationDetails, setPoliceVerificationDetails] = useState([]);
  const [policeVerificationModal, setPoliceVerificationModal] = useState(false);

  const [verificationId, setVerificationId] = useState('');
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const [agentData, setAgentData] = useState([]);
  const [state, setState] = useState({
    agentId: [],
    topIndex: '-1'
  })

  const [educationDetailsEdit, setEducationDetailsEdit] = useState({
    courseDegree: "",
    college: "",
    days: "",
    enrollmentNo: "",
    yearOfPassing: "",
    verifier: "",
    contactNo: "",
    modeOfVerification: "",
    remarks: "",
    marksCard: "",
    image1: "",
  });

  const [employmentDetailsEdit, setEmploymentDetailsEdit] = useState({
    companyName: "",
    tenure: "",
    designation: "",
    employeeCode: "",
    salary: "",
    reportingManager: "",
    duties: "",
    issues: "",
    performanceAtWork: "",
    reasonForLeaving: "",
    eligibilityToRetire: "",
    verifier: "",
    contactNo: "",
    modeOfVerification: "",
    remarks: "",
    image1: "",
  });

  const [addressDetailsEdit, setAddressDetailsEdit] = useState({
    name: "",
    fatherName: "",
    dob: "",
    confirmationAddress: "",
    typeOfAddress: "",
    contactNumber: "",
    periodOfStay: "",
    typeOfProperty: "",
    idProof: "",
    specialComments: "",
    image1: "",
  });

  const [criminalDetailsEdit, setCriminalDetailsEdit] = useState({
    fatherName: "",
    dob: "",
    magistrate: "",
    sessions: "",
    highCourt: "",
    durationOfRecordsChecked: "",
  });

  const [identityDetailsEdit, setIdentityDetailsEdit] = useState({
    fullName: "",
    fatherName: "",
    dob: "",
    pancardNo: "",
    image1: "",
  });

  const [referenceDetailsEdit, setReferenceDetailsEdit] = useState({
    respondentName: "",
    designation: "",
    organisation: "",
  });

  const [drugTestDetailsEdit, setDrugTestDetailsEdit] = useState({
    name: "",
    dob: "",
    bloodSamplesCollected: "",
    collectionLocation: "",
    testResult: "",
  });

  const [creditCheckDetailsEdit, setCreditCheckDetailsEdit] = useState({
    name: "",
    dob: "",
    mobileNumber: "",
    address: "",
    pancardNo: "",
  });

  const [policeVerificationDetailsEdit, setPoliceVerificationDetailsEdit] = useState({
    name: "",
    fatherName: "",
    dob: "",
    address: "",
    civil: "",
    magistrate: "",
    sessions: "",
    highCourt: "",
  });

  const toggleEducationModal = (verificationId) => {
    setVerificationId(verificationId)
    var config = {
      method: 'get',
      url: API_BASE_URL + "/api/verification/" + verificationId,
      headers: {
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at'),
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setEducationDetails(response.data.vEducation)
          const lastEducationIndex = response.data.vEducation?.length - 1;
          const lastEducation = response.data.vEducation[lastEducationIndex];

          setEducationDetailsEdit({
            ...educationDetailsEdit,
            courseDegree: lastEducation?.courseDegree || "",
            college: lastEducation?.college || "",
            days: lastEducation?.days || "",
            enrollmentNo: lastEducation?.enrollmentNo || "",
            yearOfPassing: lastEducation?.yearOfPassing || "",
            verifier: lastEducation?.verifier || "",
            contactNo: lastEducation?.contactNo || "",
            modeOfVerification: lastEducation?.modeOfVerification || "",
            remarks: lastEducation?.remarks || "",
            marksCard: lastEducation?.marksCard || "",
            image1: lastEducation?.image1 || "",
          });
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });
    setEducationModal(!educationModal);
  }

  const handleEducationChange = (e) => {
    setEducationDetailsEdit({
      ...educationDetailsEdit,
      [e.target.name]: e.target.value
    })
  }

  const uploadMarksCard = (e) => {
    const file = e.target.files[0];
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: `verification/${file.name}`,
      Body: file,
    };
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'application/pdf') {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading file:', err);
        } else {
          setEducationDetailsEdit({
            ...educationDetailsEdit,
            image1: data.Location
          })
        }
      });
    } else {
      alert("File type is not supported")
    }
  }

  const toggleEmploymentModal = (verificationId) => {
    setVerificationId(verificationId)
    var config = {
      method: 'get',
      url: API_BASE_URL + "/api/verification/" + verificationId,
      headers: {
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at'),
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setEmploymentDetails(response.data.vEmployment)
          const lastEmploymentIndex = response.data.vEmployment?.length - 1;
          const lastEmployment = response.data.vEmployment[lastEmploymentIndex];

          setEmploymentDetailsEdit({
            ...employmentDetailsEdit,
            companyName: lastEmployment?.companyName || "",
            tenure: lastEmployment?.tenure || "",
            designation: lastEmployment?.designation || "",
            employeeCode: lastEmployment?.employeeCode || "",
            salary: lastEmployment?.salary || "",
            reportingManager: lastEmployment?.reportingManager || "",
            duties: lastEmployment?.duties || "",
            issues: lastEmployment?.issues || "",
            performanceAtWork: lastEmployment?.performanceAtWork || "",
            reasonForLeaving: lastEmployment?.reasonForLeaving || "",
            eligibilityToRetire: lastEmployment?.eligibilityToRetire || "",
            verifier: lastEmployment?.verifier || "",
            contactNo: lastEmployment?.contactNo || "",
            modeOfVerification: lastEmployment?.modeOfVerification || "",
            remarks: lastEmployment?.remarks || "",
            image1: lastEmployment?.image1 || "",
          });
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });
    setEmploymentModal(!employmentModal);
  }

  const handleEmploymentChange = (e) => {
    setEmploymentDetailsEdit({
      ...employmentDetailsEdit,
      [e.target.name]: e.target.value
    })
  }
  const uploadIdCard = (e) => {
    const file = e.target.files[0];
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: `verification/${file.name}`,
      Body: file,
    };
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'application/pdf') {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading file:', err);
        } else {
          setEmploymentDetailsEdit({
            ...employmentDetailsEdit,
            image1: data.Location
          })
        }
      });
    } else {
      alert("File type is not supported")
    }
  }

  const toggleAddressModal = (verificationId) => {
    setVerificationId(verificationId)
    var config = {
      method: 'get',
      url: API_BASE_URL + "/api/verification/" + verificationId,
      headers: {
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at'),
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setAddressDetails(response.data.vAddress)
          const lastAddressIndex = response.data.vAddress?.length - 1;
          const lastAddress = response.data.vAddress[lastAddressIndex];

          setAddressDetailsEdit({
            ...addressDetailsEdit,
            name: lastAddress?.name || "",
            fatherName: lastAddress?.fatherName || "",
            dob: lastAddress?.dob || "",
            confirmationAddress: lastAddress?.confirmationAddress || "",
            typeOfAddress: lastAddress?.typeOfAddress || "",
            contactNumber: lastAddress?.contactNumber || "",
            periodOfStay: lastAddress?.periodOfStay || "",
            typeOfProperty: lastAddress?.typeOfProperty || "",
            idProof: lastAddress?.idProof || "",
            specialComments: lastAddress?.specialComments || "",
            image1: lastAddress?.image1 || "",
          });
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });
    setAddressModal(!addressModal);
  }

  const handleAddressChange = (e) => {
    setAddressDetailsEdit({
      ...addressDetailsEdit,
      [e.target.name]: e.target.value
    })
  }
  const uploadIdCardForAddress = (e) => {
    const file = e.target.files[0];
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: `verification/${file.name}`,
      Body: file,
    };
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'application/pdf') {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading file:', err);
        } else {
          setAddressDetailsEdit({
            ...addressDetailsEdit,
            image1: data.Location
          })
        }
      });
    } else {
      alert("File type is not supported")
    }
  }

  const toggleCriminalModal = (verificationId) => {
    setVerificationId(verificationId)
    var config = {
      method: 'get',
      url: API_BASE_URL + "/api/verification/" + verificationId,
      headers: {
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at'),
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setCriminalDetails(response.data.vCriminal)
          const lastCriminalIndex = response.data.vCriminal?.length - 1;
          const lastCriminal = response.data.vCriminal[lastCriminalIndex];

          setCriminalDetailsEdit({
            ...criminalDetailsEdit,
            fatherName: lastCriminal?.fatherName || "",
            dob: lastCriminal?.dob || "",
            magistrate: lastCriminal?.magistrate || "",
            sessions: lastCriminal?.sessions || "",
            highCourt: lastCriminal?.highCourt || "",
            durationOfRecordsChecked: lastCriminal?.durationOfRecordsChecked || "",
          });
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });
    setCriminalModal(!criminalModal);
  }

  const handleCriminalChange = (e) => {
    setCriminalDetails({
      ...criminalDetails,
      [e.target.name]: e.target.value
    })
  }

  const toggleIdentityModal = (verificationId) => {
    setVerificationId(verificationId)
    var config = {
      method: 'get',
      url: API_BASE_URL + "/api/verification/" + verificationId,
      headers: {
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at'),
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setIdentityDetails(response.data.vIdentity)
          const lastIdentityIndex = response.data.vIdentity?.length - 1;
          const lastIdentity = response.data.vIdentity[lastIdentityIndex];

          setIdentityDetailsEdit({
            ...identityDetailsEdit,
            fullName: lastIdentity?.fullName || "",
            fatherName: lastIdentity?.fatherName || "",
            dob: lastIdentity?.dob || "",
            pancardNo: lastIdentity?.pancardNo || "",
            image1: lastIdentity?.image1 || "",
          });
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });
    setIdentityModal(!identityModal);
  }

  const handleIdentityChange = (e) => {
    setIdentityDetailsEdit({
      ...identityDetailsEdit,
      [e.target.name]: e.target.value
    })
  }

  const uploadPancardImage = (e) => {
    const file = e.target.files[0];
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: `verification/${file.name}`,
      Body: file,
    };
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'application/pdf') {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading file:", err);
        } else {
          setIdentityDetailsEdit({
            ...identityDetailsEdit,
            image1: data.Location
          })
        }
      });
    } else {
      alert("File type is not supported")
    }
  }

  const toggleReferenceModal = (verificationId) => {
    setVerificationId(verificationId)
    var config = {
      method: 'get',
      url: API_BASE_URL + "/api/verification/" + verificationId,
      headers: {
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at'),
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setReferenceDetails(response.data.vReference)
          const lastReferenceIndex = response.data.vReference?.length - 1;
          const lastReference = response.data.vReference[lastReferenceIndex];

          setReferenceDetailsEdit({
            ...referenceDetailsEdit,
            respondentName: lastReference?.respondentName || "",
            designation: lastReference?.designation || "",
            organisation: lastReference?.organisation || "",
          });
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });
    setReferenceModal(!referenceModal);
  }

  const handleReferenceChange = (e) => {
    setReferenceDetailsEdit({
      ...referenceDetailsEdit,
      [e.target.name]: e.target.value
    })
  }

  const toggleCreditCheckModal = (verificationId) => {
    setVerificationId(verificationId)
    var config = {
      method: 'get',
      url: API_BASE_URL + "/api/verification/" + verificationId,
      headers: {
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at'),
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setCreditCheckDetails(response.data.vCreditCheck)
          const lastCreditCheckIndex = response.data.vCreditCheck?.length - 1;
          const lastCreditCheck = response.data.vCreditCheck[lastCreditCheckIndex];

          setCreditCheckDetailsEdit({
            ...creditCheckDetailsEdit,
            name: lastCreditCheck?.name || "",
            dob: lastCreditCheck?.dob || "",
            mobileNumber: lastCreditCheck?.mobileNumber || "",
            address: lastCreditCheck?.address || "",
            pancardNo: lastCreditCheck?.pancardNo || "",
          });
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });
    setCreditCheckModal(!creditCheckModal);
  }
  const handleCreditCheckChange = (e) => {
    setCreditCheckDetails({
      ...creditCheckDetails,
      [e.target.name]: e.target.value
    })
  }

  const toggleDrugTestModal = (verificationId) => {
    setVerificationId(verificationId)
    var config = {
      method: 'get',
      url: API_BASE_URL + "/api/verification/" + verificationId,
      headers: {
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at'),
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setDrugTestDetails(response.data.vDrugTest)
          const lastDrugTestIndex = response.data.vDrugTest?.length - 1;
          const lastDrugTest = response.data.vDrugTest[lastDrugTestIndex];

          setDrugTestDetailsEdit({
            ...drugTestDetailsEdit,
            name: lastDrugTest?.name || "",
            dob: lastDrugTest?.dob || "",
            bloodSamplesCollected: lastDrugTest?.bloodSamplesCollected || "",
            collectionLocation: lastDrugTest?.collectionLocation || "",
            testResult: lastDrugTest?.testResult || "",
          });
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });
    setDrugTestModal(!drugTestModal);
  }

  const handleDrugTestChange = (e) => {
    setDrugTestDetails({
      ...drugTestDetails,
      [e.target.name]: e.target.value
    })
  }

  const togglePoliceVerificationModal = (verificationId) => {
    setVerificationId(verificationId)
    var config = {
      method: 'get',
      url: API_BASE_URL + "/api/verification/" + verificationId,
      headers: {
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at'),
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setPoliceVerificationDetails(response.data.vPoliceVerification)
          const lastPoliceVerificationIndex = response.data.vPoliceVerification?.length - 1;
          const lastPoliceVerification = response.data.vPoliceVerification[lastPoliceVerificationIndex];

          setPoliceVerificationDetailsEdit({
            ...policeVerificationDetailsEdit,
            name: lastPoliceVerification?.name || "",
            fatherName: lastPoliceVerification?.fatherName || "",
            dob: lastPoliceVerification?.dob || "",
            address: lastPoliceVerification?.address || "",
            civil: lastPoliceVerification?.civil || "",
            magistrate: lastPoliceVerification?.magistrate || "",
            sessions: lastPoliceVerification?.sessions || "",
            highCourt: lastPoliceVerification?.highCourt || "",
          });
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });
    setPoliceVerificationModal(!policeVerificationModal);
  }

  const handlePoliceVerificationChange = (e) => {
    setPoliceVerificationDetails({
      ...policeVerificationDetails,
      [e.target.name]: e.target.value
    })
  }

  //Fetch Details from API for verifications
  const creditCheckApi = () => {
    var data = JSON.stringify(
      {
        "panNumber": creditCheckDetails[0].pancardNo,
        "name": creditCheckDetails[0].name,
        "dob": creditCheckDetails[0].dob,
        "panStatus": "true"
      }
    );
    var config = {
      method: 'POST',
      url: API_SINGZYS_BASE_URL + "/pan/pan-verify-details",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': API_SIGNZYS_ACCESS_TOKEN,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.result.nameMatch) {
          creditCheckDetails[1].name = "VERIFIED"
        }
        else {
          creditCheckDetails[1].name = "NOT VERIFIED"
        }
        if (response.result.dobMatch) {
          creditCheckDetails[1].dob = "VERIFIED"
        }
        else {
          creditCheckDetails[1].dob = "NOT VERIFIED"
        }
        if (response.result.panStatus === "VALID") {
          creditCheckDetails[1].pancardNo = "VERIFIED"
        }
        else {
          creditCheckDetails[1].pancardNo = "NOT VERIFIED"
        }

      })
      .catch(function (error) {
        console.log(error)
        handleNon200Response(error)
      });
  }


  // Toggle active state for Tab 
  const toggle = tab => {
    if (tab === '2') {
      fetchInProgressVerification();
    }
    if (tab === '3') {
      fetchCompletedVerification();
    }
    if (tab === '4') {
      fetchRejectedVerification();
    }
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }
  useEffect(() => {
    fetchClient();
    fetchPendingVerification();
    fetchAgentList();
  }, [])

  const fetchClient = () => {
    let clientId = []
    let clientName = [];
    var config = {
      method: 'get',
      url: API_BASE_URL + '/api/client',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
      }
    };

    axios(config)
      .then(function (response) {
        for (let i = 0; i < response.data.length; i++) {
          clientId.push(response.data[i]._id)
          clientName.push(response.data[i].clientName)
        }
        setClientId(clientId)
        setClientName(clientName)
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error);
      });
  }

  const fetchAgentList = () => {
    let agentJsonData = {}

    agentJsonData.approvalStatus = "Approved";
    agentJsonData.agentOnlineStatus = "Active"
    let agentJsonData1 = JSON.stringify(agentJsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/api/agentsList',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: agentJsonData1
    };

    axios(config)
      .then(function (response) {
        let agents = []
        for (let i = 0; i < response.data.length; i++) {
          const dict = {
            label: response.data[i].name,
            value: response.data[i]._id
          }
          agents.push(dict)
        }
        setAgentData(agents)
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error);
      });
  }

  const fetchPendingVerification = () => {
    let jsonData = {};

    if (userType === "CLIENT") {
      jsonData.clientId = localStorage.getItem("id");
    }

    jsonData.status = "Pending";
    let jsonData1 = JSON.stringify(jsonData);

    let config = {
      method: 'post',
      url: API_BASE_URL + '/api/verificationList',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };

    axios(config)
      .then(function (response) {
        console.log("response.data", response.data);
        setPendingVerificationData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error);
      });
  }

  const fetchInProgressVerification = () => {
    let jsonData = {};

    if (userType === "CLIENT") {
      jsonData.clientId = localStorage.getItem("id");
    }

    jsonData.status = "InProgress";
    let jsonData1 = JSON.stringify(jsonData);

    let config = {
      method: 'post',
      url: API_BASE_URL + '/api/verificationList',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };

    axios(config)
      .then(function (response) {
        console.log("response.data", response.data);
        setInProgressVerificationData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error);
      });
  }

  const fetchCompletedVerification = () => {
    let jsonData = {};

    if (userType === "CLIENT") {
      jsonData.clientId = localStorage.getItem("id");
    }

    jsonData.status = "Completed";
    let jsonData1 = JSON.stringify(jsonData);

    let config = {
      method: 'post',
      url: API_BASE_URL + '/api/verificationList',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };

    axios(config)
      .then(function (response) {
        console.log("response.data", response.data);
        setCompletedVerificationData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error);
      });
  }

  const fetchRejectedVerification = () => {
    let jsonData = {};

    if (userType === "CLIENT") {
      jsonData.clientId = localStorage.getItem("id");
    }

    jsonData.status = "Rejected";
    let jsonData1 = JSON.stringify(jsonData);

    let config = {
      method: 'post',
      url: API_BASE_URL + '/api/verificationList',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };

    axios(config)
      .then(function (response) {
        console.log("response.data", response.data);
        setRejectedVerificationData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error);
      });
  }


  const handleNon200Response = (error) => {
    if (error.response.status === 401 && error.response.data.message === "User Not authenticated") {
      sessionExpired();
    }
  }

  const handleAgent = (agentId, index) => {
    setState({
      ...state,
      agentId,
      topIndex: index
    })
  };

  const handleAssignAgent = (key, index) => {

    if (state.topIndex === index) {
      if (state.agentId.length === 0) {
        alert("Select Agent")
        return
      }

      console.log(state.agentId.label)
      console.log(state.agentId.value)

      var jsonData = JSON.stringify({
        "agentName": state.agentId.label,
        "agentId": state.agentId.value,
        "status": "InProgress"
      })

      var config = {
        method: "put",
        url: API_BASE_URL + "/api/verification/" + key._id,
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData,
      }

      axios(config)
        .then(function (response) {
          if (response.status === 200) {
            fetchPendingVerification()
            setState({
              ...state,
              agentId: [],
              topIndex: '-1'
            })
            alert("Agent Assigned Successfully!!")
          }
        })
        .catch(function (error) {
          console.log(error)
          handleNon200Response(error)
        })
    } else {
      alert("Select Agent")
      return
    }
    document.getElementById(`agent${key._id}`).value = "";
  }

  const completeVerification = (key) => {
    if (window.confirm('Are you sure you want to complete the verification?')) {
      var jsonData = JSON.stringify({
        "status": "Completed",
      })

      var config = {
        method: "put",
        url: API_BASE_URL + "/api/verification/" + key._id,
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData,
      }

      axios(config)
        .then(function (response) {
          console.log(response.data)
          if (response.status === 200) {
            alert("Completed Successfully!!")
            fetchInProgressVerification();
          }
        })
        .catch(function (error) {
          console.log(error)
          handleNon200Response(error, "2")
        })
    } else { }
  }

  const rejectVerification = (key) => {
    if (window.confirm('Are you sure you want to reject the verification?')) {
      var jsonData = JSON.stringify({
        "status": "Rejected",
      })

      var config = {
        method: "put",
        url: API_BASE_URL + "/api/verification/" + key._id,
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData,
      }

      axios(config)
        .then(function (response) {
          console.log(response.data)
          if (response.status === 200) {
            alert("Rejected Successfully!!")
            fetchInProgressVerification();
          }
        })
        .catch(function (error) {
          console.log(error)
          handleNon200Response(error, "2")
        })
    } else { }
  }

  const pendingVerification = (key) => {
    if (window.confirm('Are you sure you want to move the verification to pending?')) {
      var jsonData = JSON.stringify({
        "status": "Pending",
      })

      var config = {
        method: "put",
        url: API_BASE_URL + "/api/verification/" + key._id,
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData,
      }

      axios(config)
        .then(function (response) {
          console.log(response.data)
          if (response.status === 200) {
            alert("Moved to Pending Successfully!!")
            fetchRejectedVerification();
          }
        })
        .catch(function (error) {
          console.log(error)
          handleNon200Response(error, "2")
        })
    } else { }
  }

  const updateIndividualVerification = (verificationId, key) => {
    var jsonData = JSON.stringify({
      [key]: true
    })

    let config = {
      method: "put",
      url: API_BASE_URL + "/api/verification/" + verificationId,
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        "Content-Type": "application/json",
      },
      data: jsonData,
    }

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          fetchInProgressVerification()
        }
      })
      .catch(function (error) {
        console.log(error)
        handleNon200Response(error)
      })

    if (key === "vEducationVerified") {
      var jsonData1 = JSON.stringify({
        "verificationId": verificationId,
        "vEducationVerified": true,
        "educationDetails": {
          courseDegree: educationDetailsEdit.courseDegree,
          college: educationDetailsEdit.college,
          days: educationDetailsEdit.days,
          enrollmentNo: educationDetailsEdit.enrollmentNo,
          yearOfPassing: educationDetailsEdit.yearOfPassing,
          image1: educationDetailsEdit.image1
        }
      })

      let config1 = {
        method: "post",
        url: API_BASE_URL + "/api/verification/education",
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData1,
      }

      axios(config1)
        .then(function (response1) {
          if (response1.status === 200) {
          }
        })
        .catch(function (error1) {
          console.log(error1)
          handleNon200Response(error1)
        })
      toggleEducationModal(verificationId)
    }
    if (key === "vEmploymentVerified") {
      var jsonData1 = JSON.stringify({
        "verificationId": verificationId,
        "vEmploymentVerified": true,
        "employmentDetails": {
          companyName: employmentDetailsEdit.companyName,
          tenure: employmentDetailsEdit.tenure,
          designation: employmentDetailsEdit.designation,
          employeeCode: employmentDetailsEdit.employeeCode,
          salary: employmentDetailsEdit.salary,
          reportingManager: employmentDetailsEdit.reportingManager,
          duties: employmentDetailsEdit.duties,
          issues: employmentDetailsEdit.issues,
          performanceAtWork: employmentDetailsEdit.performanceAtWork,
          reasonForLeaving: employmentDetailsEdit.reasonForLeaving,
        }
      })

      let config1 = {
        method: "post",
        url: API_BASE_URL + "/api/verification/employment",
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData1,
      }

      axios(config1)
        .then(function (response1) {
          if (response1.status === 200) {
          }
        })
        .catch(function (error1) {
          console.log(error1)
          handleNon200Response(error1)
        })
      toggleEmploymentModal(verificationId)
    }
    if (key === "vAddressVerified") {
      var jsonData1 = JSON.stringify({
        "verificationId": verificationId,
        "vAddressVerified": true,
        "addressDetails": {
          name: addressDetailsEdit.name,
          fatherName: addressDetailsEdit.fatherName,
          dob: addressDetailsEdit.dob,
          confirmationAddress: addressDetailsEdit.confirmationAddress,
          typeOfAddress: addressDetailsEdit.typeOfAddress,
          contactNumber: addressDetailsEdit.contactNumber,
          periodOfStay: addressDetailsEdit.periodOfStay,
          typeOfProperty: addressDetailsEdit.typeOfProperty,
          image1: addressDetailsEdit.image1,
        }
      })

      let config1 = {
        method: "post",
        url: API_BASE_URL + "/api/verification/address",
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData1,
      }

      axios(config1)
        .then(function (response1) {
          if (response1.status === 200) {
          }
        })
        .catch(function (error1) {
          console.log(error1)
          handleNon200Response(error1)
        })
      toggleAddressModal(verificationId)
    }
    if (key === "vCriminalVerified") {
      var jsonData1 = JSON.stringify({
        "verificationId": verificationId,
        "vCriminalVerified": true,
        "criminalDetails": {
          candidateName: criminalDetailsEdit.candidateName,
          fatherName: criminalDetailsEdit.fatherName,
          dob: criminalDetailsEdit.dob,
          magistrate: criminalDetailsEdit.magistrate,
          sessions: criminalDetailsEdit.sessions,
          highCourt: criminalDetailsEdit.highCourt,
          durationOfRecordsChecked: criminalDetailsEdit.durationOfRecordsChecked,
        }
      })

      let config1 = {
        method: "post",
        url: API_BASE_URL + "/api/verification/criminalDetails",
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData1,
      }

      axios(config1)
        .then(function (response1) {
          if (response1.status === 200) {
          }
        })
        .catch(function (error1) {
          console.log(error1)
          handleNon200Response(error1)
        })
      toggleCriminalModal(verificationId)
    }
    if (key === "vDrugTestVerified") {
      var jsonData1 = JSON.stringify({
        "verificationId": verificationId,
        "vDrugTestVerified": true,
        "drugTestDetails": {
          name: drugTestDetailsEdit.name,
          dob: drugTestDetailsEdit.dob,
          bloodSamplesCollected: drugTestDetailsEdit.bloodSamplesCollected,
          collectionLocation: drugTestDetailsEdit.collectionLocation,
          testResult: drugTestDetailsEdit.testResult
        }
      })

      let config1 = {
        method: "post",
        url: API_BASE_URL + "/api/verification/drugtest",
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData1,
      }

      axios(config1)
        .then(function (response1) {
          if (response1.status === 200) {
          }
        })
        .catch(function (error1) {
          console.log(error1)
          handleNon200Response(error1)
        })
      toggleDrugTestModal(verificationId)
    }
    if (key === "vPoliceVerificationVerified") {
      var jsonData1 = JSON.stringify({
        "verificationId": verificationId,
        "vPoliceVerificationVerified": true,
        "policeVerificationDetails": {
          name: policeVerificationDetailsEdit.name,
          fatherName: policeVerificationDetailsEdit.fatherName,
          dob: policeVerificationDetailsEdit.dob,
          address: policeVerificationDetailsEdit.address,
          civil: policeVerificationDetailsEdit.civil,
          magistrate: policeVerificationDetailsEdit.magistrate,
          sessions: policeVerificationDetailsEdit.sessions,
          highCourt: policeVerificationDetailsEdit.highCourt,
        }
      })

      let config1 = {
        method: "post",
        url: API_BASE_URL + "/api/verification/policeVerification",
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData1,
      }

      axios(config1)
        .then(function (response1) {
          if (response1.status === 200) {
          }
        })
        .catch(function (error1) {
          console.log(error1)
          handleNon200Response(error1)
        })
      togglePoliceVerificationModal(verificationId)
    }
    if (key === "vCreditCheckVerified") {
      var jsonData1 = JSON.stringify({
        "verificationId": verificationId,
        "vCreditCheckVerified": true,
        "creditCheckDetails": {
          name: creditCheckDetailsEdit.name,
          dob: creditCheckDetailsEdit.dob,
          mobileNumber: creditCheckDetailsEdit.mobileNumber,
          address: creditCheckDetailsEdit.address,
          pancardNo: creditCheckDetailsEdit.pancardNo
        }
      })

      let config1 = {
        method: "post",
        url: API_BASE_URL + "/api/verification/creditCheck",
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData1,
      }

      axios(config1)
        .then(function (response1) {
          if (response1.status === 200) {
          }
        })
        .catch(function (error1) {
          console.log(error1)
          handleNon200Response(error1)
        })
      toggleCreditCheckModal(verificationId)
    }
    if (key === "vIdentityVerified") {
      var jsonData1 = JSON.stringify({
        "verificationId": verificationId,
        "vIdentityVerified": true,
        "identityDetails": {
          fullName: identityDetailsEdit.fullName,
          fatherName: identityDetailsEdit.fatherName,
          dob: identityDetailsEdit.dob,
          pancardNo: identityDetailsEdit.pancardNo,
          image1: identityDetailsEdit.image1
        }
      })

      let config1 = {
        method: "post",
        url: API_BASE_URL + "/api/verification/identity",
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData1,
      }

      axios(config1)
        .then(function (response1) {
          if (response1.status === 200) {
          }
        })
        .catch(function (error1) {
          console.log(error1)
          handleNon200Response(error1)
        })
      toggleIdentityModal(verificationId)
    }
    if (key === "vReferenceVerified") {
      var jsonData1 = JSON.stringify({
        "verificationId": verificationId,
        "vReferenceVerified": true,
        "referenceDetails": {
          respondentName: referenceDetailsEdit.respondentName,
          designation: referenceDetailsEdit.designation,
          organisation: referenceDetailsEdit.organisation,
        }
      })

      let config1 = {
        method: "post",
        url: API_BASE_URL + "/api/verification/reference",
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData1,
      }

      axios(config1)
        .then(function (response1) {
          if (response1.status === 200) {
          }
        })
        .catch(function (error1) {
          console.log(error1)
          handleNon200Response(error1)
        })
      toggleReferenceModal(verificationId)
    }
  }

  const handleLink = (rowData) => {
    var ciphertext = CryptoJS.AES.encrypt(rowData?._id, cyptoKey).toString();
    const cryptedId = ciphertext.replace(/\//g, '-');

    window.open(`${"/verification/link/"}${cryptedId}`);
  }

  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Verification"}
          parent={"Verification"}
          title={"Verification"}
        />
        <Col xl={12}>
          <Card className="height-equal">
            {/* <CommonHeader
                title={"BrowserDefaultsHeading"}
                subTitle={subTitle}
                headClass="pb-0"
                /> */}
            <CardBody className="custom-input">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        currentActiveTab === '1'
                    })}
                    style={{ cursor: "pointer" }}
                    onClick={() => { toggle('1'); }}
                  >
                    <b>Pending Verification</b>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        currentActiveTab === '2'
                    })}
                    style={{ cursor: "pointer" }}
                    onClick={() => { toggle('2'); }}
                  >
                    <b>InProgress Verification</b>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        currentActiveTab === '3'
                    })}
                    style={{ cursor: "pointer" }}
                    onClick={() => { toggle('3'); }}
                  >
                    <b>Completed Verification</b>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        currentActiveTab === '4'
                    })}
                    style={{ cursor: "pointer" }}
                    onClick={() => { toggle('4'); }}
                  >
                    <b>Rejected Verification</b>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={currentActiveTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <Table responsive className="" style={{ textAlign: "center" }}>
                        <thead>
                          <tr>
                            <th>Verifcation Id</th>
                            <th>Name</th>
                            <th>Number</th>
                            <th>Email</th>
                            <th>Client</th>
                            <th>Education</th>
                            <th>Employemnt</th>
                            <th>Address</th>
                            <th>Criminal</th>
                            <th>Identity</th>
                            <th>Reference</th>
                            <th>Database</th>
                            <th>Social Media</th>
                            <th>Drug Test</th>
                            <th>Credit Check</th>
                            <th>Police Verification</th>
                            <th>Link</th>
                            <th>Agent</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pendingVerificationData.map((rowData, index) => (
                            <tr key={index}>
                              <td>{rowData.verificationNumber}</td>
                              <td>{rowData.name}</td>
                              <td>{rowData.number}</td>
                              <td>{rowData.email}</td>
                              <td>{clientName[clientId.indexOf(rowData.clientId)]}</td>
                              <td>
                                {rowData.vEducationStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>
                                {rowData.vEmploymentStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>
                                {rowData.vAddressStatus === true ? "YES" : "NO"}
                              </td>
                              <td>
                                {rowData.vCriminalStatus === true ? "YES" : "NO"}
                              </td>
                              <td>
                                {rowData.vIdentityStatus === true ? "YES" : "NO"}
                              </td>
                              <td>
                                {rowData.vReferenceStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>
                                {rowData.vDatabaseStatus === true ? "YES" : "NO"}
                              </td>
                              <td>
                                {rowData.vSocialMediaStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>
                                {rowData.vDrugTestStatus === true ? "YES" : "NO"}
                              </td>
                              <td>
                                {rowData.vCreditCheckStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>
                                {rowData.vPoliceVerificationStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>
                                <FontAwesomeIcon
                                  icon={faLink}
                                  className='pending-verification-link-icon'
                                  onClick={() => handleLink(rowData)}
                                />
                              </td>

                              <td>
                                <Select
                                  onChange={(e) => handleAgent(e, index)}
                                  options={agentData}
                                  id={`agent${rowData._id}`}
                                  placeholder="Select Agent"
                                  styles={customStyles1}
                                />
                                <br />
                              </td>
                              <td>
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    handleAssignAgent(rowData, index)
                                  }
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <Table responsive className="" style={{ textAlign: "center" }}>
                              <thead>
                                <tr>
                                  <th>Verifcation Id</th>
                                  <th>Name</th>
                                  <th>Number</th>
                                  <th>Email</th>
                                  <th>Client</th>
                                  <th>Education</th>
                                  <th>Employemnt</th>
                                  <th>Address</th>
                                  <th>Criminal</th>
                                  <th>Identity</th>
                                  <th>Reference</th>
                                  <th>Drug Test</th>
                                  <th>Credit Check</th>
                                  <th>Police Verification</th>
                                  <th>Agent</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {inProgressVerificationData.map(
                                  (rowData, index) => (
                                    <tr key={index}>
                                      <td>{rowData.verificationNumber}</td>
                                      <td>{rowData.name}</td>
                                      <td>{rowData.number}</td>
                                      <td>{rowData.email}</td>
                                      <td>{clientName[clientId.indexOf(rowData.clientId)]}</td>
                                      <td>
                                        {rowData.vEducationStatus === true ? (
                                          <p>
                                            YES <br />
                                            <Button
                                              color="primary"
                                              type="submit"
                                              id="submit"
                                              onClick={() =>
                                                toggleEducationModal(
                                                  rowData._id
                                                )
                                              }
                                            >
                                              Verify
                                            </Button>
                                            &nbsp;{" "}
                                            {rowData.vEducationVerified ===
                                              true ? (
                                              <FontAwesomeIcon
                                                style={{
                                                  color: "darkgreen",
                                                  fontSize: "15px",
                                                  fontWeight: "800",
                                                }}
                                                icon={faCheckCircle}
                                              />
                                            ) : null}{" "}
                                          </p>
                                        ) : (
                                          "NO"
                                        )}
                                      </td>
                                      <td>
                                        {rowData.vEmploymentStatus === true ? (
                                          <p>
                                            YES <br />
                                            <Button
                                              color="primary"
                                              type="submit"
                                              id="submit"
                                              onClick={() =>
                                                toggleEmploymentModal(
                                                  rowData._id
                                                )
                                              }
                                            >
                                              Verify
                                            </Button>
                                            &nbsp;{" "}
                                            {rowData.vEmploymentVerified ===
                                              true ? (
                                              <FontAwesomeIcon
                                                style={{
                                                  color: "darkgreen",
                                                  fontSize: "15px",
                                                  fontWeight: "800",
                                                }}
                                                icon={faCheckCircle}
                                              />
                                            ) : null}{" "}
                                          </p>
                                        ) : (
                                          "NO"
                                        )}
                                      </td>
                                      <td>
                                        {rowData.vAddressStatus === true ? (
                                          <p>
                                            YES <br />
                                            <Button
                                              color="primary"
                                              type="submit"
                                              id="submit"
                                              onClick={() =>
                                                toggleAddressModal(rowData._id)
                                              }
                                            >
                                              Verify
                                            </Button>
                                            &nbsp;{" "}
                                            {rowData.vAddressVerified ===
                                              true ? (
                                              <FontAwesomeIcon
                                                style={{
                                                  color: "darkgreen",
                                                  fontSize: "15px",
                                                  fontWeight: "800",
                                                }}
                                                icon={faCheckCircle}
                                              />
                                            ) : null}{" "}
                                          </p>
                                        ) : (
                                          "NO"
                                        )}
                                      </td>
                                      <td>
                                        {rowData.vCriminalStatus === true ? (
                                          <p>
                                            YES <br />
                                            <Button
                                              color="primary"
                                              type="submit"
                                              id="submit"
                                              onClick={() =>
                                                toggleCriminalModal(rowData._id)
                                              }
                                            >
                                              Verify
                                            </Button>
                                            &nbsp;{" "}
                                            {rowData.vCriminalVerified ===
                                              true ? (
                                              <FontAwesomeIcon
                                                style={{
                                                  color: "darkgreen",
                                                  fontSize: "15px",
                                                  fontWeight: "800",
                                                }}
                                                icon={faCheckCircle}
                                              />
                                            ) : null}{" "}
                                          </p>
                                        ) : (
                                          "NO"
                                        )}
                                      </td>
                                      <td>
                                        {rowData.vIdentityStatus === true ? (
                                          <p>
                                            YES <br />
                                            <Button
                                              color="primary"
                                              type="submit"
                                              id="submit"
                                              onClick={() =>
                                                toggleIdentityModal(rowData._id)
                                              }
                                            >
                                              Verify
                                            </Button>
                                            &nbsp;{" "}
                                            {rowData.vIdentityVerified ===
                                              true ? (
                                              <FontAwesomeIcon
                                                style={{
                                                  color: "darkgreen",
                                                  fontSize: "15px",
                                                  fontWeight: "800",
                                                }}
                                                icon={faCheckCircle}
                                              />
                                            ) : null}{" "}
                                          </p>
                                        ) : (
                                          "NO"
                                        )}
                                      </td>
                                      <td>
                                        {rowData.vReferenceStatus === true ? (
                                          <p>
                                            YES <br />
                                            <Button
                                              color="primary"
                                              type="submit"
                                              id="submit"
                                              onClick={() =>
                                                toggleReferenceModal(rowData._id)
                                              }
                                            >
                                              Verify
                                            </Button>
                                            &nbsp;{" "}
                                            {rowData.vReferenceVerified ===
                                              true ? (
                                              <FontAwesomeIcon
                                                style={{
                                                  color: "darkgreen",
                                                  fontSize: "15px",
                                                  fontWeight: "800",
                                                }}
                                                icon={faCheckCircle}
                                              />
                                            ) : null}{" "}
                                          </p>
                                        ) : (
                                          "NO"
                                        )}
                                      </td>
                                      <td>
                                        {rowData.vDrugTestStatus === true ? (
                                          <p>
                                            YES <br />
                                            <Button
                                              color="primary"
                                              type="submit"
                                              id="submit"
                                              onClick={() =>
                                                toggleDrugTestModal(rowData._id)
                                              }
                                            >
                                              Verify
                                            </Button>
                                            &nbsp;{" "}
                                            {rowData.vDrugTestVerified ===
                                              true ? (
                                              <FontAwesomeIcon
                                                style={{
                                                  color: "darkgreen",
                                                  fontSize: "15px",
                                                  fontWeight: "800",
                                                }}
                                                icon={faCheckCircle}
                                              />
                                            ) : null}{" "}
                                          </p>
                                        ) : (
                                          "NO"
                                        )}
                                      </td>
                                      <td>
                                        {rowData.vCreditCheckStatus === true ? (
                                          <p>
                                            YES <br />
                                            <Button
                                              color="primary"
                                              type="submit"
                                              id="submit"
                                              onClick={() =>
                                                toggleCreditCheckModal(rowData._id)
                                              }
                                            >
                                              Verify
                                            </Button>
                                            &nbsp;{" "}
                                            {rowData.vCreditCheckVerified ===
                                              true ? (
                                              <FontAwesomeIcon
                                                style={{
                                                  color: "darkgreen",
                                                  fontSize: "15px",
                                                  fontWeight: "800",
                                                }}
                                                icon={faCheckCircle}
                                              />
                                            ) : null}{" "}
                                          </p>
                                        ) : (
                                          "NO"
                                        )}
                                      </td>
                                      <td>
                                        {rowData.vPoliceVerificationStatus === true ? (
                                          <p>
                                            YES <br />
                                            <Button
                                              color="primary"
                                              type="submit"
                                              id="submit"
                                              onClick={() =>
                                                togglePoliceVerificationModal(rowData._id)
                                              }
                                            >
                                              Verify
                                            </Button>
                                            &nbsp;{" "}
                                            {rowData.vPoliceVerificationVerified ===
                                              true ? (
                                              <FontAwesomeIcon
                                                style={{
                                                  color: "darkgreen",
                                                  fontSize: "15px",
                                                  fontWeight: "800",
                                                }}
                                                icon={faCheckCircle}
                                              />
                                            ) : null}{" "}
                                          </p>
                                        ) : (
                                          "NO"
                                        )}
                                      </td>
                                      <td>{rowData.agentName}</td>
                                      <td>
                                        <Button
                                          color="success"
                                          onClick={() =>
                                            completeVerification(rowData)
                                          }
                                        >
                                          Complete
                                        </Button>{" "}
                                        <br /> <br />
                                        <Button
                                          color="danger"
                                          onClick={() =>
                                            rejectVerification(rowData)
                                          }
                                        >
                                          Reject
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </TabPane>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <Table
                        responsive
                        className=""
                        style={{ textAlign: "center" }}
                      >
                        <thead>
                          <tr>
                            <th>Verifcation Id</th>
                            <th>Name</th>
                            <th>Number</th>
                            <th>Email</th>
                            <th>Client</th>
                            <th>Education</th>
                            <th>Employemnt</th>
                            <th>Address</th>
                            <th>Criminal</th>
                            <th>Identity</th>
                            <th>Reference</th>
                            <th>Database</th>
                            <th>Social Media</th>
                            <th>Drug Test</th>
                            <th>Credit Check</th>
                            <th>Police Verification</th>
                            <th>Agent</th>
                          </tr>
                        </thead>
                        <tbody>
                          {completedVerificationData.map((rowData, index) => (
                            <tr key={index}>
                              <td>{rowData.verificationNumber}</td>
                              <td>{rowData.name}</td>
                              <td>{rowData.number}</td>
                              <td>{rowData.email}</td>
                              <td>{clientName[clientId.indexOf(rowData.clientId)]}</td>
                              <td>
                                <span>
                                  {rowData.vEducationStatus === true
                                    ? "YES"
                                    : "NO"}
                                </span>
                                <span className="pdf-download-icon-span">
                                  {rowData.vEducationStatus === true && (
                                    <PdfDownload
                                      rowData={rowData}
                                      selectedFlag={Education}
                                    />
                                  )}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {rowData.vEmploymentStatus === true
                                    ? "YES"
                                    : "NO"}
                                </span>
                                <span className="pdf-download-icon-span">
                                  {rowData.vEmploymentStatus === true && (
                                    <PdfDownload
                                      rowData={rowData}
                                      selectedFlag={Employment}
                                    />
                                  )}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {rowData.vAddressStatus === true
                                    ? "YES"
                                    : "NO"}
                                </span>
                                <span className="pdf-download-icon-span">
                                  {rowData.vAddressStatus === true && (
                                    <PdfDownload
                                      rowData={rowData}
                                      selectedFlag={Address}
                                    />
                                  )}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {rowData.vCriminalStatus === true
                                    ? "YES"
                                    : "NO"}
                                </span>
                                <span className="pdf-download-icon-span">
                                  {rowData.vCriminalStatus === true && (
                                    <PdfDownload
                                      rowData={rowData}
                                      selectedFlag={Criminal}
                                    />
                                  )}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {rowData.vIdentityStatus === true
                                    ? "YES"
                                    : "NO"}
                                </span>
                                <span className="pdf-download-icon-span">
                                  {rowData.vIdentityStatus === true && (
                                    <PdfDownload
                                      rowData={rowData}
                                      selectedFlag={Identity}
                                    />
                                  )}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {rowData.vReferenceStatus === true
                                    ? "YES"
                                    : "NO"}
                                </span>
                                <span className="pdf-download-icon-span">
                                  {rowData.vReferenceStatus === true && (
                                    <PdfDownload
                                      rowData={rowData}
                                      selectedFlag={Reference}
                                    />
                                  )}
                                </span>
                              </td>
                              <td>
                                {rowData.vDatabaseStatus === true ? "YES" : "NO"}
                              </td>
                              <td>
                                {rowData.vSocialMediaStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>
                                <span>
                                  {rowData.vDrugTestStatus === true
                                    ? "YES"
                                    : "NO"}
                                </span>
                                <span className="pdf-download-icon-span">
                                  {rowData.vDrugTestStatus === true && (
                                    <PdfDownload
                                      rowData={rowData}
                                      selectedFlag={DrugTest}
                                    />
                                  )}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {rowData.vCreditCheckStatus === true
                                    ? "YES"
                                    : "NO"}
                                </span>
                                <span className="pdf-download-icon-span">
                                  {rowData.vCreditCheckStatus === true && (
                                    <PdfDownload
                                      rowData={rowData}
                                      selectedFlag={CreditCheck}
                                    />
                                  )}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {rowData.vPoliceVerificationStatus === true
                                    ? "YES"
                                    : "NO"}
                                </span>
                                <span className="pdf-download-icon-span">
                                  {rowData.vPoliceVerificationStatus ===
                                    true && (
                                      <PdfDownload
                                        rowData={rowData}
                                        selectedFlag={PoliceVerification}
                                      />
                                    )}
                                </span>
                              </td>
                              <td>{rowData.agentName}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col sm="12">
                      <Table responsive className="" style={{ textAlign: "center" }}>
                        <thead>
                          <tr>
                            <th>Verifcation Id</th>
                            <th>Name</th>
                            <th>Number</th>
                            <th>Email</th>
                            <th>Client</th>
                            <th>Education</th>
                            <th>Employemnt</th>
                            <th>Address</th>
                            <th>Criminal</th>
                            <th>Identity</th>
                            <th>Reference</th>
                            <th>Database</th>
                            <th>Social Media</th>
                            <th>Drug Test</th>
                            <th>Credit Check</th>
                            <th>Police Verification</th>
                            <th>Agent</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rejectedVerificationData.map((rowData, index) => (
                            <tr key={index}>
                              <td>{rowData.verificationNumber}</td>
                              <td>{rowData.name}</td>
                              <td>{rowData.number}</td>
                              <td>{rowData.email}</td>
                              <td>{clientName[clientId.indexOf(rowData.clientId)]}</td>
                              <td>
                                {rowData.vEducationStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>
                                {rowData.vEmploymentStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>
                                {rowData.vAddressStatus === true ? "YES" : "NO"}
                              </td>
                              <td>
                                {rowData.vCriminalStatus === true ? "YES" : "NO"}
                              </td>
                              <td>
                                {rowData.vIdentityStatus === true ? "YES" : "NO"}
                              </td>
                              <td>
                                {rowData.vReferenceStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>
                                {rowData.vDatabaseStatus === true ? "YES" : "NO"}
                              </td>
                              <td>
                                {rowData.vSocialMediaStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>
                                {rowData.vDrugTestStatus === true ? "YES" : "NO"}
                              </td>
                              <td>
                                {rowData.vCreditCheckStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>
                                {rowData.vPoliceVerificationStatus === true
                                  ? "YES"
                                  : "NO"}
                              </td>
                              <td>{rowData.agentName}</td>
                              <td>
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    pendingVerification(rowData, index)
                                  }
                                  type="submit"
                                >
                                  Pending
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>

              <Modal size="lg" isOpen={educationModal} toggle={() => toggleEducationModal(verificationId)} width={"1000px"}>
                <ModalHeader toggle={() => toggleEducationModal(verificationId)}>Education Details</ModalHeader>
                <ModalBody>
                  <Row className="g-4">
                    <Col sm="12">
                      <Table responsive className="">
                        <thead>
                          <tr>
                            <th>Education Details</th>
                            <th>Client</th>
                            <th>Verifier</th>
                          </tr>
                          <tr>
                            <td>Course Degree</td>
                            <td>{educationDetails.length > 0 ? educationDetails[0].courseDegree : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Course Degree"
                                value={educationDetailsEdit.courseDegree}
                                id="courseDegree"
                                name="courseDegree"
                                onChange={handleEducationChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>College/Institute/University</td>
                            <td>{educationDetails.length > 0 ? educationDetails[0].college : ''}</td>
                            <td>
                              <Col xs={12}>
                                <Input
                                  type="textarea"
                                  placeholder="College"
                                  value={educationDetailsEdit.college}
                                  id="college"
                                  name="college"
                                  onChange={handleEducationChange}
                                  rows="5"
                                />
                              </Col>
                            </td>
                          </tr>
                          <tr>
                            <td>Dates Attended</td>
                            <td>{educationDetails.length > 0 ? educationDetails[0].days : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Dates Attended"
                                value={educationDetailsEdit.days}
                                id="days"
                                name="days"
                                onChange={handleEducationChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>EnrollMent No/Roll No</td>
                            <td>{educationDetails.length > 0 ? educationDetails[0].enrollmentNo : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Enrollment No"
                                value={educationDetailsEdit.enrollmentNo}
                                id="enrollmentNo"
                                name="enrollmentNo"
                                onChange={handleEducationChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Year of passing</td>
                            <td>{educationDetails.length > 0 ? educationDetails[0].yearOfPassing : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Year of Passing"
                                value={educationDetailsEdit.yearOfPassing}
                                id="yearOfPassing"
                                name="yearOfPassing"
                                onChange={handleEducationChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Image</td>
                            <td>{educationDetails.length > 0 ? <a href={educationDetails[0].image1} target="_blank"><img src={educationDetails[0].image1} height={50} width={50} /></a> : ''}</td>
                            <td>
                              <Input
                                type="file"
                                id="image1"
                                name="image1"
                                onChange={uploadMarksCard}
                              />
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={() => updateIndividualVerification(verificationId, "vEducationVerified")}>
                    Verify
                  </Button>{' '}
                </ModalFooter>
              </Modal>

              <Modal size="lg" isOpen={employmentModal} toggle={() => toggleEmploymentModal(verificationId)}>
                <ModalHeader toggle={() => toggleEmploymentModal(verificationId)}>Employment Details</ModalHeader>
                <ModalBody>
                  <Row className="g-3">
                    <Col sm="12">
                      <Table responsive className="">
                        <thead>
                          <tr>
                            <th>Employment Details</th>
                            <th>Client</th>
                            <th>Verifier</th>
                          </tr>
                          <tr>
                            <td>Company Name</td>
                            <td>{employmentDetails.length > 0 ? employmentDetails[0].companyName : ''}</td>
                            <td> <Input
                              type="text"
                              placeholder="Company Name"
                              value={employmentDetailsEdit.companyName}
                              id="companyName"
                              name="companyName"
                              onChange={handleEmploymentChange}
                            /></td>
                          </tr>
                          <tr>
                            <td>Tenure</td>
                            <td>{employmentDetails.length > 0 ? employmentDetails[0].tenure : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Tenure"
                                value={employmentDetailsEdit.tenure}
                                id="tenure"
                                name="tenure"
                                onChange={handleEmploymentChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Designation</td>
                            <td>{employmentDetails.length > 0 ? employmentDetails[0].designation : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Designation"
                                value={employmentDetailsEdit.designation}
                                id="designation"
                                name="designation"
                                onChange={handleEmploymentChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Employee Code</td>
                            <td>{employmentDetails.length > 0 ? employmentDetails[0].employeeCode : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Employee Code"
                                value={employmentDetailsEdit.employeeCode}
                                id="employeeCode"
                                name="employeeCode"
                                onChange={handleEmploymentChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Salary</td>
                            <td>{employmentDetails.length > 0 ? employmentDetails[0].salary : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Salary"
                                value={employmentDetailsEdit.salary}
                                id="salary"
                                name="salary"
                                onChange={handleEmploymentChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Supervisor/Reporting Manager</td>
                            <td>{employmentDetails.length > 0 ? employmentDetails[0].reportingManager : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Supervisor/Reporting Manager"
                                value={employmentDetailsEdit.reportingManager}
                                id="reportingManager"
                                name="reportingManager"
                                onChange={handleEmploymentChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Duties and Responsibility (Nature of work)</td>
                            <td>{employmentDetails.length > 0 ? employmentDetails[0].duties : ''}</td>
                            <td> <Input
                              type="textarea"
                              placeholder="Duties and Responsibility (Nature of work)"
                              value={employmentDetailsEdit.duties}
                              id="duties"
                              name="duties"
                              onChange={handleEmploymentChange}
                            /></td>
                          </tr>
                          <tr>
                            <td>Any Issue (Credit/Behaivour/ Other)</td>
                            <td>{employmentDetails.length > 0 ? employmentDetails[0].issues : ''}</td>
                            <td> <Input
                              type="textarea"
                              placeholder="Any Issue"
                              value={employmentDetailsEdit.issues}
                              id="issues"
                              name="issues"
                              onChange={handleEmploymentChange}
                              rows="5"
                            /></td>
                          </tr>
                          <tr>
                            <td>Performance at work</td>
                            <td>{employmentDetails.length > 0 ? employmentDetails[0].performanceAtWork : ''}</td>
                            <td> <Input
                              type="textarea"
                              placeholder="Performance at work"
                              value={employmentDetailsEdit.performanceAtWork}
                              id="performanceAtWork"
                              name="performanceAtWork"
                              onChange={handleEmploymentChange}
                              rows="5"
                            /></td>
                          </tr>
                          <tr>
                            <td>Reason for leaving</td>
                            <td>{employmentDetails.length > 0 ? employmentDetails[0].reasonForLeaving : ''}</td>
                            <td> <Input
                              type="textarea"
                              placeholder="Reason for leaving"
                              value={employmentDetailsEdit.reasonForLeaving}
                              id="reasonForLeaving"
                              name="reasonForLeaving"
                              onChange={handleEmploymentChange}
                              rows="5"
                            /></td>
                          </tr>
                          <tr>
                            <td>Image</td>
                            <td>{employmentDetails.length > 0 ? <a href={employmentDetails[0].image1} target="_blank"><img src={employmentDetails[0].image1} height={50} width={50} /></a> : ''}</td>
                            <td>
                              <Input
                                type="file"
                                id="image1"
                                name="image1"
                                onChange={uploadIdCard}
                              />
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={() => updateIndividualVerification(verificationId, "vEmploymentVerified")}>
                    Verify
                  </Button>{' '}
                </ModalFooter>
              </Modal>

              <Modal size="lg" isOpen={addressModal} toggle={() => toggleAddressModal(verificationId)}>
                <ModalHeader toggle={() => toggleAddressModal(verificationId)}>Address Details</ModalHeader>
                <ModalBody>
                  <Row className="g-3">
                    <Col sm="12">
                      <Table responsive className="">
                        <thead>
                          <tr>
                            <th>Address Details</th>
                            <th>Client</th>
                            <th>Verifier</th>
                          </tr>
                          <tr>
                            <td>Name of the Candidate</td>
                            <td>{addressDetails.length > 0 ? addressDetails[0].name : ''}</td>
                            <td><Input
                              type="text"
                              placeholder="Name of the Candidate"
                              value={addressDetailsEdit.name}
                              id="name"
                              name="name"
                              onChange={handleAddressChange}
                            />
                            </td>
                          </tr>
                          <tr>
                            <td>Father Name</td>
                            <td>{addressDetails.length > 0 ? addressDetails[0].fatherName : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Father Name"
                                value={addressDetailsEdit.fatherName}
                                id="fatherName"
                                name="fatherName"
                                onChange={handleAddressChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Date of Birth</td>
                            <td>{addressDetails.length > 0 ? addressDetails[0].dob : ''}</td>
                            <td>
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                value={addressDetailsEdit.dob}
                                id="dob"
                                name="dob"
                                onChange={handleAddressChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Confimation of Address ( Post office, Pin No)</td>
                            <td>{addressDetails.length > 0 ? addressDetails[0].confirmationAddress : ''}</td>
                            <td>
                              <Input
                                type="textarea"
                                placeholder="Confimation of Address ( Post office, Pin No)"
                                value={addressDetailsEdit.confirmationAddress}
                                id="confirmationAddress"
                                name="confirmationAddress"
                                onChange={handleAddressChange}
                                rows="5"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Type of Address</td>
                            <td>{addressDetails.length > 0 ? addressDetails[0].typeOfAddress : ''}</td>
                            <td><Input
                              id="typeOfAddress"
                              name="typeOfAddress"
                              type="select"
                              value={addressDetails.typeOfAddress}
                              onChange={handleAddressChange}
                            >
                              <option value="">Select</option>
                              <option value="Present">Present</option>
                              <option value="Permanent">Permanent</option>
                              <option value="Previous">Previous</option>
                            </Input>
                            </td>
                          </tr>
                          <tr>
                            <td>Contact Number</td>
                            <td>{addressDetails.length > 0 ? addressDetails[0].contactNumber : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Contact Number"
                                value={addressDetailsEdit.contactNumber}
                                id="contactNumber"
                                name="contactNumber"
                                onChange={handleAddressChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Period of Stay</td>
                            <td>{addressDetails.length > 0 ? addressDetails[0].periodOfStay : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Period of Stay"
                                value={addressDetailsEdit.periodOfStay}
                                id="periodOfStay"
                                name="periodOfStay"
                                onChange={handleAddressChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Type of Property</td>
                            <td>{addressDetails.length > 0 ? addressDetails[0].typeOfProperty : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Type of Property"
                                value={addressDetailsEdit.typeOfProperty}
                                id="typeOfProperty"
                                name="typeOfProperty"
                                onChange={handleAddressChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Image</td>
                            <td>{addressDetails.length > 0 ? <a href={addressDetails[0].image1} target="_blank"><img src={addressDetails[0].image1} height={50} width={50} /></a> : ''}</td>
                            <td>
                              <Input
                                type="file"
                                id="image1"
                                name="image1"
                                onChange={uploadIdCardForAddress}
                              />
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={() => updateIndividualVerification(verificationId, "vAddressVerified")}>
                    Verify
                  </Button>{' '}
                </ModalFooter>
              </Modal>

              <Modal size="lg" isOpen={criminalModal} toggle={() => toggleCriminalModal(verificationId)}>
                <ModalHeader toggle={() => toggleCriminalModal(verificationId)}>Criminal Details</ModalHeader>
                <ModalBody>
                  <Row className="g-3">
                    <Col sm="12">
                      <Table responsive className="">
                        <thead>
                          <tr>
                            <th>Criminal Details</th>
                            <th>Client</th>
                            <th>Verifier</th>
                          </tr>
                          <tr>
                            <td>Candidate Name</td>
                            <td>{criminalDetails.length > 0 ? criminalDetails[0].candidateName : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Candidate Name"
                                value={criminalDetailsEdit.candidateName}
                                id="candidateName"
                                name="candidateName"
                                onChange={handleCriminalChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Father Name</td>
                            <td>{criminalDetails.length > 0 ? criminalDetails[0].fatherName : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Father Name"
                                value={criminalDetailsEdit.fatherName}
                                id="fatherName"
                                name="fatherName"
                                onChange={handleCriminalChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Date of Birth</td>
                            <td>{criminalDetails.length > 0 ? criminalDetails[0].dob : ''}</td>
                            <td>
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                value={criminalDetailsEdit.dob}
                                id="dob"
                                name="dob"
                                onChange={handleCriminalChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Magistrate</td>
                            <td>{criminalDetails.length > 0 ? criminalDetails[0].magistrate : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Magistrate"
                                value={criminalDetailsEdit.magistrate}
                                id="magistrate"
                                name="magistrate"
                                onChange={handleCriminalChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Sessions</td>
                            <td>{criminalDetails.length > 0 ? criminalDetails[0].sessions : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Sessions"
                                value={criminalDetailsEdit.sessions}
                                id="sessions"
                                name="sessions"
                                onChange={handleCriminalChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>High Court</td>
                            <td>{criminalDetails.length > 0 ? criminalDetails[0].highCourt : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="High Court"
                                value={criminalDetailsEdit.highCourt}
                                id="highCourt"
                                name="highCourt"
                                onChange={handleCriminalChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Duration of Records Checked</td>
                            <td>{criminalDetails.length > 0 ? criminalDetails[0].durationOfRecordsChecked : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Duration of Records Checked"
                                value={criminalDetailsEdit.durationOfRecordsChecked}
                                id="durationOfRecordsChecked"
                                name="durationOfRecordsChecked"
                                onChange={handleCriminalChange}
                              />
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={() => updateIndividualVerification(verificationId, "vCriminalVerified")}>
                    Verify
                  </Button>{' '}
                </ModalFooter>
              </Modal>

              <Modal size="lg" isOpen={identityModal} toggle={() => toggleIdentityModal(verificationId)}>
                <ModalHeader toggle={() => toggleIdentityModal(verificationId)}>Identity Details</ModalHeader>
                <ModalBody>
                  <Row className="g-3">
                    <Col sm="12">
                      <Table responsive className="">
                        <thead>
                          <tr>
                            <th>Identity Details</th>
                            <th>Client</th>
                            <th>Verifier</th>
                          </tr>
                          <tr>
                            <td>Full Name</td>
                            <td>{identityDetails.length > 0 ? identityDetails[0].fullName : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Full Name"
                                value={identityDetailsEdit.fullName}
                                id="fullName"
                                name="fullName"
                                onChange={handleIdentityChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Father Name</td>
                            <td>{identityDetails.length > 0 ? identityDetails[0].fatherName : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Father Name"
                                value={identityDetailsEdit.fatherName}
                                id="fatherName"
                                name="fatherName"
                                onChange={handleIdentityChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Date of Birth</td>
                            <td>{identityDetails.length > 0 ? identityDetails[0].dob : ''}</td>
                            <td>
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                value={identityDetailsEdit.dob}
                                id="dob"
                                name="dob"
                                onChange={handleIdentityChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Pancard No</td>
                            <td>{identityDetails.length > 0 ? identityDetails[0].pancardNo : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Pancard No"
                                value={identityDetailsEdit.pancardNo}
                                id="pancardNo"
                                name="pancardNo"
                                onChange={handleIdentityChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Image</td>
                            <td>{identityDetails.length > 0 ? <a href={identityDetails[0].image1} target="_blank"><img src={identityDetails[0].image1} height={50} width={50} /></a> : ''}</td>
                            <td>
                              <Input
                                type="file"
                                id="image1"
                                name="image1"
                                onChange={uploadPancardImage}
                              />
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={() => updateIndividualVerification(verificationId, "vIdentityVerified")}>
                    Get Details from Api
                  </Button>{' '}
                  <Button color="success" onClick={() => updateIndividualVerification(verificationId, "vIdentityVerified")}>
                    Verify
                  </Button>{' '}
                </ModalFooter>
              </Modal>

              <Modal isOpen={referenceModal} toggle={() => toggleReferenceModal(verificationId)}>
                <ModalHeader toggle={() => toggleReferenceModal(verificationId)}>Reference Details</ModalHeader>
                <ModalBody>
                  <Row className="g-3">
                    <Col sm="12">
                      <Table responsive className="">
                        <thead>
                          <tr>
                            <th>Reference Details</th>
                            <th>Client</th>
                            <th>Verifier</th>
                          </tr>
                          <tr>
                            <td>Respondent Name</td>
                            <td>{referenceDetails.length > 0 ? referenceDetails[0].respondentName : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Responden Name"
                                value={referenceDetailsEdit.respondentName}
                                id="respondentName"
                                name="respondentName"
                                onChange={handleReferenceChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Desgination</td>
                            <td>{referenceDetails.length > 0 ? referenceDetails[0].designation : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Designation"
                                value={referenceDetailsEdit.designation}
                                id="designation"
                                name="designation"
                                onChange={handleReferenceChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Organisation</td>
                            <td>{referenceDetails.length > 0 ? referenceDetails[0].organisation : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Organisation"
                                value={referenceDetailsEdit.organisation}
                                id="organisation"
                                name="organisation"
                                onChange={handleReferenceChange}
                              />
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={() => updateIndividualVerification(verificationId, "vReferenceVerified")}>
                    Verify
                  </Button>{' '}
                </ModalFooter>
              </Modal>

              <Modal size='lg' isOpen={drugTestModal} toggle={() => toggleDrugTestModal(verificationId)}>
                <ModalHeader toggle={() => toggleDrugTestModal(verificationId)}>Drug Test Details</ModalHeader>
                <ModalBody>
                  <Row className="g-3">
                    <Col sm="12">
                      <Table responsive className="">
                        <thead>
                          <tr>
                            <th>Drug Test Details</th>
                            <th>Client</th>
                            <th>Verifier</th>
                          </tr>
                          <tr>
                            <td>Name</td>
                            <td>{drugTestDetails.length > 0 ? drugTestDetails[0].name : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Name"
                                value={drugTestDetailsEdit.name}
                                id="name"
                                name="name"
                                onChange={handleDrugTestChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Date of Birth</td>
                            <td>{drugTestDetails.length > 0 ? drugTestDetails[0].dob : ''}</td>
                            <td>
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                value={drugTestDetailsEdit.dob}
                                id="dob"
                                name="dob"
                                onChange={handleDrugTestChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Blood Samples Collected</td>
                            <td>{drugTestDetails.length > 0 ? drugTestDetails[0].bloodSamplesCollected : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Blood Samples Collected"
                                value={drugTestDetailsEdit.bloodSamplesCollected}
                                id="bloodSamplesCollected"
                                name="bloodSamplesCollected"
                                onChange={handleDrugTestChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Collection Location</td>
                            <td>{drugTestDetails.length > 0 ? drugTestDetails[0].collectionLocation : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Collection Location"
                                value={drugTestDetails.collectionLocation}
                                id="collectionLocation"
                                name="collectionLocation"
                                onChange={handleDrugTestChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Test Result</td>
                            <td>{drugTestDetails.length > 0 ? drugTestDetails[0].testResult : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Test Result"
                                value={drugTestDetails.testResult}
                                id="testResult"
                                name="testResult"
                                onChange={handleDrugTestChange}
                              />
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={() => updateIndividualVerification(verificationId, "vDrugTestVerified")}>
                    Verify
                  </Button>{' '}
                </ModalFooter>
              </Modal>

              <Modal size='lg' isOpen={creditCheckModal} toggle={() => toggleCreditCheckModal(verificationId)}>
                <ModalHeader toggle={() => toggleCreditCheckModal(verificationId)}>Credit Check Details</ModalHeader>
                <ModalBody>
                  <Row className="g-3">
                    <Col sm="12">
                      <Table responsive className="">
                        <thead>
                          <tr>
                            <th>Credit Check Details</th>
                            <th>Client</th>
                            <th>Verifier</th>
                          </tr>
                          <tr>
                            <td>Name</td>
                            <td>{creditCheckDetails.length > 0 ? creditCheckDetails[0].name : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Name"
                                value={creditCheckDetailsEdit.name}
                                id="name"
                                name="name"
                                onChange={handleCreditCheckChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Date of Birth</td>
                            <td>{creditCheckDetails.length > 0 ? creditCheckDetails[0].dob : ''}</td>
                            <td>
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                value={creditCheckDetailsEdit.dob}
                                id="dob"
                                name="dob"
                                onChange={handleCreditCheckChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Mobile Number</td>
                            <td>{creditCheckDetails.length > 0 ? creditCheckDetails[0].mobileNumber : ''}</td>
                            <td>
                              <Input
                                type="number"
                                placeholder="Mobile Number"
                                value={creditCheckDetailsEdit.mobileNumber}
                                id="mobileNumber"
                                name="mobileNumber"
                                onChange={handleCreditCheckChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>{creditCheckDetails.length > 0 ? creditCheckDetails[0].address : ''}</td>
                            <td>
                              <Input
                                type="textarea"
                                placeholder="Address"
                                value={creditCheckDetailsEdit.address}
                                id="address"
                                name="address"
                                onChange={handleCreditCheckChange}
                                rows="5"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Pancard No</td>
                            <td>{creditCheckDetails.length > 0 ? creditCheckDetails[0].pancardNo : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Pancard No"
                                value={creditCheckDetailsEdit.pancardNo}
                                id="pancardNo"
                                name="pancardNo"
                                onChange={handleCreditCheckChange}
                              />
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={() => creditCheckApi()}>
                    Verify Details with API
                  </Button>{' '}
                  <Button color="success" onClick={() => updateIndividualVerification(verificationId, "vCreditCheckVerified")}>
                    Verify
                  </Button>{' '}
                </ModalFooter>
              </Modal>

              <Modal size="lg" isOpen={policeVerificationModal} toggle={() => togglePoliceVerificationModal(verificationId)}>
                <ModalHeader toggle={() => togglePoliceVerificationModal(verificationId)}>Police Verification Details</ModalHeader>
                <ModalBody>
                  <Row className="g-3">
                    <Col sm="12">
                      <Table responsive className="">
                        <thead>
                          <tr>
                            <th>Police Verifcation Details</th>
                            <th>Client</th>
                            <th>Verifier</th>
                          </tr>
                          <tr>
                            <td>Name</td>
                            <td>{policeVerificationDetails.length > 0 ? policeVerificationDetails[0].name : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Name"
                                value={policeVerificationDetailsEdit.name}
                                id="name"
                                name="name"
                                onChange={handlePoliceVerificationChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Father Name</td>
                            <td>{policeVerificationDetails.length > 0 ? policeVerificationDetails[0].fatherName : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Father Name"
                                value={policeVerificationDetailsEdit.fatherName}
                                id="fatherName"
                                name="fatherName"
                                onChange={handlePoliceVerificationChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Date of Birth</td>
                            <td>{policeVerificationDetails.length > 0 ? policeVerificationDetails[0].dob : ''}</td>
                            <td>
                              <Input
                                type="date"
                                placeholder="Date of Birth"
                                value={policeVerificationDetailsEdit.dob}
                                id="dob"
                                name="dob"
                                onChange={handlePoliceVerificationChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>{policeVerificationDetails.length > 0 ? policeVerificationDetails[0].address : ''}</td>
                            <td>
                              <Input
                                type="textarea"
                                placeholder="Address"
                                value={policeVerificationDetailsEdit.address}
                                id="address"
                                name="address"
                                onChange={handlePoliceVerificationChange}
                                rows="5"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Civil</td>
                            <td>{policeVerificationDetails.length > 0 ? policeVerificationDetails[0].civil : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Civil"
                                value={policeVerificationDetailsEdit.civil}
                                id="civil"
                                name="civil"
                                onChange={handlePoliceVerificationChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Magistrate</td>
                            <td>{policeVerificationDetails.length > 0 ? policeVerificationDetails[0].magistrate : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Magistrate"
                                value={policeVerificationDetailsEdit.magistrate}
                                id="magistrate"
                                name="magistrate"
                                onChange={handlePoliceVerificationChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Sessions</td>
                            <td>{policeVerificationDetails.length > 0 ? policeVerificationDetails[0].sessions : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Sessions"
                                value={policeVerificationDetailsEdit.sessions}
                                id="sessions"
                                name="sessions"
                                onChange={handlePoliceVerificationChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>High Court</td>
                            <td>{policeVerificationDetails.length > 0 ? policeVerificationDetails[0].highCourt : ''}</td>
                            <td>
                              <Input
                                type="text"
                                placeholder="High Court"
                                value={policeVerificationDetailsEdit.highCourt}
                                id="highCourt"
                                name="highCourt"
                                onChange={handlePoliceVerificationChange}
                              />
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={() => updateIndividualVerification(verificationId, "vPoliceVerificationVerified")}>
                    Verify
                  </Button>{' '}
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default Verification;
