import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useState } from "react";

const BrowserDefaultForm = () => {
  // Define a single state object to store all input values
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    variety: "",
    quantity: 0,
    address: "",
  });

  const handleInputChange = (fieldName: string, value: string | number) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Access the form data from the state
    console.log(formData);
    // Perform other actions, such as sending data to a server
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="g-3">
        <Col xs={12}>
          <Label htmlFor="name">Product Name</Label>
          <Input
            type="text"
            placeholder="Name"
            value={formData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label htmlFor="quantity">Sample Quantity</Label>
          <Input
            type="number"
            placeholder="quantity"
            value={formData.quantity}
            onChange={(e) => handleInputChange("quantity", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label htmlFor="name">Contact Name</Label>
          <Input
            type="text"
            placeholder="Name"
            value={formData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label>Contact Number</Label>
          <Input
            type="text"
            placeholder="+1 2332 2223"
            value={formData.number}
            onChange={(e) => handleInputChange("number", e.target.value)}
            required
          />
        </Col>

        <Col xs={12}>
          <Label htmlFor="address">Address</Label>
          <Label htmlFor="address1">Address Line1</Label>
          <Input
            type="text"
            placeholder="address1"
            value={formData.address}
            onChange={(e) => handleInputChange("address", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label htmlFor="address2">Address Line 2</Label>
          <Input
            type="text"
            placeholder="address2"
            value={formData.address}
            onChange={(e) => handleInputChange("address", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label htmlFor="city">City</Label>
          <Input
            type="text"
            placeholder="city"
            value={formData.address}
            onChange={(e) => handleInputChange("city", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label htmlFor="state">State</Label>
          <Input
            type="text"
            placeholder="state"
            value={formData.address}
            onChange={(e) => handleInputChange("state", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label htmlFor="pincode">Pincode</Label>
          <Input
            type="number"
            placeholder="pincode"
            value={formData.address}
            onChange={(e) => handleInputChange("pincode", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Button color="primary" type="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BrowserDefaultForm;
