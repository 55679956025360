import { Button, Card, Col, Table } from "reactstrap";
import CommonHeader from "../../../../../Common/CommonHeader";
import { HoverableRowsWithHorizontalBorder } from "../../../../../utils/Constant";
import { hoverableRowsBorderSpan } from "../../../../../Data/Tables/ReactStrapTablesData/ReactStrapTablesData";
import HoverableRowsTableHead from "./HoverableRowsTableHead";
import HoverableRowsTableBody from "./HoverableRowsTableBody";
import { useState } from "react";
import LargeModal from "./LargeModal";

const RequirementTable = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const ModalData = {
    isOpen: modal,
    header: true,
    class: "modal-lg",
    toggler: toggle,
    title: "Large modal",
    size: "fullscreen",
  };

  return (
    <Col sm={12}>
      <Card>
        {/* <CommonHeader title={HoverableRowsWithHorizontalBorder} subTitle={hoverableRowsBorderSpan}/> */}
        <div className="table-responsive">
          <LargeModal modalData={ModalData} />
          <Table hover={true} className="table-border-horizontal">
            <HoverableRowsTableHead />
            <HoverableRowsTableBody toggle={toggle} />
          </Table>
        </div>
      </Card>
    </Col>
  );
};

export default RequirementTable;
