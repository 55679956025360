import { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { H3, H4, Image, P } from "../../AbstractElements";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import {
  CreateAccount,
  DoNotAccount,
  EmailAddress,
  ForgotPassword,
  Href,
  Password,
  RememberPassword,
  SignIn,
  SignInAccount,
  SignInWith,
} from "../../utils/Constant";
import { dynamicImage } from "../../Service";
import SocialApp from "./SocialApp";
import axios from "axios";
import { API_BASE_URL, API_KEY, setCookie } from "../Helper/utils";

const Login = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const SimpleLoginHandle = (event) => {
    event.preventDefault();

    var jsonData = JSON.stringify({
      "email": email,
      "password": password
    });

    var config = {
      method: 'post',
      url: API_BASE_URL +  '/auth/webUser/login',
      headers: { 
        'apikey': API_KEY, 
        'Content-Type': 'application/json'
      },
      data : jsonData
    };

    axios(config)
    .then(function (response) {
      if(response.status === 200) {
          console.log(response.data)
          setCookie("at", response.data.accessToken)
          setCookie("rt", response.data.refreshToken)

          localStorage.setItem("login", JSON.stringify(true));

        // if(remember.checked === true){
            localStorage.setItem('email', response.data.email);
            localStorage.setItem('name', response.data.name);
            localStorage.setItem('role', response.data.role);
            localStorage.setItem('position', response.data.position);
            localStorage.setItem('id', response.data.id);
            localStorage.setItem('type', response.data.type);
            toast.success("Login Success...!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            navigate(`${process.env.PUBLIC_URL}/dashboards/verifix`);  
            navigate(0);
        // }
        // else{
        //     sessionStorage.setItem('username', response.data.username);   
        //     sessionStorage.setItem('username', response.data.username);
        //     sessionStorage.setItem('name', response.data.name);
        //     sessionStorage.setItem('role', response.data.role);
        //     sessionStorage.setItem('position', response.data.position);
        //     sessionStorage.setItem('state', response.data.state + ",");
        //     sessionStorage.setItem('bank', response.data.bank + ",");
        //     app.history.push('/dashboard');  
        // }   
      }
    })
    .catch(function (error) {
      console.log(error.response.data);
      toast.error("Please Enter valid email or password...!");
    });

    // if (email === "test123@gmail.com" && password === "Test@123") {
    //   localStorage.setItem("login", JSON.stringify(true));
    //   toast.success("Login Success...!", {
    //     position: "top-right",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //   });
    //   navigate(`${process.env.PUBLIC_URL}/dashboards/verifix`);
    // } else {
    //   toast.error("Please Enter valid email or password...!");
    // }
  };
  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs={12} className="p-0">
          <div className="login-card login-dark">
            <div>
              <div>
                <Link className="logo text-center" to={Href}>
                  {/* <Image
                    className="img-fluid for-light"
                    src={dynamicImage("logo/logo-1.png")}
                    alt="looginpage"
                  />
                  <Image
                    className="img-fluid for-dark"
                    src={dynamicImage("logo/logo.jpeg")}
                    alt="looginpage"
                  /> */}
                </Link>
              </div>
              <div className="login-main">
                <Form
                  className="theme-form"
                  onSubmit={(e) => SimpleLoginHandle(e)}
                >
                  <H3>{SignInAccount}</H3>
                  <P>{"Enter your email & password to login"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      type="email"
                      required
                      placeholder="Test@gmail.com"
                      value={email}
                      name="email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <div className="form-input position-relative">
                      <Input
                        type={show ? "text" : "password"}
                        placeholder="*********"
                        onChange={(event) => setPassword(event.target.value)}
                        value={password}
                        name="password"
                      />
                      <div className="show-hide" onClick={() => setShow(!show)}>
                        <span className="show"> </span>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-0 form-sub-title">
                    {/* <div className="checkbox p-0">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" htmlFor="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div> */}
                    {/* <Link
                      className="link"
                      to={`${process.env.PUBLIC_URL}/pages/authentication/forgetpassword`}
                    >
                      {ForgotPassword}{" "}
                    </Link> */}
                    <div className="text-end mt-3">
                      <Button
                        color="primary"
                        className="btn-block w-100"
                        type="submit"
                      >
                        {SignIn}{" "}
                      </Button>
                    </div>
                  </FormGroup>
                  {/* <H4 className="text-muted mt-4 or">{SignInWith}</H4> */}
                  {/* <SocialApp /> */}
                  {/* <P className="mt-4 mb-0 text-center"> {DoNotAccount}
                    <Link className="ms-2" to={`${process.env.PUBLIC_URL}/pages/authentication/registersimple`} >{CreateAccount}</Link>
                  </P> */}
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
