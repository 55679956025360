import { Card, CardBody, Col } from "reactstrap";
import { BrowserDefaultsHeading } from "../../../../../utils/Constant";
import BrowserDefaultForm from "./BrowserDefaultForm";
import CommonHeader from "../../../../../Common/CommonHeader";

const AdminUserForm = () => {
  const subTitle = [
    {
      text: ``,
    },
  ];
  return (
    <Col xl={12}>
      <Card className="height-equal">
        {/* <CommonHeader
          title={"BrowserDefaultsHeading"}
          subTitle={subTitle}
          headClass="pb-0"
        /> */}
        <CardBody className="custom-input">
          <BrowserDefaultForm />
        </CardBody>
      </Card>
    </Col>
  );
};

export default AdminUserForm;
