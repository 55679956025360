import { hoverTableData } from "../../../../../Data/Tables/ReactStrapTablesData/ReactStrapTablesData";
import { dummyData } from "./req";

interface TableParam {
  toggler: () => void;
}

const HoverableRowsTableBody = ({ toggle }: { toggle: any }) => {
  return (
    <tbody>
      {dummyData.map((item) => (
        <tr key={item.SlNo} onClick={toggle}>
          <th scope="row">{item.SlNo}</th>
          <td>
            <div className="d-flex align-items-center">
              <span
                className={`${item.BuyerName} rounded-100 p-1 me-2 d-flex align-items-center`}
              ></span>
              {item.PortOfDelivery}
            </div>
          </td>
          <td>{item.PackagingType}</td>
          <td>{item.INCOTerms}</td>
          <td>{item.ProductName}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default HoverableRowsTableBody;
