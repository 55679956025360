import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";

const ProcessOrder = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Process Order"}
          parent={"Report"}
          title={"Process Order"}
        />
        {/* <Row></Row> */}
      </Container>
    </>
  );
};

export default ProcessOrder;
