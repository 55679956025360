import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useState } from "react";

const BrowserDefaultForm = () => {
  // Define a single state object to store all input values
  const [formData, setFormData] = useState({
    name: "",
    poNumber: "",
    date: "",
    quantity: 0,
    total: 0,
  });

  const handleInputChange = (fieldName: string, value: string | number) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Access the form data from the state
    console.log(formData);
    // Perform other actions, such as sending data to a server
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="g-3">
        <Col xs={12}>
          <Label htmlFor="name"> {"Name"}</Label>
          <Input
            type="text"
            placeholder="Name"
            value={formData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label>{"PO Number"}</Label>
          <Input
            type="text"
            placeholder="2332"
            value={formData.poNumber}
            onChange={(e) => handleInputChange("poNumber", e.target.value)}
            required
          />
        </Col>

        <Col xs={12}>
          <Label htmlFor="date">Date</Label>
          <Input
            type="text"
            placeholder="date"
            value={formData.date}
            onChange={(e) => handleInputChange("date", e.target.value)}
            required
          />
        </Col>

        <Col xs={12}>
          <Label htmlFor="total">Total</Label>
          <Input
            type="number"
            placeholder="total"
            value={formData.total}
            onChange={(e) => handleInputChange("total", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Button color="primary" type="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BrowserDefaultForm;
