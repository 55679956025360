import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import SellerForm from "../Forms/FormsControl/FormValidition/SellerForm/BrowserDefault";
import SellerTable from "./SellerTable/HoverableRowsClass";

const SellerFormPage = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Seller Form"}
          parent={"Manage"}
          title={"Seller"}
        />
        {/* <Row></Row> */}
        <SellerForm />
        {/* <SellerTable /> */}
      </Container>
    </>
  );
};

export default SellerFormPage;
