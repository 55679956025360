export const leftSideData = [
    {
        classMain: 'ribbon-wrapper border border-1 height-equal alert-light-light',
        ribbonClass: 'ribbon ribbon-primary ribbon-space-bottom',
        ribbonText: 'SAVE 10%',
        subText:" <p><em className='txt-danger'>Ribbon designs</em> have been a part of web design. There is a design for you in this collection, regardless of whether you use <em className='txt-danger'>CSS ribbons </em> to identify your items.</p>"
    },
    {
        classMain: 'ribbon-wrapper border border-1 height-equal',
        ribbonClass: 'ribbon ribbon-secondary ribbon-clip',
        ribbonText: 'SAVE 50%',
        subText:" <p>The <em className='txt-danger'>ribbon and tag</em> frequently appear together because they have similar features that capture attention and make wonderful spaces for vital information.</p>"
    },
    {
        classMain: 'ribbon-vertical-left-wrapper border border-1 vertical-lp-space height-equal alert-light-light',
        ribbonClass: 'ribbon ribbon-warning ribbon-vertical-left',
        ribbonText: "<i className='icofont icofont-love' />",
        subText: "<p>The <em className='txt-danger'>ribbons</em> have also been utilised by certain inventive developers in the menu and navigation choices.The possibilities are endless when it comes to creativity. </p>"
    },
    {
        classMain: 'ribbon-wrapper border border-1 height-equal',
        ribbonClass: 'ribbon ribbon-info ribbon-bookmark',
        ribbonText: 'Cashback 20%',
        subText: "<p> Although the majority of the <em className='txt-danger'>ribbon components</em> on this list have static, straightforward styles, we have also gathered dynamic ribbons.</p>"
    },
    {
        classMain: 'ribbon-wrapper-bottom border border-1 clip-bp-space height-equal alert-light-light',
        ribbonClass: 'ribbon ribbon-dark ribbon-clip-bottom',
        ribbonText: '40% OFF',
        subText:" <p>Edge <em className='txt-danger'>ribbon </em>among the most often used <em className='txt-danger'>ribbon styles is CSS.</em> This design's author provides you with a short piece of code that has room for several optional components and effects.</p>"
    },
    {
        classMain: 'ribbon-vertical-left-wrapper border border-1 vertical-lp-space height-equal',
        ribbonClass: 'ribbon ribbon-bookmark ribbon-vertical-left ribbon-danger',
        ribbonText:" <i className='icon-gift' />",
        subText: "<p>The entire ribbon and other components seem clean since they were created entirely with CSS. You may utilize the <em className='txt-danger'>ribbons</em> as cards to deliver the material.</p>"
    }
]

export const rightSideData = [
    {
        classMain: 'ribbon-wrapper border border-1 height-equal',
        ribbonClass: 'ribbon ribbon-dark ribbon-right',
        ribbonText: '50% OFF',
        subText: "<p>The <em className='txt-danger'>ribbon and tag</em> frequently appear together because they have similar features that capture attention and make wonderful spaces for vital information.</p>"
    },
    {
        classMain: 'ribbon-wrapper-right border border-1 height-equal alert-light-light',
        ribbonClass: 'ribbon ribbon-primary ribbon-clip-right ribbon-right',
        ribbonText: 'SAVE 50%',
        subText: "<p>Since very early times, <em className='txt-danger'>ribbon designs</em> have been a part of web design. There is a design for you in this collection, regardless of whether you use <em className='txt-danger'>CSS ribbons </em> to identify your items.</p>"
    },
    {
        classMain: 'ribbon-vertical-right-wrapper border border-1 vertical-rp-space height-equal',
        ribbonClass: 'ribbon ribbon-bookmark ribbon-vertical-right ribbon-secondary',
        ribbonText: "<i className='icon-signal' />",
        subText: "<p>The entire ribbon and other components seem clean since they were created entirely with CSS. You may utilize the <em className='txt-danger'>ribbons</em> as cards to deliver the material. </p>"
    },
    {
        classMain: 'ribbon-wrapper border border-1 height-equal alert-light-light',
        ribbonClass: 'ribbon ribbon-warning ribbon-bookmark ribbon-right',
        ribbonText: 'SAVE 20%',
        subText: "<p>Edge <em className='txt-danger'>ribbon </em>among the most often used <em className='txt-danger'>ribbon styles is CSS.</em> This design's author provides you with a short piece of code that has room for several optional components and effects.</p>"
    },
    {
        classMain: 'ribbon-wrapper-bottom border border-1 clip-bp-space height-equal',
        ribbonClass: 'ribbon ribbon-success ribbon-clip-bottom-right',
        ribbonText: 'Cashback 10%',
        subText: "<p> Although the majority of the   <em className='txt-danger'>ribbon components</em> on this list have static, straightforward styles, we have also gathered dynamic ribbons.</p>"
    },
    {
        classMain: 'ribbon-vertical-right-wrapper border border-1 vertical-rp-space height-equal alert-light-light',
        ribbonClass: 'ribbon ribbon-danger ribbon-vertical-right',
        ribbonText:" <i className='fa fa-taxi' />",
        subText: "<p>The <em className='txt-danger'>ribbons</em> have also been utilized by certain inventive developers in the menu and navigation choices. The possibilities are endless when it comes to creativity.</p>"
    }
]