import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import BuyerForm from "../Forms/FormsControl/FormValidition/BuyerForm/BrowserDefault";
import SampleRequestForm from "../Forms/FormsControl/FormValidition/SampleRequestForm/BrowserDefault";

const SampleRequestFormPage = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Sample Request Form"}
          parent={"Report"}
          title={"Sample request"}
        />
        {/* <Row></Row> */}
        <SampleRequestForm />
      </Container>
    </>
  );
};

export default SampleRequestFormPage;
