import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import SellerForm from "../Forms/FormsControl/FormValidition/SellerForm/BrowserDefault";
import SellerTable from "./SellerTable/HoverableRowsClass";
import DropdownAddForm from "../Forms/FormsControl/FormValidition/DropdownAddForm/BrowserDefault";
import DropdownTable from "./DropdownMenuTable/HoverableRowsClass";

const INCOFormPage = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"INCO Terms Form"}
          parent={"Manage"}
          title={"INCO terms"}
        />
        {/* <Row></Row> */}
        <DropdownAddForm />
        <DropdownTable
          data={[
            { name: "FOD", status: "active" },
            { name: "IBM", status: "active" },
          ]}
        />
      </Container>
    </>
  );
};

export default INCOFormPage;
