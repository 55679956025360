import { Button, Card, Col, Table } from "reactstrap";
import HoverableRowsTableHead from "./HoverableRowsTableHead";
import HoverableRowsTableBody from "./HoverableRowsTableBody";
import { useState } from "react";

const DropdownTable = ({
  data,
}: {
  data: {
    name: string;
    status: string;
  }[];
}) => {
  return (
    <Col sm={12}>
      <Card>
        <div className="table-responsive">
          <Table hover={true} className="table-border-horizontal">
            <HoverableRowsTableHead />
            <HoverableRowsTableBody data={data} />
          </Table>
        </div>
      </Card>
    </Col>
  );
};

export default DropdownTable;
