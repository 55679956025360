export const ticketData = [
  {
    id: 1,
    title: "New",
    num: 2563,
    class: "info",
  },
  {
    id: 2,
    title: "In Progress",
    num: 8943,
    class: "warning",
  },
  {
    id: 3,
    title: "Confirmed",
    num: 2500,
    class: "success",
  },
];
