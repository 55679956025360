import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import RequirementForm from "../Forms/FormsControl/FormValidition/RequirementForm/BrowserDefault";

const RequirementFormPage = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Requirement Form"}
          parent={"Manage"}
          title={"Requirement"}
        />
        {/* <Row></Row> */}
        <RequirementForm />
      </Container>
    </>
  );
};

export default RequirementFormPage;
