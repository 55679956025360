import { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Table, Button } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import { API_BASE_URL, API_KEY, getCookie, sessionExpired } from "../Helper/utils";
import axios from 'axios';

const AgentInActive = () => {
    const [apiData, setApiData] = useState([]);

    useEffect(() => {
        let jsonData = {
            approvalStatus: "Approved"
        }
        let jsonData1 = JSON.stringify(jsonData);

        var config = {
            method: 'post',
            url: API_BASE_URL + '/api/agentsList',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
                'Content-Type': 'application/json'
            },
            data: jsonData1
        };

        axios(config)
            .then(function (response) {
                setApiData(response.data)
            })
            .catch(function (error) {
                console.log(error);
                handleNon200Response(error);
            });
    }, [apiData])

    const handleNon200Response = (error) => {
        if (error.response.status === 401 && error.response.data.message === "User Not authenticated") {
            sessionExpired();
        }
    }

    const blockRow = (key) => {
        if (window.confirm('Are you sure you want to block the agent?')) {
            var jsonData = JSON.stringify({
                "approvalStatus": "Pending",
            })

            var config = {
                method: "put",
                url: API_BASE_URL + "/api/agent/" + key._id,
                headers: {
                    apikey: API_KEY,
                    Authorization: "Bearer " + getCookie("at"),
                    "Content-Type": "application/json",
                },
                data: jsonData,
            }

            axios(config)
                .then(function (response) {
                    console.log(response.data)
                    if (response.status == 200) {
                        alert("Blocked Successfully!!")
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    handleNon200Response(error, "2")
                })
        } else { }
    }

    return (
        <>
            <Container className="basic_table" fluid>
                <Breadcrumbs
                    mainTitle={"Agent InActive"}
                    parent={"Masters"}
                    title={"Agent"}
                />
                <Col xl={12}>
                    <Card className="height-equal">

                        <CardBody className="custom-input">
                            <Table className="display order-wrapper" id="recent-order">
                                <thead>
                                    <tr>
                                        <th>Sl.No</th>
                                        <th>Name</th>
                                        <th>Number</th>
                                        <th>Email</th>
                                        <th>ID Proof</th>
                                        <th>Address Proof</th>
                                        <th>Approve</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {apiData.map((rowData, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{rowData.name}</td>
                                            <td>{rowData.number}</td>
                                            <td>{rowData.email}</td>
                                            <td>{rowData.idProof}</td>
                                            <td><a href={rowData.adressProofImage} target="_blank">{rowData.adressProofImage}</a></td>
                                            <td><Button color="danger" onClick={() => blockRow(rowData)}>Block</Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Container>
        </>
    );
};

export default AgentInActive;
