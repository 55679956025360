import { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Label, Input, Button, Form, Table } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, sessionExpired } from "../Helper/utils";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CreateUsers = () => {
  const [apiData, setApiData] = useState([]);
  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    role: '',
    pushId: '',
    dash1: false,
    verification1: false,
    verification2: false,
    masters1: false,
    masters2: false,
    masters3: false,
    masters4: false,
    masters5: false,
    report1: false,
    report2: false,
    report3: false,
    report4: false,
    report5: false
  })

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const defaultState = () => {
    setState({
      ...state,
      name: '',
      email: '',
      password: '',
      role: '',
      pushId: '',
      verification1: false,
      verification2: false,
      masters1: false,
      masters2: false,
      masters3: false,
      masters4: false,
      masters5: false,
      report1: false,
      report2: false,
      report3: false,
      report4: false,
      report5: false
    })
  }

  useEffect(() => {
    var config = {
      method: 'get',
      url: API_BASE_URL + '/api/webUser',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
      }
    };

    axios(config)
      .then(function (response) {
        setApiData(response.data)
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error);
      });
  }, [apiData])

  const handleNon200Response = (error) => {
    if (error.response.status === 401 && error.response.data.message === "User Not authenticated") {
      sessionExpired();
    }
  }

  const handleSubmit = () => {
    if (state.name === '') {
      alert("Enter Name")
      document.getElementById("name").focus();
      return;
    }

    if (state.email === '') {
      alert("Enter Email")
      document.getElementById("email").focus();
      return;
    }

    if (state.password === '') {
      alert("Enter Password")
      document.getElementById("password").focus();
      return;
    }

    let roles = ""
    if (state.dash1 == true) {
      roles += "dash1" + ","
    }

    if (state.verification1 == true) {
      roles += "verification1" + ","
    }

    if (state.verification2 == true) {
      roles += "verification2" + ","
    }

    if (state.masters1 == true) {
      roles += "masters1" + ","
    }

    if (state.masters2 == true) {
      roles += "masters2" + ","
    }

    if (state.masters3 == true) {
      roles += "masters3" + ","
    }

    if (state.masters4 == true) {
      roles += "masters4" + ","
    }

    if (state.masters5 == true) {
      roles += "masters5" + ","
    }

    if (state.report1 == true) {
      roles += "report1" + ","
    }

    if (state.report2 == true) {
      roles += "report2" + ","
    }

    if (state.report3 == true) {
      roles += "report3" + ","
    }

    if (state.report4 == true) {
      roles += "report4" + ","
    }


    var jsonData = JSON.stringify({
      "name": state.name,
      "email": state.email,
      "password": state.password,
      "role": roles,
      "position": "ADMIN"
    });

    var config = {
      method: 'post',
      url: API_BASE_URL + "/api/webUser",
      headers: {
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at'),
        'Content-Type': 'application/json'
      },
      data: jsonData
    };

    axios(config)
      .then(function (response) {
        if (response.status === 201) {
          defaultState()
          alert("Added Successfully")
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });
  }

  const handleUpdate = () => {
    if (state.name === '') {
      alert("Enter Name")
      document.getElementById("name").focus();
      return;
    }

    if (state.email === '') {
      alert("Enter Email")
      document.getElementById("email").focus();
      return;
    }

    if (state.password === '') {
      alert("Enter Password")
      document.getElementById("password").focus();
      return;
    }

    let roles = ""
    if (state.dash1 == true) {
      roles += "dash1" + ","
    }

    if (state.verification1 == true) {
      roles += "verification1" + ","
    }

    if (state.verification2 == true) {
      roles += "verification2" + ","
    }

    if (state.masters1 == true) {
      roles += "masters1" + ","
    }

    if (state.masters2 == true) {
      roles += "masters2" + ","
    }

    if (state.masters3 == true) {
      roles += "masters3" + ","
    }

    if (state.masters4 == true) {
      roles += "masters4" + ","
    }

    if (state.masters5 == true) {
      roles += "masters5" + ","
    }

    if (state.report1 == true) {
      roles += "report1" + ","
    }

    if (state.report2 == true) {
      roles += "report2" + ","
    }

    if (state.report3 == true) {
      roles += "report3" + ","
    }

    if (state.report4 == true) {
      roles += "report4" + ","
    }

    var jsonData = JSON.stringify({
      "name": state.name,
      "email": state.email,
      "password": state.password,
      "role": roles,
    });

    var config = {
      method: 'put',
      url: API_BASE_URL + "/api/webUser/" + state.pushId,
      headers: {
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at'),
        'Content-Type': 'application/json'
      },
      data: jsonData
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          defaultState()
          alert("Updated Successfully")
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });

    document.getElementById("submit").style.display = "block"
    document.getElementById("update").style.display = "none"
  }

  const editRow = (key) => {
    document.getElementById("submit").style.display = "none"
    document.getElementById("update").style.display = "block"

    setState({
      ...state,
      name: key.name,
      email: key.email,
      password: key.password,
      role: key.role,
      pushId: key._id,
      dash1: key.role.includes("dash1") ? true : false,
      verification1: key.role.includes("verification1") ? true : false,
      verification2: key.role.includes("verification2") ? true : false,
      masters1: key.role.includes("masters1") ? true : false,
      masters2: key.role.includes("masters2") ? true : false,
      masters3: key.role.includes("masters3") ? true : false,
      masters4: key.role.includes("masters4") ? true : false,
      masters5: key.role.includes("masters5") ? true : false,
      report1: key.role.includes("report1") ? true : false,
      report2: key.role.includes("report2") ? true : false,
      report3: key.role.includes("report3") ? true : false,
      report4: key.role.includes("report4") ? true : false,
    })
  }

  const deleteRow = (key) => {
    if (window.confirm('Are you sure you want to delete?')) {
      var config = {
        method: 'delete',
        url: API_BASE_URL + '/api/webUser/' + key._id,
        headers: {
          'apikey': API_KEY,
          'Authorization': 'Bearer ' + getCookie('at')
        },
      };

      axios(config)
        .then(function (response) {
          if (response.status == 200) {
            defaultState()
            alert("Deleted Successfully")
          }
        })
        .catch(function (error) {
          console.log(error)
          handleNon200Response(error)
        });
    } else { }
  }

  const checkBoxOnChange = (evt) => {
    const value = evt.target.checked;
    setState({
      ...state,
      [evt.target.name]: value
    });
  }

  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Create Users"}
          parent={"Masters"}
          title={"Users"}
        />
        <Col xl={12}>
          <Card className="height-equal">
            {/* <CommonHeader
                title={"BrowserDefaultsHeading"}
                subTitle={subTitle}
                headClass="pb-0"
                /> */}
            <CardBody className="custom-input">
              {/* <Form> */}
              <Row className="g-3">
                <Col xs={12}>
                  <Label htmlFor="first-name"> {"Name"}</Label>
                  <Input
                    type="text"
                    placeholder="Name"
                    value={state.name}
                    id="name"
                    name="name"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col xs={12}>
                  <Label>{"Email"}</Label>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={state.email}
                    id="email"
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col xs={12}>
                  <Label>Password</Label>
                  <Input
                    type="password"
                    placeholder="Password"
                    value={state.password}
                    id="password"
                    name="password"
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={4}>
                  <h4><b>Verification</b></h4> <br />
                  <Col xs={12}>
                    <input
                      type="checkbox"
                      checked={state.verification1}
                      onChange={checkBoxOnChange}
                      name="verification1"
                    />&nbsp;
                    <Label>Create Verification</Label>
                  </Col>
                  <Col xs={12}>
                    <input
                      type="checkbox"
                      checked={state.verification2}
                      onChange={checkBoxOnChange}
                      name="verification2"
                    />&nbsp;
                    <Label>Verification</Label>
                  </Col>
                </Col>
                <Col xs={4}>
                  <h4><b>Masters</b></h4> <br />
                  <Col xs={12}>
                    <input
                      type="checkbox"
                      checked={state.masters1}
                      onChange={checkBoxOnChange}
                      name="masters1"
                    />&nbsp;
                    <Label>Create Client</Label>
                  </Col>
                  <Col xs={12}>
                    <input
                      type="checkbox"
                      checked={state.masters2}
                      onChange={checkBoxOnChange}
                      name="masters2"
                    />&nbsp;
                    <Label>Create Users</Label>
                  </Col>
                  <Col xs={12}>
                    <input
                      type="checkbox"
                      checked={state.masters3}
                      onChange={checkBoxOnChange}
                      name="masters3"
                    />&nbsp;
                    <Label>Agent Approvals</Label>
                  </Col>
                  <Col xs={12}>
                    <input
                      type="checkbox"
                      checked={state.masters4}
                      onChange={checkBoxOnChange}
                      name="masters4"
                    />&nbsp;
                    <Label>Agent InActive</Label>
                  </Col>
                  <Col xs={12}>
                    <input
                      type="checkbox"
                      checked={state.masters5}
                      onChange={checkBoxOnChange}
                      name="masters5"
                    />&nbsp;
                    <Label>Email Template</Label>
                  </Col>
                </Col>
                <Col xs={4}>
                  <h4><b>Reports</b></h4> <br />
                  <Col xs={12}>
                    <input
                      type="checkbox"
                      checked={state.report1}
                      onChange={checkBoxOnChange}
                      name="report1"
                    />&nbsp;
                    <Label>Verification Report</Label>
                  </Col>
                  <Col xs={12}>
                    <input
                      type="checkbox"
                      checked={state.report2}
                      onChange={checkBoxOnChange}
                      name="report2"
                    />&nbsp;
                    <Label>Client Report</Label>
                  </Col>
                  <Col xs={12}>
                    <input
                      type="checkbox"
                      checked={state.report3}
                      onChange={checkBoxOnChange}
                      name="report3"
                    />&nbsp;
                    <Label>User Report</Label>
                  </Col>
                  <Col xs={12}>
                    <input
                      type="checkbox"
                      checked={state.report4}
                      onChange={checkBoxOnChange}
                      name="report4"
                    />&nbsp;
                    <Label>Agent Repprt</Label>
                  </Col>
                </Col>
              </Row>
              <br /><br />
              <Row>
                <Col xs={12}>
                  <Button color="primary" type="submit" id="submit" onClick={handleSubmit}>
                    Submit
                  </Button>
                  <Button color="primary" type="submit" id="update" onClick={handleUpdate} style={{ display: "none" }}>
                    Update
                  </Button>
                </Col>
              </Row>
              {/* </Form> */}
            </CardBody>
          </Card>
        </Col>
        <br />
        <Col xl={12}>
          <Card className="height-equal">

            <CardBody className="custom-input">
              <Table responsive className="display order-wrapper" id="recent-order">
                <thead>
                  <tr>
                    <th>SL.No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Password</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData.map((rowData, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{rowData.name}</td>
                      <td>{rowData.email}</td>
                      <td>{rowData.password}</td>
                      <td onClick={() => editRow(rowData)}><FontAwesomeIcon style={{ color: "black", fontSize: "12px", cursor: "pointer" }} icon={faEdit} /></td>
                      <td onClick={() => deleteRow(rowData)}><FontAwesomeIcon style={{ color: "red", fontSize: "12px", cursor: "pointer" }} icon={faTrash} /></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default CreateUsers;
