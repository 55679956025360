import {
  Id,
  Schedule,
  Security,
  SignalName,
  Stage,
  Status,
  TeamLead,
} from "../../../utils/Constant";

const HoverableRowsTableHead = () => {
  return (
    <thead>
      <tr>
        <th scope="col">No</th>
        <th scope="col">Name</th>
        <th scope="col">Status</th>
      </tr>
    </thead>
  );
};

export default HoverableRowsTableHead;
