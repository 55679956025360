import { Badge } from "reactstrap";

interface PropItems {
  data: {
    name: string;
    status: string;
  }[];
}

const HoverableRowsTableBody: React.FC<PropItems> = ({ data }) => {
  return (
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{item.name}</td>
          <td>
            <Badge
              color={item.status === "active" ? "primary" : "warning"}
              className={item.status}
            >
              {
                <>
                  <span className="f-14">{item.status}</span>
                </>
              }
            </Badge>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default HoverableRowsTableBody;
