import { useEffect, useState } from 'react';
import { Badge, Card, CardBody, Col, Row } from "reactstrap";
import SVG from "../../../../utils/CommonSvgIcon/SVG";
import { totalEarningData } from "../../../../Data/Dashboard/ShoppingPlace/TotalEarning";
import ReactApexChart from "react-apexcharts";
import { H3, H5, P } from "../../../../AbstractElements";
import { API_BASE_URL, API_KEY, getCookie, sessionExpired } from "../../../Helper/utils";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Bar, Line, Pie } from 'react-chartjs-2';

const TotalEarning = () => {
  const [state, setState] = useState({
    pendingVerification: 0,
    inProgressVerification: 0,
    completedVerification: 0,
    rejectedVerification: 0,
    verificationDataAnalytics: {
      labels: ['Pending Verification', 'InProgress Verification', 'Completed Verification', 'Rejected Verification'],
      datasets: [
        {
          label: '# of Verifications',
          data: [0, 0, 0],
          backgroundColor: [
            '#0096FF',
            '#FFD700',
            '#008000',
            '#FF0000',
          ],
          borderColor: [
            '#0096FF',
            '#FFD700',
            '#008000',
            '#FF0000',
          ],
          borderWidth: 1,
        },
      ],
    },
  })
  const navigate = useNavigate();

  const userType = localStorage.getItem("type")

  useEffect(() => {
    fetchList();
  }, [])

  const fetchList = () => {
    let jsonData = {}
    if (userType == "CLIENT") {
      jsonData.clientId = localStorage.getItem("id");
    }

    let jsonData1 = JSON.stringify(jsonData)

    let config = {
      method: 'post',
      url: API_BASE_URL + '/api/dashboard/verification',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };

    axios(config)
      .then(function (response) {
        console.log("response.data", response.data);
        setState({
          ...state,
          pendingVerification: response.data.pendingVerification,
          inProgressVerification: response.data.inProgressVerification,
          completedVerification: response.data.completedVerification,
          rejectedVerification: response.data.rejectedVerification,
          verificationDataAnalytics: {
            labels: ['Pending Verification', 'InProgress Verification', 'Completed Verification', 'Rejected Verification'],
            datasets: [
              {
                label: '# of Verifications',
                data: [response.data.pendingVerification, response.data.inProgressVerification, response.data.completedVerification, response.data.rejectedVerification],
                backgroundColor: [
                  '#0096FF',
                  '#FFD700',
                  '#008000',
                  '#FF0000',
                ],
                borderColor: [
                  '#0096FF',
                  '#FFD700',
                  '#008000',
                  '#FF0000',
                ],
                borderWidth: 1,
              },
            ],
          },

        })
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error);
      });
  }

  const handleNon200Response = (error) => {
    if (error.response.status === 401 && error.response.data.message === "User Not authenticated") {
      sessionExpired();
    }
  }

  const moveTothePage = (path) => {
    navigate(`${process.env.PUBLIC_URL}${path}`);
  }

  return (
    <>
      {/* {totalEarningData.map((data,index) => ( */}
      <Col xl="3" lg="6" sm="12" className="box-col-4">
        <Card className="total-earning" style={{ cursor: "pointer" }} onClick={() => moveTothePage("/verification")}>
          <CardBody>
            <Row>
              <Col sm="12" className="box-col-7">
                <div className="d-flex">
                  {/* <Badge color="transperant" className={`bg-light-${data.badgeColor} badge-rounded font-primary me-2`}>
                      {data.badge ? data.badge : <SVG iconId={data.svgIcon}/>}
                    </Badge> */}
                  <div className="flex-grow-1">
                    <H3>Pending Verification</H3>
                  </div>
                </div>
                <H5>{state.pendingVerification}</H5>
                {/* <P className="d-flex">
                    <span className={`bg-light-${data.color} me-2`}>
                      <SVG iconId={data.icon} />
                    </span>
                    <span className={`font-${data.color} me-2`}>{data.percentage}</span>
                    <span>{data.time}</span>
                  </P> */}
              </Col>
              {/* <Col sm="5" className={`box-col-5 ${data.chartClass}`}>
                  <ReactApexChart id={data.chartId} options={data.charts} series={data.charts.series} type={data.charts.chart?.type} height={data.charts.chart?.height} />
                </Col> */}
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col xl="3" lg="6" sm="12" className="box-col-4">
        <Card className="total-earning" style={{ cursor: "pointer" }} onClick={() => moveTothePage("/verification")}>
          <CardBody>
            <Row>
              <Col sm="12" className="box-col-7">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <H3>InProgress Verification</H3>
                  </div>
                </div>
                <H5>{state.inProgressVerification}</H5>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col xl="3" lg="6" sm="12" className="box-col-4">
        <Card className="total-earning" style={{ cursor: "pointer" }} onClick={() => moveTothePage("/verification")}>
          <CardBody>
            <Row>
              <Col sm="12" className="box-col-7">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <H3>Completed Verification</H3>
                  </div>
                </div>
                <H5>{state.completedVerification}</H5>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col xl="3" lg="6" sm="12" className="box-col-4">
        <Card className="total-earning" style={{ cursor: "pointer" }} onClick={() => moveTothePage("/verification")}>
          <CardBody>
            <Row>
              <Col sm="12" className="box-col-7">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <H3>Rejected Verification</H3>
                  </div>
                </div>
                <H5>{state.rejectedVerification}</H5>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      <Col xl="6" lg="6" sm="12" className="box-col-4">
        <Card className="mini-stats-wid">
          <CardBody>
            <Row>
              <Col sm="12" className="box-col-7">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <Pie data={state.verificationDataAnalytics} height="300px" width="300px" options={{ maintainAspectRatio: false }} />
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {/* <Card className="total-earning" style={{ cursor: "pointer" }} onClick={() => moveTothePage("/verification")}>
          <CardBody>
            <Row>
              <Col sm="12" className="box-col-7">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <H3>Rejected Verification</H3>
                  </div>
                </div>
                <H5>{state.rejectedVerification}</H5>
              </Col>
            </Row>
          </CardBody>
        </Card> */}
      </Col>
      {/* ))} */}
    </>
  );
};

export default TotalEarning;
