import { Button, Card, CardBody, Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import SampleRequestTable from "../Tables/ReactStrapTables/BasicTables/SampleRequestTable/HoverableRowsClass";
import TicketList from "../MISCELLANEOUS/SupportTicket/TicketList";
import { Fragment } from "react";
import CommonButtonsToolTip from "../Buttons/CommonButtons/CommonButtonsToolTip";
import { buttons } from "./buttons";
import SampleRequestCard from "../MISCELLANEOUS/Stats/SampleRequestat";

const SampleRequest = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Card>
          <CardBody>
            <Breadcrumbs
              mainTitle={"SampleRequest"}
              parent={"SampleRequest"}
              title={"Table"}
            />
            <SampleRequestCard />
          </CardBody>
        </Card>
        {/* <Row></Row> */}
        <CardBody>
          <Card>
            <CardBody>
              <div className="btn-showcase">
                {buttons.map((data, index) => (
                  <Fragment key={index}>
                    <Button
                      className={`btn-pill ${
                        "raised" && `btn-air-${data.color}`
                      }`}
                      active={data.active}
                      disabled={data.disabled}
                      size={data.size ? data.size : ""}
                      id={data.id}
                      color={data.color}
                    >
                      {data.tittle}
                    </Button>
                    <CommonButtonsToolTip
                      toolTipText={data.toolTipText ? data.toolTipText : ""}
                      id={data.id ? data.id : ""}
                    />
                  </Fragment>
                ))}
              </div>
            </CardBody>
            <SampleRequestTable />
          </Card>
        </CardBody>
      </Container>
    </>
  );
};

export default SampleRequest;
