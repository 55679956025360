import React, { useEffect, useState } from "react";
import CreateVerification from "./createVerification";
import { useParams } from "react-router-dom";
import { cyptoKey } from "../../utils/Constant";
import CryptoJS from "crypto-js";
import { API_BASE_URL, API_KEY, getCookie } from "../Helper/utils";
import axios from "axios";

const PendingLinkVerification = () => {
  const { id } = useParams();
  const [getByVerificarionNumber, setGetByVerificarionNumber] = useState();

  const modifiedId = id.replace(/-/g, "/");

  var bytes = CryptoJS.AES.decrypt(modifiedId, cyptoKey);
  var originalId = bytes.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    if (originalId) {
      handleGetUserData();
    }
  }, []);

  const handleGetUserData = () => {
    var config = {
      method: "get",
      url: API_BASE_URL + "/auth/verificationLink/" + originalId,
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setGetByVerificarionNumber(response.data);
        }
      })
      .catch(function (error) {
        console.log(error.response.data);
      });
  };

  return (
    <>
      <CreateVerification
        getByVerificarionNumber={getByVerificarionNumber}
        createVerificationLink={true}
      />
    </>
  );
};

export default PendingLinkVerification;
