import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import RequirementForm from "../Forms/FormsControl/FormValidition/RequirementForm/BrowserDefault";
import BuyerForm from "../Forms/FormsControl/FormValidition/BuyerForm/BrowserDefault";
import AdminUserForm from "../Forms/FormsControl/FormValidition/AdminUserForm/BrowserDefault";
import AdminTable from "./AdminTable/HoverableRowsClass";

const AdminUserFormPage = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Admin User Form"}
          parent={"Manage"}
          title={"Admin user"}
        />
        {/* <Row></Row> */}
        <AdminUserForm />
        {/* <AdminTable /> */}
      </Container>
    </>
  );
};

export default AdminUserFormPage;
