export const wishlistData =[
    {
        "img":"ecommerce/product-table-6.png",
        "name":"Women Top",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-5.png",
        "name":"Women shorts",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-4.png",
        "name":"Cyclamen",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-3.png",
        "name":"Men Solid Denim Jacket",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-2.png",
        "name":"Blue shirt",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-1.png",
        "name":"Red shirt",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-1.png",
        "name":"Red Shirt",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-6.png",
        "name":"Women Top",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },

    {
        "img":"ecommerce/product-table-5.png",
        "name":"Women shorts",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-4.png",
        "name":"Cyclamen",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-3.png",
        "name":"Men Solid Denim Jacket",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-3.png",
        "name":"Men Solid Denim Jacket",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-2.png",
        "name":"Blue shirt",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-6.png",
        "name":"Women Top",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    },
    {
        "img":"ecommerce/product-table-5.png",
        "name":"Women Short",
        "title":"Price",
        "amount":"210$",
        "stock":"In stock",
        "btn":"Move to Cart"
    }
]