import {
  Id,
  Schedule,
  Security,
  SignalName,
  Stage,
  Status,
  TeamLead,
} from "../../../../../utils/Constant";

const HoverableRowsTableHead = () => {
  return (
    <thead>
      <tr>
        <th scope="col">Sl No</th>
        <th scope="col">Buyer Name</th>
        <th scope="col">Product Name</th>
        <th scope="col">Port of Delivery</th>
        <th scope="col">Packaging Type</th>
        <th scope="col">INCO Terms</th>
      </tr>
    </thead>
  );
};

export default HoverableRowsTableHead;
