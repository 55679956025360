import { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Table, Label, Input, Button } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, sessionExpired } from "../Helper/utils";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const VerificationReport = () => {
  const [verifcationData, setVerificationData] = useState([]);
  const [state, setState] = useState({
    startDate: '',
    endDate: ''
  })

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const userType = localStorage.getItem("type")

  useEffect(() => {
    fetchList();
  }, [])

  const fetchList = () => {
    let jsonData = {};

    if (userType == "CLIENT") {
      jsonData.clientId = localStorage.getItem("id");
    }

    let jsonData1 = JSON.stringify(jsonData);

    let config = {
      method: 'post',
      url: API_BASE_URL + '/api/verificationList',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };

    axios(config)
      .then(function (response) {
        console.log("response.data", response.data);
        setVerificationData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error);
      });
  }

  const handleFilter = () => {

    if (state.startDate === '') {
      alert("Select Start Date")
      document.getElementById("startDate").focus();
      return;
    }

    if (state.endDate === '') {
      alert("Select End Date")
      document.getElementById("endDate").focus();
      return;
    }

    let jsonData = {};

    if (userType == "CLIENT") {
      jsonData.clientId = localStorage.getItem("id");
    }

    jsonData.startDate = state.startDate
    jsonData.endDate = state.endDate

    let jsonData1 = JSON.stringify(jsonData);

    let config = {
      method: 'post',
      url: API_BASE_URL + '/api/verificationList',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };

    axios(config)
      .then(function (response) {
        console.log("response.data", response.data);
        setVerificationData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error);
      });
  }


  const handleNon200Response = (error) => {
    if (error.response.status === 401 && error.response.data.message === "User Not authenticated") {
      sessionExpired();
    }
  }

  const exportToCSV = (fileName) => {
    let jsonData = {}

    if (userType == "CLIENT") {
      jsonData.clientId = localStorage.getItem("id");
    }

    let jsonData1 = JSON.stringify(jsonData);

    let config = {
      method: 'post',
      url: API_BASE_URL + '/api/verificationList',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };


    axios(config)
      .then(function (response) {
        let verifications = []
        if (response.status == 200) {
          for (let i = 0; i < response.data.length; i++) {
            const verify = {
              VerificationId: response.data[i].verificationNumber,
              Name: response.data[i].name,
              Number: response.data[i].number,
              Email: response.data[i].email,
              Education: response.data[i].vEducationStatus,
              Employment: response.data[i].vEmploymentStatus,
              Address: response.data[i].vAddressStatus,
              Criminal: response.data[i].vCriminalStatus,
              Identity: response.data[i].vIdentityStatus,
              Reference: response.data[i].vReferenceStatus,
              Database: response.data[i].vDatabaseStatus,
              SocialMedia: response.data[i].vSocialMediaStatus,
              DrugTest: response.data[i].vDrugTestStatus,
              CreditCheck: response.data[i].vCreditCheckStatus,
              PoliceVerification: response.data[i].vPoliceVerificationStatus,
              Status: response.data[i].status,
              Agent: response.data[i].agentName,
            }
            verifications.push(verify)
          }
        }

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(verifications);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error, "1");
      });
  }

  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Verification Report"}
          parent={"Report"}
          title={"Verification"}
        />
        <Col xl={12}>
          <Card className="height-equal">
            <CardBody className="custom-input">
              <Row className="g-3">
                <Col xs={3}>
                  <Label htmlFor="first-name"> {"Start Date"}</Label>
                  <Input
                    type="date"
                    placeholder="Start Date"
                    value={state.startDate}
                    id="startDate"
                    name="startDate"
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col xs={3}>
                  <Label>{"End Date"}</Label>
                  <Input
                    type="date"
                    placeholder="End Date"
                    value={state.endDate}
                    id="endDate"
                    name="endDate"
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={10}>
                  <Button color="primary" type="submit" id="submit" onClick={handleFilter}>
                    Submit
                  </Button>
                </Col>
                <Col xs={2}>
                  <Button color="primary" type="submit" id="submit"onClick={() => exportToCSV("Verification Report")}>
                    Excel
                  </Button>
                </Col>
              </Row>
              <br />
              <Table responsive className="display order-wrapper" id="recent-order">
                <thead>
                  <tr>
                    <th>Verification Id</th>
                    <th>Name</th>
                    <th>Number</th>
                    <th>Email</th>
                    <th>Education</th>
                    <th>Employemnt</th>
                    <th>Address</th>
                    <th>Criminal</th>
                    <th>Identity</th>
                    <th>Reference</th>
                    <th>Database</th>
                    <th>Social Media</th>
                    <th>Drug Test</th>
                    <th>Credit Check</th>
                    <th>Police Verification</th>
                    <th>Agent</th>
                  </tr>
                </thead>
                <tbody>
                  {verifcationData.map((rowData, index) => (
                    <tr key={index}>
                      <td>{rowData.verificationNumber}</td>
                      <td>{rowData.name}</td>
                      <td>{rowData.number}</td>
                      <td>{rowData.email}</td>
                      <td>{rowData.vEducationStatus == true ? "YES" : "NO"}</td>
                      <td>{rowData.vEmploymentStatus == true ? "YES" : "NO"}</td>
                      <td>{rowData.vAddressStatus == true ? "YES" : "NO"}</td>
                      <td>{rowData.vCriminalStatus == true ? "YES" : "NO"}</td>
                      <td>{rowData.vIdentityStatus == true ? "YES" : "NO"}</td>
                      <td>{rowData.vReferenceStatus == true ? "YES" : "NO"}</td>
                      <td>{rowData.vDatabaseStatus == true ? "YES" : "NO"}</td>
                      <td>{rowData.vSocialMediaStatus == true ? "YES" : "NO"}</td>
                      <td>{rowData.vDrugTestStatus == true ? "YES" : "NO"}</td>
                      <td>{rowData.vCreditCheckStatus == true ? "YES" : "NO"}</td>
                      <td>{rowData.vPoliceVerificationStatus == true ? "YES" : "NO"}</td>
                      <td>{rowData.agentName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default VerificationReport;
