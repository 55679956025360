export const buttons = [
  {
    toolTipText: "",
    color: "primary",
    tittle: "New Requests",
    id: "default1",
    outline: "",
    active: false,
    disabled: false,
    size: "l",
  },
  {
    toolTipText: "",
    color: "info",
    tittle: "In Progress",
    id: "default2",
    outline: "",
    active: true,
    disabled: false,
    size: "l",
  },
  {
    toolTipText: "",
    color: "success",
    tittle: "In Transit",
    id: "default2",
    outline: "",
    active: true,
    disabled: false,
    size: "l",
  },
  {
    toolTipText: "",
    color: "warning",
    tittle: "Delivered",
    id: "default3",
    outline: "",
    active: false,
    disabled: false,
    size: "l",
  },
  {
    toolTipText: "",
    color: "danger",
    tittle: "Rejected",
    id: "default4",
    outline: "",
    active: false,
    disabled: false,
    size: "l",
  },
];
