import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  Address,
  CreditCheck,
  Criminal,
  DrugTest,
  Education,
  Employment,
  Identity,
  PoliceVerification,
  Reference,
  educationColumns,
  employmentColumns,
  addressColumns,
  criminalColumns,
  identityColumns,
  referenceColumns,
  drugTestColumns,
  creditCheckColumns,
  policeColumns,
  VerificationDetails,
  adsumLogoImage,
  stampLogoImage,
} from "../../utils/Constant";

function PdfDownload(props: any) {
  const { selectedFlag, rowData } = props;

  function getValueByKey(object: any, keyName: any) {
    if (keyName === "NA") {
      return "NA";
    } else if (object && keyName in object) {
      if (
        object[keyName].includes(".jpg") ||
        object[keyName].includes(".png") ||
        object[keyName].includes(".jpeg")
      ) {
        return object[keyName];
      } else if (object[keyName].includes(".pdf") || object[keyName] === "") {
        return "NA";
      } else {
        return object[keyName];
      }
    } else {
      return "NA";
    }
  }

  function formatDate(date: any) {
    if (!(date instanceof Date)) {
      return "Invalid Date";
    }

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  function returnMainTableDetails(rowData: any) {
    const data = [
      {
        key: "Date of Initiation",
        value: formatDate(new Date(getValueByKey(rowData, "createdOn"))),
      },
      {
        key: "Date of Completion",
        value:
          getValueByKey(rowData, "NA") !== "NA"
            ? formatDate(new Date(getValueByKey(rowData, "NA")))
            : "NA",
      },
      { key: "Candidate  Name", value: getValueByKey(rowData, "name") },
      { key: "Email", value: getValueByKey(rowData, "email") },
      { key: "Verification Status", value: getValueByKey(rowData, "status") },
      { key: "Verification No.", value: getValueByKey(rowData, "verificationNumber") },
      {
        key: "Client Name/Location",
        value: getValueByKey(rowData, "NA"),
      },
      {
        key: "Final Color Code",
        value: getValueByKey(rowData, "status"),
      },
    ];

    return (
      <View>
        <View style={styles.container} fixed>
          <Text>CANDIDATE DETAILS</Text>
        </View>
        <View style={styles.dataCellValue}>
          <View>
            {data.map(
              (item, index) =>
                index % 2 == 0 && (
                  <View style={rowStyles.row}>
                    <Text
                      style={[rowStyles.cell, { backgroundColor: "lightgray" }]}
                      key={index}
                    >
                      {item.key || "NA"}
                    </Text>
                    <Text style={[rowStyles.cell]} key={index}>
                      {item.value || "NA"}
                    </Text>
                  </View>
                )
            )}
          </View>
          <View>
            {data.map(
              (item, index) =>
                index % 2 !== 0 && (
                  <View style={rowStyles.row}>
                    <Text
                      style={[rowStyles.cell, { backgroundColor: "lightgray" }]}
                      key={index}
                    >
                      {item.key || "NA"}
                    </Text>
                    <Text
                      style={[
                        rowStyles.cell,
                        item.value === "Completed"
                          ? {
                              backgroundColor: "green",
                              color: "white",
                            }
                          : {},
                      ]}
                      key={index}
                    >
                      {item.value || "NA"}
                    </Text>
                  </View>
                )
            )}
          </View>
        </View>
      </View>
    );
  }

  function educationVerificationDetails(detailsData: any) {
    const lastEducationIndex = detailsData?.vEducation?.length - 1;
    let lastEducation = detailsData?.vEducation[lastEducationIndex];

    const data = [
      {
        key: "Course /Degree",
        value: getValueByKey(lastEducation, "courseDegree"),
        verify: detailsData?.vEducationVerified,
      },

      {
        key: "College/Institute/University (Name & Location)",
        value: getValueByKey(lastEducation, "college"),
        verify: detailsData?.vEducationVerified,
      },

      {
        key: "Dates Attended",
        value: getValueByKey(lastEducation, "days"),
        verify: detailsData?.vEducationVerified,
      },

      {
        key: "Roll No",
        value: getValueByKey(lastEducation, "enrollmentNo"),
        verify: detailsData?.vEducationVerified,
      },

      {
        key: "Year of Passing",
        value: getValueByKey(lastEducation, "yearOfPassing"),
        verify: detailsData?.vEducationVerified,
      },

      {
        key: "Verifier (Name & Designation)",
        value: getValueByKey(lastEducation, "verifier"),
      },

      {
        key: "Mode of Verification Email/Letter/Online/Site Visit",
        value: getValueByKey(lastEducation, "modeOfVerification"),
      },
    ];

    let ImageArray: any[] = [];

    Object.values(lastEducation).forEach((x: any) => {
      const match = x?.match(/\d+/);
      if (match !== null) {
        ImageArray.push(`image`);
      }
    });

    return (
      <>
        <View>
          <View style={styles.container} fixed>
            {educationColumns.map((column: any, index) => (
              <Text
                style={[
                  styles.headerCell,
                  {
                    width:
                      column !== VerificationDetails
                        ? "66%"
                        : detailsData?.vEducationVerified
                        ? "33%"
                        : "100%",
                  },
                ]}
                key={index}
              >
                {column !== VerificationDetails
                  ? column
                  : detailsData?.vEducationVerified
                  ? column
                  : ""}
              </Text>
            ))}
          </View>

          {data.map((item, index) => (
            <View style={rowStyles.row} key={`${index}-1`}>
              <Text
                style={[
                  rowStyles.cell,
                  {
                    backgroundColor: "lightgray",
                    maxWidth: 200,
                    minWidth: 200,
                    height: "100%",
                  },
                ]}
                key={index}
              >
                {item.key || "NA"}
              </Text>

              <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                {item.value || "NA"}
              </Text>

              {item.verify && (
                <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                  {item.value || "NA"}
                </Text>
              )}
            </View>
          ))}
        </View>

        {ImageArray?.map(
          (x, i) =>
            getValueByKey(lastEducation, `${x + (i + 1)}`) != "NA" && (
              <>
                <View break style={rowStyles.imageContainer}>
                  <View>
                    <Image
                      style={styles.headerImage}
                      src={adsumLogoImage}
                      fixed
                    />
                  </View>

                  <Image
                    style={rowStyles.pageImage}
                    src={getValueByKey(lastEducation, `${x + (i + 1)}`)}
                  />
                </View>
              </>
            )
        )}
      </>
    );
  }

  function employmentVerificationDetails(detailsData: any) {
    const lastEmploymentIndex = detailsData?.vEmployment?.length - 1;
    const lastEmployment = detailsData?.vEmployment[lastEmploymentIndex];

    const data = [
      {
        key: "Company Name",
        value: getValueByKey(lastEmployment, "companyName"),
        verify: detailsData?.vEmploymentVerified,
      },

      {
        key: "Contact No",
        value: getValueByKey(lastEmployment, "contactNo"),
      },

      {
        key: "Designation",
        value: getValueByKey(lastEmployment, "designation"),
        verify: detailsData?.vEmploymentVerified,
      },

      {
        key: "Employee Code",
        value: getValueByKey(lastEmployment, "employeeCode"),
        verify: detailsData?.vEmploymentVerified,
      },

      {
        key: "Mode Of Verification",
        value: getValueByKey(lastEmployment, "modeOfVerification"),
      },

      {
        key: "Performance At Work",
        value: getValueByKey(lastEmployment, "performanceAtWork"),
      },

      {
        key: "Remarks",
        value: getValueByKey(lastEmployment, "remarks"),
      },

      {
        key: "Salary",
        value: getValueByKey(lastEmployment, "salary"),
        verify: detailsData?.vEmploymentVerified,
      },

      {
        key: "Tenure",
        value: getValueByKey(lastEmployment, "tenure"),
      },

      {
        key: "Verifier",
        value: getValueByKey(lastEmployment, "verifier"),
      },
    ];

    let ImageArray: any[] = [];

    Object.values(lastEmployment).forEach((x: any) => {
      const match = x?.match(/\d+/);
      if (match !== null) {
        ImageArray.push(`image`);
      }
    });

    return (
      <>
        <View>
          <View style={styles.container} fixed>
            {employmentColumns.map((column: any, index) => (
              <Text
                style={[
                  styles.headerCell,
                  {
                    width:
                      column !== VerificationDetails
                        ? "66%"
                        : detailsData?.vEmploymentVerified
                        ? "33%"
                        : "100%",
                  },
                ]}
                key={index}
              >
                {column !== VerificationDetails
                  ? column
                  : detailsData?.vEmploymentVerified
                  ? column
                  : ""}
              </Text>
            ))}
          </View>

          {data.map((item, index) => (
            <View style={rowStyles.row} key={`${index}-1`}>
              <Text
                style={[
                  rowStyles.cell,
                  {
                    backgroundColor: "lightgray",
                    maxWidth: 200,
                    minWidth: 200,
                    height: "100%",
                  },
                ]}
                key={index}
              >
                {item.key || "NA"}
              </Text>

              <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                {item.value || "NA"}
              </Text>

              {item.verify && (
                <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                  {item.value || "NA"}
                </Text>
              )}
            </View>
          ))}
        </View>

        {ImageArray?.map(
          (x, i) =>
            getValueByKey(lastEmployment, `${x + (i + 1)}`) != "NA" && (
              <>
                <View break style={rowStyles.imageContainer}>
                  <View>
                    <Image
                      style={styles.headerImage}
                      src={adsumLogoImage}
                      fixed
                    />
                  </View>

                  <Image
                    style={rowStyles.pageImage}
                    src={getValueByKey(lastEmployment, `${x + (i + 1)}`)}
                  />
                </View>
              </>
            )
        )}
      </>
    );
  }

  function addressVerificationDetails(detailsData: any) {
    const lastAddressIndex = detailsData?.vAddress?.length - 1;
    const lastAddress = detailsData?.vAddress[lastAddressIndex];

    const data = [
      {
        key: "Confirmation Of Address",
        value: getValueByKey(lastAddress, "confirmationOfAddress"),
        verify: detailsData?.vAddressVerified,
      },

      {
        key: "Contact No",
        value: getValueByKey(lastAddress, "contactNumber"),
      },

      {
        key: "Date Of Birth",
        value: formatDate(new Date(getValueByKey(lastAddress, "dob"))),
        verify: detailsData?.vAddressVerified,
      },

      {
        key: "Father Name",
        value: getValueByKey(lastAddress, "fatherName"),
        verify: detailsData?.vAddressVerified,
      },

      {
        key: "Name",
        value: getValueByKey(lastAddress, "name"),
      },

      {
        key: "Period Of Stay",
        value: getValueByKey(lastAddress, "periodOfStay"),
      },

      {
        key: "Remarks",
        value: getValueByKey(lastAddress, "remarks"),
      },

      {
        key: "Respondent Name",
        value: getValueByKey(lastAddress, "respondentName"),
        verify: detailsData?.vAddressVerified,
      },

      {
        key: "Type Of Address",
        value: getValueByKey(lastAddress, "typeOfAddress"),
        verify: detailsData?.vAddressVerified,
      },

      {
        key: "Type Of Property",
        value: getValueByKey(lastAddress, "typeOfProperty"),
      },
    ];

    let ImageArray: any[] = [];

    Object.values(lastAddress).forEach((x: any) => {
      const match = x?.match(/\d+/);
      if (match !== null) {
        ImageArray.push(`image`);
      }
    });

    return (
      <>
        <View>
          <View style={styles.container} fixed>
            {addressColumns.map((column: any, index) => (
              <Text
                style={[
                  styles.headerCell,
                  {
                    width:
                      column !== VerificationDetails
                        ? "66%"
                        : detailsData?.vAddressVerified
                        ? "33%"
                        : "100%",
                  },
                ]}
                key={index}
              >
                {column !== VerificationDetails
                  ? column
                  : detailsData?.vAddressVerified
                  ? column
                  : ""}
              </Text>
            ))}
          </View>

          {data.map((item, index) => (
            <View style={rowStyles.row} key={`${index}-1`}>
              <Text
                style={[
                  rowStyles.cell,
                  {
                    backgroundColor: "lightgray",
                    maxWidth: 200,
                    minWidth: 200,
                    height: "100%",
                  },
                ]}
                key={index}
              >
                {item.key || "NA"}
              </Text>

              <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                {item.value || "NA"}
              </Text>

              {item.verify && (
                <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                  {item.value || "NA"}
                </Text>
              )}
            </View>
          ))}
        </View>

        {ImageArray?.map(
          (x, i) =>
            getValueByKey(lastAddress, `${x + (i + 1)}`) != "NA" && (
              <>
                <View break style={rowStyles.imageContainer}>
                  <View>
                    <Image
                      style={styles.headerImage}
                      src={adsumLogoImage}
                      fixed
                    />
                  </View>

                  <Image
                    style={rowStyles.pageImage}
                    src={getValueByKey(lastAddress, `${x + (i + 1)}`)}
                  />
                </View>
              </>
            )
        )}
      </>
    );
  }

  function criminalVerificationDetails(detailsData: any) {
    const lastCriminalIndex = detailsData?.vCriminal?.length - 1;
    const lastCriminal = detailsData?.vCriminal[lastCriminalIndex];

    const data = [
      {
        key: "Candidate Name",
        value: getValueByKey(lastCriminal, "candidateName"),
        verify: detailsData?.vCriminalVerified,
      },

      {
        key: "Date Of Birth",
        value: formatDate(new Date(getValueByKey(lastCriminal, "dob"))),
      },

      {
        key: "Father Name",
        value: getValueByKey(lastCriminal, "fatherName"),
        verify: detailsData?.vCriminalVerified,
      },

      {
        key: "High Court",
        value: getValueByKey(lastCriminal, "highCourt"),
        verify: detailsData?.vCriminalVerified,
      },

      {
        key: "Magistrate",
        value: getValueByKey(lastCriminal, "magistrate"),
      },

      {
        key: "Sessions",
        value: getValueByKey(lastCriminal, "sessions"),
        verify: detailsData?.vCriminalVerified,
      },
    ];

    let ImageArray: any[] = [];

    Object.values(lastCriminal).forEach((x: any) => {
      const match = x?.match(/\d+/);
      if (match !== null) {
        ImageArray.push(`image`);
      }
    });

    return (
      <>
        <View>
          <View style={styles.container} fixed>
            {criminalColumns.map((column: any, index) => (
              <Text
                style={[
                  styles.headerCell,
                  {
                    width:
                      column !== VerificationDetails
                        ? "66%"
                        : detailsData?.vCriminalVerified
                        ? "33%"
                        : "100%",
                  },
                ]}
                key={index}
              >
                {column !== VerificationDetails
                  ? column
                  : detailsData?.vCriminalVerified
                  ? column
                  : ""}
              </Text>
            ))}
          </View>

          {data.map((item, index) => (
            <View style={rowStyles.row} key={`${index}-1`}>
              <Text
                style={[
                  rowStyles.cell,
                  {
                    backgroundColor: "lightgray",
                    maxWidth: 200,
                    minWidth: 200,
                    height: "100%",
                  },
                ]}
                key={index}
              >
                {item.key || "NA"}
              </Text>

              <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                {item.value || "NA"}
              </Text>

              {item.verify && (
                <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                  {item.value || "NA"}
                </Text>
              )}
            </View>
          ))}
        </View>

        {ImageArray?.map(
          (x, i) =>
            getValueByKey(lastCriminal, `${x + (i + 1)}`) != "NA" && (
              <>
                <View break style={rowStyles.imageContainer}>
                  <View>
                    <Image
                      style={styles.headerImage}
                      src={adsumLogoImage}
                      fixed
                    />
                  </View>

                  <Image
                    style={rowStyles.pageImage}
                    src={getValueByKey(lastCriminal, `${x + (i + 1)}`)}
                  />
                </View>
              </>
            )
        )}
      </>
    );
  }

  function identityVerificationDetails(detailsData: any) {
    const lastIdentityIndex = detailsData?.vIdentity?.length - 1;
    const lastIdentity = detailsData?.vIdentity[lastIdentityIndex];

    const data = [
      {
        key: "Date Of Birth",
        value: formatDate(new Date(getValueByKey(lastIdentity, "dob"))),
        verify: detailsData?.vIdentityVerified,
      },

      {
        key: "Father Name",
        value: getValueByKey(lastIdentity, "fatherName"),
      },

      {
        key: "Full Name",
        value: getValueByKey(lastIdentity, "fullName"),
      },

      {
        key: "pancardNo",
        value: getValueByKey(lastIdentity, "pancardNo"),
        verify: detailsData?.vIdentityVerified,
      },
    ];

    let ImageArray: any[] = [];

    Object.values(lastIdentity).forEach((x: any) => {
      const match = x?.match(/\d+/);
      if (match !== null) {
        ImageArray.push(`image`);
      }
    });

    return (
      <>
        <View>
          <View style={styles.container} fixed>
            {identityColumns.map((column: any, index) => (
              <Text
                style={[
                  styles.headerCell,
                  {
                    width:
                      column !== VerificationDetails
                        ? "66%"
                        : detailsData?.vIdentityVerified
                        ? "33%"
                        : "100%",
                  },
                ]}
                key={index}
              >
                {column !== VerificationDetails
                  ? column
                  : detailsData?.vIdentityVerified
                  ? column
                  : ""}
              </Text>
            ))}
          </View>

          {data.map((item, index) => (
            <View style={rowStyles.row} key={`${index}-1`}>
              <Text
                style={[
                  rowStyles.cell,
                  {
                    backgroundColor: "lightgray",
                    maxWidth: 200,
                    minWidth: 200,
                    height: "100%",
                  },
                ]}
                key={index}
              >
                {item.key || "NA"}
              </Text>

              <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                {item.value || "NA"}
              </Text>

              {item.verify && (
                <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                  {item.value || "NA"}
                </Text>
              )}
            </View>
          ))}
        </View>

        {ImageArray?.map(
          (x, i) =>
            getValueByKey(lastIdentity, `${x + (i + 1)}`) != "NA" && (
              <>
                <View break style={rowStyles.imageContainer}>
                  <View>
                    <Image
                      style={styles.headerImage}
                      src={adsumLogoImage}
                      fixed
                    />
                  </View>

                  <Image
                    style={rowStyles.pageImage}
                    src={getValueByKey(lastIdentity, `${x + (i + 1)}`)}
                  />
                </View>
              </>
            )
        )}
      </>
    );
  }

  function referenceVerificationDetails(detailsData: any) {
    const lastReferenceIndex = detailsData?.vReference?.length - 1;
    const lastReference = detailsData?.vReference[lastReferenceIndex];

    const data = [
      {
        key: "Designation",
        value: getValueByKey(lastReference, "designation"),
        verify: detailsData?.vReferenceVerified,
      },

      {
        key: "Organisation",
        value: getValueByKey(lastReference, "organisation"),
        verify: detailsData?.vReferenceVerified,
      },

      {
        key: "Respondent Name",
        value: getValueByKey(lastReference, "respondentName"),
      },
    ];

    let ImageArray: any[] = [];

    Object.values(lastReference).forEach((x: any) => {
      const match = x?.match(/\d+/);
      if (match !== null) {
        ImageArray.push(`image`);
      }
    });

    return (
      <>
        <View>
          <View style={styles.container} fixed>
            {referenceColumns.map((column: any, index) => (
              <Text
                style={[
                  styles.headerCell,
                  {
                    width:
                      column !== VerificationDetails
                        ? "66%"
                        : detailsData?.vReferenceVerified
                        ? "33%"
                        : "100%",
                  },
                ]}
                key={index}
              >
                {column !== VerificationDetails
                  ? column
                  : detailsData?.vReferenceVerified
                  ? column
                  : ""}
              </Text>
            ))}
          </View>

          {data.map((item, index) => (
            <View style={rowStyles.row} key={`${index}-1`}>
              <Text
                style={[
                  rowStyles.cell,
                  {
                    backgroundColor: "lightgray",
                    maxWidth: 200,
                    minWidth: 200,
                    height: "100%",
                  },
                ]}
                key={index}
              >
                {item.key || "NA"}
              </Text>

              <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                {item.value || "NA"}
              </Text>

              {item.verify && (
                <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                  {item.value || "NA"}
                </Text>
              )}
            </View>
          ))}
        </View>

        {ImageArray?.map(
          (x, i) =>
            getValueByKey(lastReference, `${x + (i + 1)}`) != "NA" && (
              <>
                <View break style={rowStyles.imageContainer}>
                  <View>
                    <Image
                      style={styles.headerImage}
                      src={adsumLogoImage}
                      fixed
                    />
                  </View>

                  <Image
                    style={rowStyles.pageImage}
                    src={getValueByKey(lastReference, `${x + (i + 1)}`)}
                  />
                </View>
              </>
            )
        )}
      </>
    );
  }

  function drugTestVerificationDetails(detailsData: any) {
    const lastDrugTestIndex = detailsData?.vDrugTest?.length - 1;
    const lastDrugTest = detailsData?.vDrugTest[lastDrugTestIndex];

    const data = [
      {
        key: "Blood Samples Collected",
        value: getValueByKey(lastDrugTest, "bloodSamplesCollected"),
        verify: detailsData?.vDrugTestVerified,
      },

      {
        key: "Collection Location",
        value: getValueByKey(lastDrugTest, "collectionLocation"),
      },

      {
        key: "Date Of Birth",
        value: getValueByKey(lastDrugTest, "dob"),
        verify: detailsData?.vDrugTestVerified,
      },

      {
        key: "Test Result",
        value: getValueByKey(lastDrugTest, "testResult"),
        verify: detailsData?.vDrugTestVerified,
      },

      {
        key: "Name",
        value: getValueByKey(lastDrugTest, "name"),
      },
    ];

    let ImageArray: any[] = [];

    Object.values(lastDrugTest).forEach((x: any) => {
      const match = x?.match(/\d+/);
      if (match !== null) {
        ImageArray.push(`image`);
      }
    });

    return (
      <>
        <View>
          <View style={styles.container} fixed>
            {drugTestColumns.map((column: any, index) => (
              <Text
                style={[
                  styles.headerCell,
                  {
                    width:
                      column !== VerificationDetails
                        ? "66%"
                        : detailsData?.vDrugTestVerified
                        ? "33%"
                        : "100%",
                  },
                ]}
                key={index}
              >
                {column !== VerificationDetails
                  ? column
                  : detailsData?.vDrugTestVerified
                  ? column
                  : ""}
              </Text>
            ))}
          </View>

          {data.map((item, index) => (
            <View style={rowStyles.row} key={`${index}-1`}>
              <Text
                style={[
                  rowStyles.cell,
                  {
                    backgroundColor: "lightgray",
                    maxWidth: 200,
                    minWidth: 200,
                    height: "100%",
                  },
                ]}
                key={index}
              >
                {item.key || "NA"}
              </Text>

              <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                {item.value || "NA"}
              </Text>

              {item.verify && (
                <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                  {item.value || "NA"}
                </Text>
              )}
            </View>
          ))}
        </View>

        {ImageArray?.map(
          (x, i) =>
            getValueByKey(lastDrugTest, `${x + (i + 1)}`) != "NA" && (
              <>
                <View break style={rowStyles.imageContainer}>
                  <View>
                    <Image
                      style={styles.headerImage}
                      src={adsumLogoImage}
                      fixed
                    />
                  </View>

                  <Image
                    style={rowStyles.pageImage}
                    src={getValueByKey(lastDrugTest, `${x + (i + 1)}`)}
                  />
                </View>
              </>
            )
        )}
      </>
    );
  }

  function creditCheckVerificationDetails(detailsData: any) {
    const lastCreditCheckIndex = detailsData?.vCreditCheck?.length - 1;
    const lastCreditCheck = detailsData?.vCreditCheck[lastCreditCheckIndex];

    const data = [
      {
        key: "Address",
        value: getValueByKey(lastCreditCheck, "address"),
        verify: detailsData?.vCreditCheckVerified,
      },

      {
        key: "Mobile Number",
        value: getValueByKey(lastCreditCheck, "mobileNumber"),
        verify: detailsData?.vCreditCheckVerified,
      },

      {
        key: "Date Of Birth",
        value: getValueByKey(lastCreditCheck, "dob"),
      },

      {
        key: "Pancard No",
        value: getValueByKey(lastCreditCheck, "pancardNo"),
        verify: detailsData?.vCreditCheckVerified,
      },

      {
        key: "Name",
        value: getValueByKey(lastCreditCheck, "name"),
      },
    ];

    let ImageArray: any[] = [];

    Object.values(lastCreditCheck).forEach((x: any) => {
      const match = x?.match(/\d+/);
      if (match !== null) {
        ImageArray.push(`image`);
      }
    });

    return (
      <>
        <View>
          <View style={styles.container} fixed>
            {creditCheckColumns.map((column: any, index) => (
              <Text
                style={[
                  styles.headerCell,
                  {
                    width:
                      column !== VerificationDetails
                        ? "66%"
                        : detailsData?.vCreditCheckVerified
                        ? "33%"
                        : "100%",
                  },
                ]}
                key={index}
              >
                {column !== VerificationDetails
                  ? column
                  : detailsData?.vCreditCheckVerified
                  ? column
                  : ""}
              </Text>
            ))}
          </View>

          {data.map((item, index) => (
            <View style={rowStyles.row} key={`${index}-1`}>
              <Text
                style={[
                  rowStyles.cell,
                  {
                    backgroundColor: "lightgray",
                    maxWidth: 200,
                    minWidth: 200,
                    height: "100%",
                  },
                ]}
                key={index}
              >
                {item.key || "NA"}
              </Text>

              <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                {item.value || "NA"}
              </Text>

              {item.verify && (
                <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                  {item.value || "NA"}
                </Text>
              )}
            </View>
          ))}
        </View>

        {ImageArray?.map(
          (x, i) =>
            getValueByKey(lastCreditCheck, `${x + (i + 1)}`) != "NA" && (
              <>
                <View break style={rowStyles.imageContainer}>
                  <View>
                    <Image
                      style={styles.headerImage}
                      src={adsumLogoImage}
                      fixed
                    />
                  </View>

                  <Image
                    style={rowStyles.pageImage}
                    src={getValueByKey(lastCreditCheck, `${x + (i + 1)}`)}
                  />
                </View>
              </>
            )
        )}
      </>
    );
  }

  function policeVerificationDetails(detailsData: any) {
    const lastPoliceVerificationIndex =
      detailsData?.vPoliceVerification?.length - 1;
    const lastPoliceVerification =
      detailsData?.vPoliceVerification[lastPoliceVerificationIndex];

    const data = [
      {
        key: "Address",
        value: getValueByKey(lastPoliceVerification, "address"),
        verify: detailsData?.vPoliceVerificationVerified,
      },

      {
        key: "Civil",
        value: getValueByKey(lastPoliceVerification, "civil"),
        verify: detailsData?.vPoliceVerificationVerified,
      },

      {
        key: "Date Of Birth",
        value: getValueByKey(lastPoliceVerification, "dob"),
        verify: detailsData?.vPoliceVerificationVerified,
      },

      {
        key: "Father Name",
        value: getValueByKey(lastPoliceVerification, "fatherName"),
        verify: detailsData?.vPoliceVerificationVerified,
      },

      {
        key: "HighCourt",
        value: getValueByKey(lastPoliceVerification, "highCourt"),
        verify: detailsData?.vPoliceVerificationVerified,
      },

      {
        key: "Magistrate",
        value: getValueByKey(lastPoliceVerification, "magistrate"),
      },

      {
        key: "Name",
        value: getValueByKey(lastPoliceVerification, "name"),
        verify: detailsData?.vPoliceVerificationVerified,
      },

      {
        key: "Sessions",
        value: getValueByKey(lastPoliceVerification, "sessions"),
      },
    ];

    let ImageArray: any[] = [];

    Object.values(lastPoliceVerification).forEach((x: any) => {
      const match = x?.match(/\d+/);
      if (match !== null) {
        ImageArray.push(`image`);
      }
    });

    return (
      <>
        <View>
          <View style={styles.container} fixed>
            {policeColumns.map((column: any, index) => (
              <Text
                style={[
                  styles.headerCell,
                  {
                    width:
                      column !== VerificationDetails
                        ? "66%"
                        : detailsData?.vPoliceVerificationVerified
                        ? "33%"
                        : "100%",
                  },
                ]}
                key={index}
              >
                {column !== VerificationDetails
                  ? column
                  : detailsData?.vPoliceVerificationVerified
                  ? column
                  : ""}
              </Text>
            ))}
          </View>

          {data.map((item, index) => (
            <View style={rowStyles.row} key={`${index}-1`}>
              <Text
                style={[
                  rowStyles.cell,
                  {
                    backgroundColor: "lightgray",
                    maxWidth: 200,
                    minWidth: 200,
                    height: "100%",
                  },
                ]}
                key={index}
              >
                {item.key || "NA"}
              </Text>

              <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                {item.value || "NA"}
              </Text>

              {item.verify && (
                <Text style={[rowStyles.cell, { height: "100%" }]} key={index}>
                  {item.value || "NA"}
                </Text>
              )}
            </View>
          ))}
        </View>

        {ImageArray?.map(
          (x, i) =>
            getValueByKey(lastPoliceVerification, `${x + (i + 1)}`) != "NA" && (
              <>
                <View break style={rowStyles.imageContainer}>
                  <View>
                    <Image
                      style={styles.headerImage}
                      src={adsumLogoImage}
                      fixed
                    />
                  </View>

                  <Image
                    style={rowStyles.pageImage}
                    src={getValueByKey(
                      lastPoliceVerification,
                      `${x + (i + 1)}`
                    )}
                  />
                </View>
              </>
            )
        )}
      </>
    );
  }

  return (
    <div>
      <PDFDownloadLink
        document={
          <>
            <Document>
              <Page style={styles.body}>
                <View>
                  <Image
                    style={styles.headerImage}
                    src={adsumLogoImage}
                    fixed
                  />
                </View>

                <View style={styles.mainHeading}>
                  <Text>FINAL BACKGROUND REPORT</Text>
                </View>
                {returnMainTableDetails(rowData)}

                {selectedFlag === Education
                  ? educationVerificationDetails(rowData)
                  : selectedFlag === Employment
                  ? employmentVerificationDetails(rowData)
                  : selectedFlag === Address
                  ? addressVerificationDetails(rowData)
                  : selectedFlag === Criminal
                  ? criminalVerificationDetails(rowData)
                  : selectedFlag === Identity
                  ? identityVerificationDetails(rowData)
                  : selectedFlag === Reference
                  ? referenceVerificationDetails(rowData)
                  : selectedFlag === DrugTest
                  ? drugTestVerificationDetails(rowData)
                  : selectedFlag === CreditCheck
                  ? creditCheckVerificationDetails(rowData)
                  : selectedFlag === PoliceVerification
                  ? policeVerificationDetails(rowData)
                  : ""}

                <View>
                  <Image style={styles.footerStampImage} src={stampLogoImage} />
                </View>
              </Page>
            </Document>
          </>
        }
        fileName={getValueByKey(rowData, "verificationNumber")+'-'+selectedFlag}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            <FontAwesomeIcon
              className="pdf-download-icon"
              icon={faDownload}
              style={{ color: "gray" }}
            />
          ) : (
            <>
              <FontAwesomeIcon
                className="pdf-download-icon"
                icon={faDownload}
                id="TooltipExample"
              />
            </>
          )
        }
      </PDFDownloadLink>
    </div>
  );
}

export default PdfDownload;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderColor: "#5f5f5f",
    border: 1,
    backgroundColor: "#afc5df",
    width: "100%",
    minHeight: "30px",
    margin: "15px auto 0px auto",
    fontStyle: "bold",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  headerImage: {
    height: "60px",
    width: "150px",
    margin: "18px 0 0 0",
  },

  footerStampImage: {
    minHeight: "auto",
    width: "100px",
    margin: "18px",
  },

  headerCell: {
    width: "100%",
    textAlign: "center",
    margin: "0px auto 0px auto",
    fontSize: "12px",
    alignItems: "center",
    fontWeight: "bold",
  },

  mainHeading: {
    flexDirection: "row",
    backgroundColor: "#afc5df",
    width: "100%",
    minHeight: "35px",
    margin: "15px auto",
    borderColor: "#5f5f5f",
    border: 1,
    fontStyle: "bold",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  dataCellValue: {
    display: "flex",
    flexDirection: "row",
    margin: "0",
    padding: "0",
  },

  body: {
    paddingBottom: 30,
    paddingHorizontal: 15,
  },
});

const rowStyles = StyleSheet.create({
  row: {
    alignItems: "center",
    fontStyle: "bold",
    fontSize: "8px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: "0",
  },

  cell: {
    width: "100%",
    padding: "10px",
    margin: "auto",
    fontSize: "12px",
    borderColor: "#5f5f5f",
    border: 1,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  imageContainer: {},

  pageImage: {
    width: "100%",
    height: "87vh",
    padding: "30px 18px 18px 18px",
  },
});
