import { Card, CardBody, Col } from "reactstrap";
import { BrowserDefaultsHeading } from "../../../../../utils/Constant";
import BrowserDefaultForm from "./BrowserDefaultForm";
import CommonHeader from "../../../../../Common/CommonHeader";
import { useState } from "react";

const DropdownAddForm = () => {
  // Define a single state object to store all input values
  const [formData, setFormData] = useState({
    name: "",
    status: "active",
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Access the form data from the state
    console.log(formData);
    // Perform other actions, such as sending data to a server
  };

  return (
    <Col xl={12}>
      <Card className="height-equal">
        {/* <CommonHeader
          title={"BrowserDefaultsHeading"}
          subTitle={subTitle}
          headClass="pb-0"
        /> */}
        <CardBody className="custom-input">
          <BrowserDefaultForm
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default DropdownAddForm;
