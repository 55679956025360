export const allNotificationData=[
    {
        img:"06",
        comment:"You have new comments in real finical law agency landing page design.",
        status:"2 min ago",
        icon:true
    },
    {
        img:"07",
        comment:"Congrats! you complete all task for today. just need to join meting.",
        status:"30 hours ago",
        icon:false
    },
    {
        img:"08",
        comment:"Jose wheeler assassin a work for you",
        status:"Today 11:45pm",
        icon:true,
        btn:true
    },
    {
        img:"09",
        comment:"Congrats! you complete all task for today. just need to join meting.",
        status:"Mon 11:45pm",
        icon:false
    },
]