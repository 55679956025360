import ShoppingPlace from "../Pages/DashBoard/ShoppingPlace/ShoppingPlace";
import CRMDashboard from "../Pages/DashBoard/CRMDashboard/CRMDashboard";
import General from "../Pages/Widgets/General/General";
import Typography from "../Pages/Ui-Kits/Typography/Typography";
import Avatars from "../Pages/Ui-Kits/Avatars/Avatars";
import HelperClasses from "../Pages/Ui-Kits/HelperClasses/HelperClasses";
import Grid from "../Pages/Ui-Kits/Grid/Grid";
import TagPills from "../Pages/Ui-Kits/TagPills/TagPills";
import Chart from "../Pages/Widgets/Chart/Chart";
import Scrollable from "../Pages/BonusUi/Scrollable/Scrollable";
import TreeView from "../Pages/BonusUi/TreeView/TreeView";
import Toasts from "../Pages/BonusUi/Toasts/Toasts";
import Rating from "../Pages/BonusUi/Rating/Rating";
import Dropzone from "../Pages/BonusUi/Dropzone/Dropzone";
import Tour from "../Pages/BonusUi/Tour/Tour";
import SweetAlert from "../Pages/BonusUi/SweetAlert/SweetAlert";
import Ribbons from "../Pages/BonusUi/Ribbons/Ribbons";
import Paginations from "../Pages/BonusUi/Paginations/Paginations";
import Breadcrumb from "../Pages/BonusUi/Breadcrumb/Breadcrumb";
import RangeSlider from "../Pages/BonusUi/RangeSlider/RangeSlider";
import ImageCropper from "../Pages/BonusUi/ImageCropper/ImageCropper";
import BasicCard from "../Pages/BonusUi/BasicCard/BasicCard";
import CreativeCard from "../Pages/BonusUi/CreativeCard/CreativeCard";
import TimeLine from "../Pages/BonusUi/TimeLine/TimeLine";
import OwlCarousel from "../Pages/BonusUi/OwlCarousel/OwlCarousel";
import Progressbar from "../Pages/Ui-Kits/Progressbar/Progressbar";
import Alert from "../Pages/Ui-Kits/Alert/Alert";
import Modal from "../Pages/Ui-Kits/Modal/Modal";
import Popover from "../Pages/Ui-Kits/Popover/Popover";
import Tooltip from "../Pages/Ui-Kits/Tooltip/Tooltip";
import Dropdown from "../Pages/Ui-Kits/Dropdown/Dropdown";
import Accordion from "../Pages/Ui-Kits/Accordion/Accordion";
import Tabs from "../Pages/Ui-Kits/Tabs/Tabs";
import SamplePage from "../Pages/MISCELLANEOUS/SamplePage/SamplePage";
import GalleryGrid from "../Pages/MISCELLANEOUS/Gallery/GalleryGrid/GalleryGrid";
import GalleryGridWithDescription from "../Pages/MISCELLANEOUS/Gallery/GalleryGridWithDescription/GalleryGridWithDescription";
import MasonryGallery from "../Pages/MISCELLANEOUS/Gallery/MasonryGallery/MasonryGallery";
import MasonryGalleryWithDescription from "../Pages/MISCELLANEOUS/Gallery/MasonryGalleryWithDescription/MasonryGalleryWithDescription";
import ImageHoverEffects from "../Pages/MISCELLANEOUS/Gallery/ImageHoverEffects/ImageHoverEffects";
import BlogDetails from "../Pages/MISCELLANEOUS/Blog/BlogDetail/BlogDetails";
import BlogSingle from "../Pages/MISCELLANEOUS/Blog/BlogSingle/BlogSingle";
import AddPost from "../Pages/MISCELLANEOUS/Blog/BlogPost/AddPost";
import FAQ from "../Pages/MISCELLANEOUS/FAQ/FAQ";
import Knowledgebase from "../Pages/MISCELLANEOUS/Knowledgebase/Knowledgebase";
import SupportTicket from "../Pages/MISCELLANEOUS/SupportTicket/SupportTicket";
import CardView from "../Pages/MISCELLANEOUS/JobSearch/CardView/CardView";
import ListView from "../Pages/MISCELLANEOUS/JobSearch/ListView/ListView";
import JobDetails from "../Pages/MISCELLANEOUS/JobSearch/JobDetails/JobDetails";
import JobApply from "../Pages/MISCELLANEOUS/JobSearch/JobApply/JobApply";
import LearningList from "../Pages/MISCELLANEOUS/Learning/LearningList/LearningList";
import DetailedCourse from "../Pages/MISCELLANEOUS/Learning/DetailedCourse/DetailedCourse";
import GoogleMap from "../Pages/MISCELLANEOUS/Maps/GoogleMap/GoogleMap";
import LeafletMaps from "../Pages/MISCELLANEOUS/Maps/LeafletMaps/LeafletMaps";
import CkEditor from "../Pages/MISCELLANEOUS/Editors/CkEditor/CkEditor";
import MDEEditor from "../Pages/MISCELLANEOUS/Editors/MDEEditor/MDEEditor";
import AceEditor from "../Pages/MISCELLANEOUS/Editors/AceEditor/AceEditor";
import ProductPage from "../Pages/Apps/Ecommerce/ProductPage/ProductPage";
import ProductList from "../Pages/Apps/Ecommerce/ProductList/ProductList";
import PaymentDetails from "../Pages/Apps/Ecommerce/PaymentDetails/PaymentDetails";
import Product from "../Pages/Apps/Ecommerce/Product/Product";
import Cart from "../Pages/Apps/Ecommerce/Cart/Cart";
import Checkout from "../Pages/Apps/Ecommerce/Checkout/Checkout";
import Invoice from "../Pages/Apps/Ecommerce/Invoice/Invoice";
import OrderHistory from "../Pages/Apps/Ecommerce/OrderHistory/OrderHistory";
import Wishlist from "../Pages/Apps/Ecommerce/Wishlist/Wishlist";
import ProductPricing from "../Pages/Apps/Ecommerce/Pricing/ProductPricing";
import ApexChart from "../Pages/Charts/ApexChart/ApexChart";
import GoogleChart from "../Pages/Charts/GoogleChart/GoogleChart";
import ChartJS from "../Pages/Charts/ChartJS/ChartJS";
import ProjectList from "../Pages/Apps/Project/ProjectList/ProjectList";
import ProjectCreate from "../Pages/Apps/Project/ProjectCreate/ProjectCreate";
import FileManager from "../Pages/Apps/FlieManager/FlieManager";
import SearchResult from "../Pages/Apps/SearchResult/SearchResult";
import Todo from "../Pages/Apps/Todo/Todo";
import SocialApp from "../Pages/Apps/SocialApp/SocialApp";
import Calender from "../Pages/Apps/Calender/Calender";
import Tasks from "../Pages/Apps/Tasks/Tasks";
import UserProfile from "../Pages/Apps/User/UserProfile/UserProfile";
import UserEdit from "../Pages/Apps/User/UserEdit/UserEdit";
import UserCard from "../Pages/Apps/User/UserCard/UserCard";
import EmailApp from "../Pages/Apps/Email/EmailApp/EmailApp";
import EmailCompose from "../Pages/Apps/Email/EmailCompose/EmailCompose";
import VideoChat from "../Pages/Apps/Chat/VideoChat/VideoChat";
import Chating from "../Pages/Apps/Chat/Chating/Chating";
import Bookmark from "../Pages/Apps/Bookmark/Bookmark";
import Contact from "../Pages/Apps/Contact/Contact";
import BasicTables from "../Pages/Tables/ReactStrapTables/BasicTables/BasicTables";
import TableComponent from "../Pages/Tables/ReactStrapTables/TableComponent/TableComponent";
import BasicDataTables from "../Pages/Tables/DataTables/BasicInt/BasicDataTables";
import AdvanceInit from "../Pages/Tables/DataTables/AdvanceInit/AdvanceInit";
import APIDataTables from "../Pages/Tables/DataTables/APIDataTables/APIDataTables";
import DataSources from "../Pages/Tables/DataTables/DataSources/DataSources";
import FormWizard from "../Pages/Forms/FormsLayout/FormWizard/FormWizard";
import TwoFactor from "../Pages/Forms/FormsLayout/TwoFactor/TwoFactor";
import FormWizardTwo from "../Pages/Forms/FormsLayout/FormWizard2/FormWizardTwo";
import FormValidation from "../Pages/Forms/FormsControl/FormValidition/FormValidation";
import BaseInput from "../Pages/Forms/FormsControl/BaseInput/BaseInput";
import CheckboxRadio from "../Pages/Forms/FormsControl/Checkbox&Radio/Checkbox&Radio";
import InputGroups from "../Pages/Forms/FormsControl/InputGroups/InputGroups";
import InputMask from "../Pages/Forms/FormsControl/InputMask/InputMask";
import MegaOptions from "../Pages/Forms/FormsControl/MegaOptions/MegaOptions";
import Datepicker from "../Pages/Forms/FormWidgets/Datepicker/Datepicker";
import Touchspin from "../Pages/Forms/FormWidgets/Touchspin/Touchspin";
import Switch from "../Pages/Forms/FormWidgets/Switch/Switch";
import Typeahead from "../Pages/Forms/FormWidgets/Typeahead/Typeahead";
import Clipboard from "../Pages/Forms/FormWidgets/Clipboard/Clipboard";
import DefaultStyle from "../Pages/Buttons/DefaultStyle/DefaultStyle";
import FlatButtons from "../Pages/Buttons/FlatButtons/FlatButtons";
import EdgeButtons from "../Pages/Buttons/EdgeButtons/EdgeButtons";
import RaisedButtons from "../Pages/Buttons/RaisedButtons/RaisedButtons";
import ButtonGroup from "../Pages/Buttons/ButtonGroup/ButtonGroup";
import FlagIcons from "../Pages/Icons/FlagIcons/FlagIcons";
import FontAwesomeIcons from "../Pages/Icons/FontAwesomeIcons/FontAwesomeIcons";
import ICOIcon from "../Pages/Icons/ICOIcon/ICOIcon";
import ThemifyIcon from "../Pages/Icons/ThemifyIcon/ThemifyIcon";
import FeatherIcons from "../Pages/Icons/FeatherIcons/FeathersIcons";
import WhetherIcon from "../Pages/Icons/WhetherIcon/WhetherIcon";
import Lists from "../Pages/Ui-Kits/Lists/Lists";
import FooterDark from "../Pages/PageLayout/FooterDark/FooterDark";
import FooterFixed from "../Pages/PageLayout/FooterFixed/FooterFixed";
import HideNavScrollContainer from "../Pages/PageLayout/HideNavScrollContainer/HideNavScrollContainer";
import FlieManager from "../Pages/Apps/FlieManager/FlieManager";
import Requirement from "../Pages/Requirement/requirement";
import SampleRequest from "../Pages/SampleRequest/sampleRequest";
import RequirementFormPage from "../Pages/Masters/RequirementForm";
import BuyerFormPage from "../Pages/Masters/BuyerForm";
import SellerFormPage from "../Pages/Masters/SellerForm";
import AdminUserFormPage from "../Pages/Masters/AdminUserForm";
import SampleRequestFormPage from "../Pages/Reports/sampleRequestForm";
import SampleRequirementFormPage from "../Pages/Reports/requirementForm";
import WorkOrderPage from "../Pages/Reports/workOrderPage";
import BuyerData from "../Pages/Reports/buyerData";
import SellerData from "../Pages/Reports/sellerData";
import ProcessOrder from "../Pages/Reports/processOrder";
import PackageFormPage from "../Pages/Masters/PackageForm";
import QuantityFormPage from "../Pages/Masters/QuantityForm";
import PaymentFormPage from "../Pages/Masters/PaymentForm";
import INCOFormPage from "../Pages/Masters/INCO";
import CreateClient from "../Pages/Masters/createClient";
import CreateUsers from "../Pages/Masters/createUsers";
import CreateVerification from "../Pages/Masters/createVerification";
import Verification from "../Pages/Masters/verification";
import VerificationReport from "../Pages/Reports/verificationReport";
import ClientReport from "../Pages/Reports/clientReport";
import UserReport from "../Pages/Reports/userReport";
import AgentApprovals from "../Pages/Masters/agentApprovals";
import AgentInActive from "../Pages/Masters/agentInActive";
import AgentReport from "../Pages/Reports/agentReport";

const routes = [
  // dashboard routes
  {
    path: `${process.env.PUBLIC_URL}/dashboards/verifix`,
    Component: <ShoppingPlace />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboards/crmdashboard`,
    Component: <CRMDashboard />,
  },

  // Requirement
  {
    path: `${process.env.PUBLIC_URL}/requirements`,
    Component: <Requirement />,
  },

  // Sample Request
  {
    path: `${process.env.PUBLIC_URL}/sampleRequest`,
    Component: <SampleRequest />,
  },

  // Masters
  {
    path: `${process.env.PUBLIC_URL}/masters/requirementForm`,
    Component: <RequirementFormPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masters/buyer`,
    Component: <BuyerFormPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masters/seller`,
    Component: <SellerFormPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masters/admin`,
    Component: <AdminUserFormPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masters/package`,
    Component: <PackageFormPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masters/quantity`,
    Component: <QuantityFormPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masters/payment`,
    Component: <PaymentFormPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masters/inco`,
    Component: <INCOFormPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masters/createClient`,
    Component: <CreateClient />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masters/createUser`,
    Component: <CreateUsers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masters/agentApprovals`,
    Component: <AgentApprovals />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masters/agentInActive`,
    Component: <AgentInActive />,
  },
  {
    path: `${process.env.PUBLIC_URL}/createVerification`,
    Component: <CreateVerification />,
  },
  {
    path: `${process.env.PUBLIC_URL}/verification`,
    Component: <Verification />,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/verificationReport`,
    Component: <VerificationReport />,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/clientReport`,
    Component: <ClientReport />,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/userReport`,
    Component: <UserReport />,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/agentReport`,
    Component: <AgentReport />,
  },

  // report
  {
    path: `${process.env.PUBLIC_URL}/report/requirement`,
    Component: <SampleRequirementFormPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/sample`,
    Component: <SampleRequestFormPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/workOrder`,
    Component: <WorkOrderPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/processOrder`,
    Component: <ProcessOrder />,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/sellerData`,
    Component: <SellerData />,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/buyerData`,
    Component: <BuyerData />,
  },

  //widgets routes
  { path: `${process.env.PUBLIC_URL}/widget/general`, Component: <General /> },
  { path: `${process.env.PUBLIC_URL}/widget/chart`, Component: <Chart /> },

  // Page Layout
  {
    path: `${process.env.PUBLIC_URL}/pagelayout/hidenavscroll`,
    Component: <HideNavScrollContainer />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pagelayout/footerdark`,
    Component: <FooterDark />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pagelayout/footerfixed`,
    Component: <FooterFixed />,
  },

  // Bonus Ui
  {
    path: `${process.env.PUBLIC_URL}/bonusui/scrollable`,
    Component: <Scrollable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/treeview`,
    Component: <TreeView />,
  },
  { path: `${process.env.PUBLIC_URL}/bonusui/toasts`, Component: <Toasts /> },
  { path: `${process.env.PUBLIC_URL}/bonusui/rating`, Component: <Rating /> },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/dropzone`,
    Component: <Dropzone />,
  },
  { path: `${process.env.PUBLIC_URL}/bonusui/tour`, Component: <Tour /> },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/sweetalert2`,
    Component: <SweetAlert />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/owlcarousel`,
    Component: <OwlCarousel />,
  },
  { path: `${process.env.PUBLIC_URL}/bonusui/ribbons`, Component: <Ribbons /> },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/pagination`,
    Component: <Paginations />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/breadcrumb`,
    Component: <Breadcrumb />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/rangeslider`,
    Component: <RangeSlider />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/imagecropper`,
    Component: <ImageCropper />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/basiccard`,
    Component: <BasicCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/creativecard`,
    Component: <CreativeCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonusui/timeline`,
    Component: <TimeLine />,
  },

  //Ui-kits routes
  {
    path: `${process.env.PUBLIC_URL}/uikits/typography`,
    Component: <Typography />,
  },
  { path: `${process.env.PUBLIC_URL}/uikits/avatars`, Component: <Avatars /> },
  {
    path: `${process.env.PUBLIC_URL}/uikits/helperclasses`,
    Component: <HelperClasses />,
  },
  { path: `${process.env.PUBLIC_URL}/uikits/grid`, Component: <Grid /> },
  {
    path: `${process.env.PUBLIC_URL}/uikits/tag&pills`,
    Component: <TagPills />,
  },
  {
    path: `${process.env.PUBLIC_URL}/uikits/progress`,
    Component: <Progressbar />,
  },
  { path: `${process.env.PUBLIC_URL}/uikits/modal`, Component: <Modal /> },
  { path: `${process.env.PUBLIC_URL}/uikits/alert`, Component: <Alert /> },
  { path: `${process.env.PUBLIC_URL}/uikits/popover`, Component: <Popover /> },
  { path: `${process.env.PUBLIC_URL}/uikits/tooltip`, Component: <Tooltip /> },
  {
    path: `${process.env.PUBLIC_URL}/uikits/dropdown`,
    Component: <Dropdown />,
  },
  {
    path: `${process.env.PUBLIC_URL}/uikits/accordion`,
    Component: <Accordion />,
  },
  { path: `${process.env.PUBLIC_URL}/uikits/tab`, Component: <Tabs /> },

  //  MISCELLANEOUS
  {
    path: `${process.env.PUBLIC_URL}/pages/samplepage`,
    Component: <SamplePage />,
  },

  // Gallary
  {
    path: `${process.env.PUBLIC_URL}/gallery/gallerygrid`,
    Component: <GalleryGrid />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/gallerygriddesc`,
    Component: <GalleryGridWithDescription />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/masonrygallery`,
    Component: <MasonryGallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/masonrywithdesc`,
    Component: <MasonryGalleryWithDescription />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/hovereffects`,
    Component: <ImageHoverEffects />,
  },

  // BlogDetail
  {
    path: `${process.env.PUBLIC_URL}/blog/blogdetails`,
    Component: <BlogDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/blogsingle`,
    Component: <BlogSingle />,
  },
  { path: `${process.env.PUBLIC_URL}/bolg/addpost`, Component: <AddPost /> },

  // FAQ
  { path: `${process.env.PUBLIC_URL}/pages/faq`, Component: <FAQ /> },

  // JobSearch
  {
    path: `${process.env.PUBLIC_URL}/jobsearch/cardsview`,
    Component: <CardView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobsearch/listview`,
    Component: <ListView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobsearch/jobdetails`,
    Component: <JobDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobsearch/apply`,
    Component: <JobApply />,
  },

  // Learning
  {
    path: `${process.env.PUBLIC_URL}/learning/learninglist`,
    Component: <LearningList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/learning/detailedcourse`,
    Component: <DetailedCourse />,
  },

  // Maps
  {
    path: `${process.env.PUBLIC_URL}/maps/googlemap`,
    Component: <GoogleMap />,
  },
  {
    path: `${process.env.PUBLIC_URL}/maps/leafletmaps`,
    Component: <LeafletMaps />,
  },

  // Editors
  {
    path: `${process.env.PUBLIC_URL}/editors/ckeditor`,
    Component: <CkEditor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editors/mdeeditor`,
    Component: <MDEEditor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editors/acecodeeditor`,
    Component: <AceEditor />,
  },

  // Knowledgeable
  {
    path: `${process.env.PUBLIC_URL}/pages/knowledgebase`,
    Component: <Knowledgebase />,
  },

  // SupportTicket
  {
    path: `${process.env.PUBLIC_URL}/pages/supportticket`,
    Component: <SupportTicket />,
  },
  //  Apps
  // Ecommerce
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/product`,
    Component: <Product />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/productpage`,
    Component: <ProductPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/productlist`,
    Component: <ProductList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/paymentdetails`,
    Component: <PaymentDetails />,
  },
  { path: `${process.env.PUBLIC_URL}/ecommerce/cart`, Component: <Cart /> },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/checkout`,
    Component: <Checkout />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/invoice`,
    Component: <Invoice />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/orderhistory`,
    Component: <OrderHistory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/wishlist`,
    Component: <Wishlist />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/pricing`,
    Component: <ProductPricing />,
  },
  //  Charts
  {
    path: `${process.env.PUBLIC_URL}/charts/apexchart`,
    Component: <ApexChart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/googlechart`,
    Component: <GoogleChart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/chatjschart`,
    Component: <ChartJS />,
  },
  // Apps
  //  Project
  {
    path: `${process.env.PUBLIC_URL}/apps/project/projectlist`,
    Component: <ProjectList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/apps/project/createnew`,
    Component: <ProjectCreate />,
  },

  // Filemanager
  {
    path: `${process.env.PUBLIC_URL}/apps/filemanager`,
    Component: <FileManager />,
  },

  //  User
  {
    path: `${process.env.PUBLIC_URL}/users/usersprofile`,
    Component: <UserProfile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/usersedit`,
    Component: <UserEdit />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/userscards`,
    Component: <UserCard />,
  },

  // Bookmarks
  { path: `${process.env.PUBLIC_URL}/apps/bookmark`, Component: <Bookmark /> },

  // Contact
  { path: `${process.env.PUBLIC_URL}/apps/contact`, Component: <Contact /> },

  // Email
  { path: `${process.env.PUBLIC_URL}/email/emailapp`, Component: <EmailApp /> },
  {
    path: `${process.env.PUBLIC_URL}/email/emailcompose`,
    Component: <EmailCompose />,
  },

  // Chat
  { path: `${process.env.PUBLIC_URL}/chat/chatapp`, Component: <Chating /> },
  {
    path: `${process.env.PUBLIC_URL}/chat/videochat`,
    Component: <VideoChat />,
  },

  //  Tasks
  { path: `${process.env.PUBLIC_URL}/apps/task`, Component: <Tasks /> },

  // Calender
  { path: `${process.env.PUBLIC_URL}/apps/calender`, Component: <Calender /> },

  //  socialapp
  {
    path: `${process.env.PUBLIC_URL}/apps/socialapp`,
    Component: <SocialApp />,
  },

  //  Todo
  { path: `${process.env.PUBLIC_URL}/apps/todo`, Component: <Todo /> },
  {
    path: `${process.env.PUBLIC_URL}/apps/filemanager`,
    Component: <FlieManager />,
  },
  // Tables
  // ReactStrapTables
  {
    path: `${process.env.PUBLIC_URL}/tables/reactstraptables/basictables`,
    Component: <BasicTables />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/reactstraptables/tablecomponents`,
    Component: <TableComponent />,
  },

  // DataTables
  {
    path: `${process.env.PUBLIC_URL}/tables/datatables/basicinit`,
    Component: <BasicDataTables />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/datatables/advanceinit`,
    Component: <AdvanceInit />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/datatables/api`,
    Component: <APIDataTables />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/datatables/datasources`,
    Component: <DataSources />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/datatables/api`,
    Component: <APIDataTables />,
  },
  //  Forms
  // Form Wizard
  {
    path: `${process.env.PUBLIC_URL}/forms/formlayout/formwizard1`,
    Component: <FormWizard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formlayout/formwizard2`,
    Component: <FormWizardTwo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formlayout/twofactor`,
    Component: <TwoFactor />,
  },
  { path: `${process.env.PUBLIC_URL}/uikits/lists`, Component: <Lists /> },

  //  Forms
  // Controls
  {
    path: `${process.env.PUBLIC_URL}/forms/formcontrols/formvalidation`,
    Component: <FormValidation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formcontrols/baseinputs`,
    Component: <BaseInput />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formcontrols/checkbox&radio`,
    Component: <CheckboxRadio />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formcontrols/inputgroups`,
    Component: <InputGroups />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formcontrols/inputmask`,
    Component: <InputMask />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formcontrols/megaoptions`,
    Component: <MegaOptions />,
  },

  // Widgets
  {
    path: `${process.env.PUBLIC_URL}/forms/formwidgets/datepicker`,
    Component: <Datepicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formwidgets/touchspin`,
    Component: <Touchspin />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formwidgets/switch`,
    Component: <Switch />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formwidgets/typeahead`,
    Component: <Typeahead />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/formwidgets/clipboard`,
    Component: <Clipboard />,
  },

  //  Buttons
  {
    path: `${process.env.PUBLIC_URL}/buttons/defaultstyle`,
    Component: <DefaultStyle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/flatstyle`,
    Component: <FlatButtons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/edgestyle`,
    Component: <EdgeButtons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/raisedstyle`,
    Component: <RaisedButtons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/buttongroup`,
    Component: <ButtonGroup />,
  },

  //  Icons
  {
    path: `${process.env.PUBLIC_URL}/icons/flagicon`,
    Component: <FlagIcons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/fontawesomeicon`,
    Component: <FontAwesomeIcons />,
  },
  { path: `${process.env.PUBLIC_URL}/icons/icoicon`, Component: <ICOIcon /> },
  {
    path: `${process.env.PUBLIC_URL}/icons/themifyicon`,
    Component: <ThemifyIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/feathericon`,
    Component: <FeatherIcons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/whethericon`,
    Component: <WhetherIcon />,
  },

  // SearchResult
  {
    path: `${process.env.PUBLIC_URL}/apps/searchresult`,
    Component: <SearchResult />,
  },
];
export default routes;
