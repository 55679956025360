import { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Label, Input, Button, Form, Table } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, sessionExpired } from "../Helper/utils";
import Select from 'react-select';
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CreateClient = () => {
  const [apiData, setApiData] = useState([]);
  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    verificationType: [],
    verificationTypes: [],
    pushId: ''
  })

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const defaultState = () => {
    setState({
      ...state,
      name: '',
      email: '',
      password: '',
      verificationType: [],
      pushId: ''
    })
  }

  const customStyles1 = {
    control: base => ({
      ...base,
      height: 53,
      minHeight: 35,
      backgroundColor: "white",
      overflow: "hidden",
    })
  };

  useEffect(() => {
    let verificationTypes = []

    let verificationNames = ["Education", "Employment", "Address", "Criminal", "Identity", "Reference", "Database", "Social Media", "Drug Test", "Credit Check", "Police Verification"]

    for(let i= 0; i < verificationNames.length; i++) {
      const dict = {
        label: verificationNames[i],
        value: verificationNames[i]
      }
      verificationTypes.push(dict)
    }

    setState({
      ...state,
      verificationTypes: verificationTypes
    })

    var config = {
      method: 'get',
      url: API_BASE_URL + '/api/client',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config)
    .then(function (response) {
      setApiData(response.data)
    })
    .catch(function (error) {
      console.log(error);
      handleNon200Response(error);
    });
  }, [apiData])

  const handleNon200Response = (error) => {
    if(error.response.status === 401 && error.response.data.message === "User Not authenticated") {
      sessionExpired();
    } 
  }

  const handleVerification = (verificationType) => {
    setState({
      ...state,
      verificationType
    })
  };

  const handleSubmit = () => {
      if(state.name === '') {
        alert("Enter Name")
        document.getElementById("name").focus();
        return;
      }

      if(state.email === '') {
        alert("Enter Email")
        document.getElementById("email").focus();
        return;
      }

      if(state.password === '') {
        alert("Enter Password")
        document.getElementById("password").focus();
        return;
      }

      if(state.verificationType.length == 0) {
        alert("Select Verification Type")
        return;
      }

      let verify = []
      for(let i=0; i < state.verificationType.length; i++) {
        verify.push(state.verificationType[i].value)
      }

      var jsonData = JSON.stringify({
        "clientName": state.name,
        "clientEmail": state.email,
        "password": state.password,
        "verificationTypes": verify,
        "status": "Active",
      });

      var config = {
        method: 'post',
        url: API_BASE_URL + "/api/client",
        headers: { 
          'apikey': API_KEY, 
          'Authorization': 'Bearer ' + getCookie('at'),
          'Content-Type': 'application/json'
        },
        data : jsonData
      };

    axios(config)
    .then(function (response) {
      if(response.status === 201) {
        defaultState()
        alert("Added Successfully")
      }
    })
    .catch(function (error) {
      console.log(error.response.data)
      handleNon200Response(error)
    });
  }

  const handleUpdate = () => {
    if(state.name === '') {
      alert("Enter Name")
      document.getElementById("name").focus();
      return;
    }

    if(state.email === '') {
      alert("Enter Email")
      document.getElementById("email").focus();
      return;
    }

    if(state.password === '') {
      alert("Enter Password")
      document.getElementById("password").focus();
      return;
    }

    if(state.verificationType.length == 0) {
      alert("Select Verification Type")
      return;
    }

    let verify = []
    for(let i=0; i < state.verificationType.length; i++) {
      verify.push(state.verificationType[i].value)
    }

    var jsonData = JSON.stringify({
      "clientName": state.name,
      "clientEmail": state.email,
      "password": state.password,
      "verificationTypes": verify,
      "status": "Active",
    });

    var config = {
      method: 'put',
      url: API_BASE_URL + "/api/client/" + state.pushId,
      headers: {  
        'apikey': API_KEY, 
        'Authorization': 'Bearer ' + getCookie('at'),
        'Content-Type': 'application/json'
      },
      data : jsonData
    };

  axios(config)
  .then(function (response) {
    if(response.status === 200) {
      defaultState()
      alert("Updated Successfully")
    }
  })
  .catch(function (error) {
    console.log(error.response.data)
    handleNon200Response(error)
  });

  document.getElementById("submit").style.display = "block"
  document.getElementById("update").style.display = "none"
}

  const editRow = (key) => {
    document.getElementById("submit").style.display = "none"
    document.getElementById("update").style.display = "block"

    let verificationType = []

    for(let i= 0; i < key.verificationTypes.length; i++) {
      const dict = {
        label: key.verificationTypes[i],
        value: key.verificationTypes[i]
      }
      verificationType.push(dict)
    }

    setState({
      ...state,
      name: key.clientName,
      email: key.clientEmail,
      password: key.password,
      verificationType: verificationType,
      pushId: key._id
    })
  }

  const deleteRow = (key) => {
    if(window.confirm('Are you sure you want to delete?')) {
      var config = {
        method: 'delete',
        url:  API_BASE_URL + '/api/client/' + key._id,
        headers: { 
          'apikey': API_KEY,
          'Authorization': 'Bearer ' + getCookie('at')
        },
      };
    
      axios(config)
      .then(function (response) {
        if(response.status == 200) {
          defaultState()
          alert("Deleted Successfully")
        }
      })
      .catch(function (error) {
        console.log(error)
        handleNon200Response(error)
      });
    } else {}
  }
  
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Create Client"}
          parent={"Masters"}
          title={"Client"}
        />
        <Col xl={12}>
            <Card className="height-equal">
                {/* <CommonHeader
                title={"BrowserDefaultsHeading"}
                subTitle={subTitle}
                headClass="pb-0"
                /> */}
                <CardBody className="custom-input">
                {/* <Form> */}
                    <Row className="g-3">
                      <Col xs={12}>
                        <Label htmlFor="first-name"> {"Name"}</Label>
                        <Input
                            type="text"
                            placeholder="Name"
                            value={state.name}
                            id = "name"
                            name = "name"
                            onChange={handleChange}
                            required
                        />
                        </Col>
                        <Col xs={12}>
                        <Label>{"Email"}</Label>
                        <Input
                            type="email"
                            placeholder="Email"
                            value={state.email}
                            id = "email"
                            name = "email"
                            onChange={handleChange}
                            required
                        />
                        </Col>
                        <Col xs={12}>
                        <Label>{"Password"}</Label>
                        <Input
                            type="password"
                            placeholder="Password"
                            value={state.password}
                            id = "password"
                            name = "password"
                            onChange={handleChange}
                            required
                        />
                        </Col>
                        <Col xs={12}>
                        <Label>Types of Verification</Label>
                        <Select
                          value = {state.verificationType}
                          onChange={handleVerification}
                          options={state.verificationTypes}
                          id= "verificationType"
                          placeholder = "Select Verification Type"
                          isMulti = {true}
                          styles = {customStyles1}
                         />
                        </Col>
                        <Col xs={12}>
                        <Button color="primary" type="submit" id = "submit" onClick = {handleSubmit}>
                            Submit
                        </Button>
                        <Button color="primary" type="submit" id = "update" onClick = {handleUpdate} style={{display: "none"}}>
                            Update
                        </Button>
                        </Col>
                    </Row>
                    {/* </Form> */}
                </CardBody>
            </Card>
        </Col>
        <br />
        <Col xl={12}>
            <Card className="height-equal">

                <CardBody className="custom-input">
                        <Table responsive className="display order-wrapper" id="recent-order">
                                <thead>
                                <tr>
                                    <th>SL.No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Password</th>
                                    <th>Verification Types</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                                </thead>
                                <tbody>
                                {apiData.map((rowData, index) => (
                                    <tr key = {index}>
                                        <td>{index + 1}</td>
                                        <td>{rowData.clientName}</td>
                                        <td>{rowData.clientEmail}</td>
                                        <td>{rowData.password}</td>
                                        <td>{rowData.verificationTypes.join()}</td>
                                        <td onClick={() => editRow(rowData)}><FontAwesomeIcon style = {{color : "black", fontSize: "12px", cursor: "pointer"}} icon={faEdit} /></td>
                                        <td onClick={() => deleteRow(rowData)}><FontAwesomeIcon style = {{color : "red", fontSize: "12px", cursor: "pointer"}} icon={faTrash} /></td>
                                    </tr>
                                  ))}
                                </tbody>
                        </Table>
                </CardBody>
            </Card>
        </Col>
      </Container>
    </>
  );
};

export default CreateClient;
