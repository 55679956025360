import { Fragment, useState } from "react";
import { Button } from "reactstrap";
import CommonModal from "../../../../../CommonElements/Ui-kits/CommonModal";
import FeatherIcons from "../../../../../utils/CommonSvgIcon/FeatherIcons";
import { H6, P } from "../../../../../AbstractElements";
import { largeModalData } from "../../../../../Data/Ui-Kits/ModalData";
import { Large_Modal, Startyourgoals } from "../../../../../utils/Constant";
import RequirementForm from "../../../../Forms/FormsControl/FormValidition/RequirementForm/BrowserDefault";

interface ModalItems {
  isOpen: boolean;
  header: boolean;
  class: string;
  toggler: () => void;
  title: string;
  size: string;
}

const LargeModal = ({ modalData }: { modalData: ModalItems }) => {
  return (
    <>
      <CommonModal modalData={modalData}>
        <RequirementForm />
        {/*<>
           {largeModalData &&
            largeModalData.map((item, index) => )} 
        </>*/}
      </CommonModal>
    </>
  );
};

export default LargeModal;
