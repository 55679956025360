import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useState } from "react";

interface PropsItem {
  formData: {
    name: string;
    status: string;
  };
  setFormData: React.Dispatch<
    React.SetStateAction<{
      name: string;
      status: string;
    }>
  >;
  handleSubmit: any;
}

const BrowserDefaultForm: React.FC<PropsItem> = ({
  handleSubmit,
  formData,
  setFormData,
}) => {
  const handleInputChange = (fieldName: string, value: string | number) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="g-3">
        <Col xs={12}>
          <Label htmlFor="name">Name</Label>
          <Input
            type="text"
            placeholder="Name"
            value={formData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            required
          />
        </Col>
        <Col xs={12}>
          <Label htmlFor="status">Status</Label>
          <Input
            id="status"
            name="select"
            type="select"
            className="form-select"
            value={formData.status}
            onChange={(e) => handleInputChange("status", e.target.value)}
            required
          >
            <option value={"active"}>Active</option>
            <option value={"inactive"}>Inactive</option>
          </Input>
        </Col>
        <Col xs={12}>
          <Button color="primary" type="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BrowserDefaultForm;
