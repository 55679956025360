export const BarRatingData = [
    {
      text: "Rating is displayed using ",
      code: "step={*}",
    },
    {
      text: " attr in Rating tag",
    },
  ];
  
  export const MovingRatingDta = [
    {
      text: "Movie rating is displayed using ",
      code: "step={4}",
    },
    {
      text: " in react-rating package",
    },
  ];
  
  export const SquareRatingData = [
    {
      text: "Square rating is displayed using",
      code: " emptySymbol & fullSymbol ",
    },
  ];
  
  export const PillRatingData = [
    {
      text: "Pill rating is displayed using ",
      code: ".pill-rating",
    },
    {
      text: " class",
    },
  ];
  
  export const ReverseRatingData = [
    {
        text: "Reversed rating is displayed using ",
        code: 'direction="rtl"'
    },
    {
        text: ' Attribute in Rating.'
    }
  ]
  
  export const StarRatingData = [
    {
        text: "Star rating is displayed using ",
        code: '.fa-star-o & fa-star'
    },
    {
        text: ' class in rating symbol'
    }
  ]
  
  export const HalfStarRatingData = [
    {
        text: "Star rating is displayed using ",
        code: '.fa-star-o & fa-star'
    },
    {
        text: ' class in rating symbol'
    }
  ]
  
  export const ThumbUpDownData = [
    {
        text: "Thumb rating is displayed using ",
        code: '.fa-thumbs-up & .fa-thumbs-down'
    },
    {
        text: ' class in rating symbol'
    }
  ]
  
  export const HeartRatingData = [
    {
        text: "Heart rating is displayed using ",
        code: '.fa-heart-o & .fa-heart'
    },
    {
        text: ' class in rating symbol'
    }
  ]