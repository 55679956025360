export const dummyData = [
  {
    SlNo: 1,
    BuyerName: "John Doe",
    ProductName: "Widget A",
    PortOfDelivery: "Port City",
    PackagingType: "Box",
    INCOTerms: "FOB",
  },
  {
    SlNo: 2,
    BuyerName: "Jane Smith",
    ProductName: "Gadget B",
    PortOfDelivery: "Harbor Town",
    PackagingType: "Crate",
    INCOTerms: "CIF",
  },
  {
    SlNo: 3,
    BuyerName: "Bob Johnson",
    ProductName: "Tool C",
    PortOfDelivery: "Seaside Port",
    PackagingType: "Pallet",
    INCOTerms: "EXW",
  },
  // Add more entries as needed
];
