export const buttons = [
  {
    toolTipText: "",
    color: "primary",
    tittle: "New",
    id: "default1",
    outline: "",
    active: false,
    disabled: false,
    size: "l",
  },
  {
    toolTipText: "",
    color: "info",
    tittle: "In Progress",
    id: "default2",
    outline: "",
    active: true,
    disabled: false,
    size: "l",
  },
  {
    toolTipText: "",
    color: "warning",
    tittle: "Need Attention",
    id: "default3",
    outline: "",
    active: false,
    disabled: false,
    size: "l",
  },
  {
    toolTipText: "",
    color: "danger",
    tittle: "Closed",
    id: "default4",
    outline: "",
    active: false,
    disabled: false,
    size: "l",
  },
];
