import { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Label, Input, Button, Form, Table } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import axios from "axios";
import { API_BASE_URL, API_KEY, S3_BUCKET_NAME, S3_REGION, S3_ACCESS_KEY, S3_SECRET_KEY, getCookie, sessionExpired } from "../Helper/utils";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AWS from 'aws-sdk';

const config = {
  accessKeyId: S3_ACCESS_KEY,
  secretAccessKey: S3_SECRET_KEY,
  region: S3_REGION,
};
const s3 = new AWS.S3(config);

const CreateVerification = (props) => {
  const { getByVerificarionNumber, createVerificationLink } = props;

  const [apiData, setApiData] = useState([]);
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [state, setState] = useState({
    name: '',
    number: '',
    email: '',
    address: '',
    clientId: '',
    vEducationStatus: false,
    vEmploymentStatus: false,
    vAddressStatus: false,
    vCriminalStatus: false,
    vIdentityStatus: false,
    vReferenceStatus: false,
    vDatabaseStatus: false,
    vSocialMediaStatus: false,
    vDrugTestStatus: false,
    vCreditCheckStatus: false,
    vPoliceVerificationStatus: false,
    verificationStatus: '',
    pushId: ''
  })

  const [educationDetails, setEducationDetails] = useState({
    courseDegree: "",
    college: "",
    days: "",
    enrollmentNo: "",
    yearOfPassing: "",
    verifier: "",
    contactNo: "",
    modeOfVerification: "",
    remarks: "",
    marksCard: "",
    image1: "",
  });

  const [employmentDetails, setEmploymentDetails] = useState({
    companyName: "",
    tenure: "",
    designation: "",
    employeeCode: "",
    salary: "",
    reportingManager: "",
    duties: "",
    issues: "",
    performanceAtWork: "",
    reasonForLeaving: "",
    eligibilityToRetire: "",
    verifier: "",
    contactNo: "",
    modeOfVerification: "",
    remarks: "",
    image1: "",
  });

  const [addressDetails, setAddressDetails] = useState({
    name: "",
    fatherName: "",
    dob: "",
    confirmationAddress: "",
    typeOfAddress: "",
    contactNumber: "",
    periodOfStay: "",
    typeOfProperty: "",
    idProof: "",
    specialComments: "",
    image1: "",
  });

  const [criminalDetails, setCriminalDetails] = useState({
    fatherName: "",
    dob: "",
    magistrate: "",
    sessions: "",
    highCourt: "",
    durationOfRecordsChecked: "",
  });

  const [identityDetails, setIdentityDetails] = useState({
    fullName: "",
    fatherName: "",
    dob: "",
    pancardNo: "",
    image1: "",
  });

  const [referenceDetails, setReferenceDetails] = useState({
    respondentName: "",
    designation: "",
    organisation: "",
  });

  const [drugTestDetails, setDrugTestDetails] = useState({
    name: "",
    dob: "",
    bloodSamplesCollected: "",
    collectionLocation: "",
    testResult: "",
  });

  const [creditCheckDetails, setCreditCheckDetails] = useState({
    name: "",
    dob: "",
    mobileNumber: "",
    address: "",
    pancardNo: "",
  });

  const [policeVerificationDetails, setPoliceVerificationDetails] = useState({
    name: "",
    fatherName: "",
    dob: "",
    address: "",
    civil: "",
    magistrate: "",
    sessions: "",
    highCourt: "",
  });

  useEffect(() => {
    if (getByVerificarionNumber) {
      setState({
        name: getByVerificarionNumber?.name || "",
        number: getByVerificarionNumber?.number || "",
        email: getByVerificarionNumber?.email || "",
        address: getByVerificarionNumber?.address || "",
        clientId: getByVerificarionNumber?.clientId || "",
        vEducationStatus: getByVerificarionNumber?.vEducationStatus || false,
        vEmploymentStatus: getByVerificarionNumber?.vEmploymentStatus || false,
        vAddressStatus: getByVerificarionNumber?.vAddressStatus || false,
        vCriminalStatus: getByVerificarionNumber?.vCriminalStatus || false,
        vIdentityStatus: getByVerificarionNumber?.vIdentityStatus || false,
        vReferenceStatus: getByVerificarionNumber?.vReferenceStatus || false,
        vDatabaseStatus: getByVerificarionNumber?.vDatabaseStatus || false,
        vSocialMediaStatus: getByVerificarionNumber?.vSocialMediaStatus || false,
        vDrugTestStatus: getByVerificarionNumber?.vDrugTestStatus || false,
        vCreditCheckStatus: getByVerificarionNumber?.vCreditCheckStatus || false,
        vPoliceVerificationStatus: getByVerificarionNumber?.vPoliceVerificationStatus || false,
        pushId: "",
        verificationStatus: getByVerificarionNumber.status
      });

      const lastEducationIndex = getByVerificarionNumber?.vEducation?.length - 1;
      const lastEducation = getByVerificarionNumber?.vEducation[lastEducationIndex];

      setEducationDetails({
        courseDegree: lastEducation?.courseDegree || "",
        college: lastEducation?.college || "",
        days: lastEducation?.days || "",
        enrollmentNo: lastEducation?.enrollmentNo || "",
        yearOfPassing: lastEducation?.yearOfPassing || "",
        verifier: lastEducation?.verifier || "",
        contactNo: lastEducation?.contactNo || "",
        modeOfVerification: lastEducation?.modeOfVerification || "",
        remarks: lastEducation?.remarks || "",
        marksCard: lastEducation?.marksCard || "",
        image1: lastEducation?.image1 || "",
      });

      const lastEmploymentIndex = getByVerificarionNumber?.vEmployment?.length - 1;
      const lastEmployment = getByVerificarionNumber?.vEmployment[lastEmploymentIndex];

      setEmploymentDetails({
        companyName: lastEmployment?.companyName || "",
        tenure: lastEmployment?.tenure || "",
        designation: lastEmployment?.designation || "",
        employeeCode: lastEmployment?.employeeCode || "",
        salary: lastEmployment?.salary || "",
        reportingManager: lastEmployment?.reportingManager || "",
        duties: lastEmployment?.duties || "",
        issues: lastEmployment?.issues || "",
        performanceAtWork: lastEmployment?.performanceAtWork || "",
        reasonForLeaving: lastEmployment?.reasonForLeaving || "",
        eligibilityToRetire: lastEmployment?.eligibilityToRetire || "",
        verifier: lastEmployment?.verifier || "",
        contactNo: lastEmployment?.contactNo || "",
        modeOfVerification: lastEmployment?.modeOfVerification || "",
        remarks: lastEmployment?.remarks || "",
        image1: lastEmployment?.image1 || "",
      });

      const lastAddressIndex = getByVerificarionNumber?.vAddress?.length - 1;
      const lastAddress = getByVerificarionNumber?.vAddress[lastAddressIndex];

      setAddressDetails({
        name: lastAddress?.name || "",
        fatherName: lastAddress?.fatherName || "",
        dob: lastAddress?.dob || "",
        confirmationAddress: lastAddress?.confirmationAddress || "",
        typeOfAddress: lastAddress?.typeOfAddress || "",
        contactNumber: lastAddress?.contactNumber || "",
        periodOfStay: lastAddress?.periodOfStay || "",
        typeOfProperty: lastAddress?.typeOfProperty || "",
        idProof: lastAddress?.idProof || "",
        specialComments: lastAddress?.specialComments || "",
        image1: lastAddress?.image1 || "",
        
      });

      const lastCriminalIndex = getByVerificarionNumber?.vCriminal?.length - 1;
      const lastCriminal = getByVerificarionNumber?.vCriminal[lastCriminalIndex];

      setCriminalDetails({
        fatherName: lastCriminal?.fatherName || "",
        dob: lastCriminal?.dob || "",
        magistrate: lastCriminal?.magistrate || "",
        sessions: lastCriminal?.sessions || "",
        highCourt: lastCriminal?.highCourt || "",
        durationOfRecordsChecked: lastCriminal?.durationOfRecordsChecked || "",        
      });

      const lastIdentityIndex = getByVerificarionNumber?.vIdentity?.length - 1;
      const lastIdentity = getByVerificarionNumber?.vIdentity[lastIdentityIndex];

      setIdentityDetails({
        fullName: lastIdentity?.fullName || "",
        fatherName: lastIdentity?.fatherName || "",
        dob: lastIdentity?.dob || "",
        pancardNo: lastIdentity?.pancardNo || "",
        image1: lastIdentity?.image1 || "",
      });

      const lastReferenceIndex = getByVerificarionNumber?.vReference?.length - 1;
      const lastReference = getByVerificarionNumber?.vReference[lastReferenceIndex];

      setReferenceDetails({
        respondentName: lastReference?.respondentName || "",
        designation: lastReference?.designation || "",
        organisation: lastReference?.organisation || "",
      });

      const lastDrugTestIndex = getByVerificarionNumber?.vDrugTest?.length - 1;
      const lastDrugTest = getByVerificarionNumber?.vDrugTest[lastDrugTestIndex];
 
      setDrugTestDetails({
        name: lastDrugTest?.name || "",
        dob: lastDrugTest?.dob || "",
        bloodSamplesCollected: lastDrugTest?.bloodSamplesCollected || "",
        collectionLocation: lastDrugTest?.collectionLocation || "",
        testResult: lastDrugTest?.testResult || "",
      });

      const lastCreditCheckIndex = getByVerificarionNumber?.vCreditCheck?.length - 1;
      const lastCreditCheck = getByVerificarionNumber?.vCreditCheck[lastCreditCheckIndex];
 
      setCreditCheckDetails({
        name: lastCreditCheck?.name || "",
        dob: lastCreditCheck?.dob || "",
        mobileNumber: lastCreditCheck?.mobileNumber || "",
        address: lastCreditCheck?.address || "",
        pancardNo: lastCreditCheck?.pancardNo || "",
      });

      const lastPoliceVerificationIndex = getByVerificarionNumber?.vPoliceVerification?.length - 1;
      const lastPoliceVerification = getByVerificarionNumber?.vPoliceVerification[lastPoliceVerificationIndex];
 
      setPoliceVerificationDetails({
        name: lastPoliceVerification?.name || "",
        fatherName: lastPoliceVerification?.fatherName || "",
        dob: lastPoliceVerification?.dob || "",
        address: lastPoliceVerification?.address || "",
        civil: lastPoliceVerification?.civil || "",
        magistrate: lastPoliceVerification?.magistrate || "",
        sessions: lastPoliceVerification?.sessions || "",
        highCourt: lastPoliceVerification?.highCourt || "",
      });
    }
  }, [getByVerificarionNumber]);

  useEffect(() => {
    if(!createVerificationLink){
    let jsonData = {};

    if (userType == "CLIENT") {
      jsonData.clientId = localStorage.getItem("id");
    }

    jsonData.status = "Pending";
    let jsonData1 = JSON.stringify(jsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/api/verificationList',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };

    axios(config)
      .then(function (response) {
        setApiData(response.data)
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error);
      });

    var config = {
      method: 'get',
      url: API_BASE_URL + '/api/client',
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
      }
    };

    axios(config)
      .then(function (response) {
        setClientData(response.data)
      })
      .catch(function (error) {
        console.log(error);
        handleNon200Response(error);
      });
    }
  }, []);

  const userType = localStorage.getItem("type");

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleEducationChange = (e) => {
    setEducationDetails({
      ...educationDetails,
      [e.target.name]: e.target.value
    })
  }
  const uploadMarksCard = (e) => {
    const file = e.target.files[0];
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: `verification/${file.name}`,
      Body: file,
    };
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'application/pdf') {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading file:', err);
        } else {
           setEducationDetails({
            ...educationDetails,
            image1: data.Location
          })
        }
      });
    } else {
      alert("File type is not supported")
    }
  }
  const defaultEducationDetails = () => {
    setEducationDetails({
      ...educationDetails,
      courseDegree: '',
      college: '',
      days: '',
      enrollmentNo: '',
      yearOfPassing: '',
      verifier: '',
      contactNo: '',
      modeOfVerification: '',
      remarks: '',
      marksCard: '',
      image1: ''
    })
  }
  const handleEmploymentChange = (e) => {
    setEmploymentDetails({
      ...employmentDetails,
      [e.target.name]: e.target.value
    })
  }
  const uploadIdCard = (e) => {
    const file = e.target.files[0];
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: `verification/${file.name}`,
      Body: file,
    };
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'application/pdf') {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading file:', err);
        } else {
           setEmploymentDetails({
            ...employmentDetails,
            image1: data.Location
          })
        }
      });
    } else {
      alert("File type is not supported")
    }
  }
  const defaultEmploymentDetails = () => {
    setEmploymentDetails({
      ...employmentDetails,
      companyName: '',
      tenure: '',
      designation: '',
      employeeCode: '',
      salary: '',
      reportingManager: '',
      duties: '',
      issues: '',
      performanceAtWork: '',
      reasonForLeaving: '',
      eligibilityToRetire: '',
      verifier: '',
      contactNo: '',
      modeOfVerification: '',
      remarks: '',
      image1: ''
    })
  }

  const handleAddressChange = (e) => {
    setAddressDetails({
      ...addressDetails,
      [e.target.name]: e.target.value
    })
  }
  const uploadIdCardForAddress = (e) => {
    const file = e.target.files[0];
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: `verification/${file.name}`,
      Body: file,
    };
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'application/pdf') {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading file:', err);
        } else {
           setAddressDetails({
            ...addressDetails,
            image1: data.Location
          })
        }
      });
    } else {
      alert("File type is not supported")
    }
  }
  const defaultAddressDetails = () => {
    setAddressDetails({
      ...addressDetails,
      name: '',
      fatherName: '',
      dob: '',
      confirmationAddress: '',
      typeOfAddress: '',
      contactNumber: '',
      periodOfStay: '',
      typeOfProperty: '',
      idProof: '',
      specialComments: '',
      image1: ''
    })
  }

  const handleCriminalChange = (e) => {
    setCriminalDetails({
      ...criminalDetails,
      [e.target.name]: e.target.value
    })
  }
  const defaultCriminalDetails = () => {
    setCriminalDetails({
      ...criminalDetails,
      fatherName: '',
      dob: '',
      magistrate: '',
      sessions: '',
      highCourt: '',
      durationOfRecordsChecked: '',
    })
  }

  const handlePoliceVerificationChange = (e) => {
    setPoliceVerificationDetails({
      ...policeVerificationDetails,
      [e.target.name]: e.target.value
    })
  }
  const defaultPoliceVerificationDetails = () => {
    setPoliceVerificationDetails({
      ...policeVerificationDetails,
      name: '',
      fatherName: '',
      dob: '',
      address: '',
      civil: '',
      magistrate: '',
      sessions: '',
      highCourt: '',
    })
  }

  const handleReferenceChange = (e) => {
    setReferenceDetails({
      ...referenceDetails,
      [e.target.name]: e.target.value
    })
  }
  const defaultReferenceDetails = () => {
    setReferenceDetails({
      ...referenceDetails,
      respondentName: '',
      designation: '',
      organisation: '',
    })
  }

  const handleIdentityChange = (e) => {
    setIdentityDetails({
      ...identityDetails,
      [e.target.name]: e.target.value
    })
  }
  const uploadPancardImage = (e) => {
    const file = e.target.files[0];
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: `verification/${file.name}`,
      Body: file,
    };
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'application/pdf') {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading file:", err);
        } else {
           setIdentityDetails({
            ...identityDetails,
            image1: data.Location
          })
        }
      });
    } else {
      alert("File type is not supported")
    }
  }
  const defaultIdentityDetails = () => {
    setIdentityDetails({
      ...identityDetails,
      fullName: '',
      fatherName: '',
      dob: '',
      pancardNo: '',
      image1: ''
    })
  }

  const handleDrugTestChange = (e) => {
    setDrugTestDetails({
      ...drugTestDetails,
      [e.target.name]: e.target.value
    })
  }
  const defaultDrugTestDetails = () => {
    setDrugTestDetails({
      ...drugTestDetails,
      name: '',
      dob: '',
      bloodSamplesCollected: '',
      collectionLocation: '',
      testResult: ''
    })
  }

  const handleCreditCheckChange = (e) => {
    setCreditCheckDetails({
      ...creditCheckDetails,
      [e.target.name]: e.target.value
    })
  }
  const defaultCreditCheckDetails = () => {
    setCreditCheckDetails({
      ...creditCheckDetails,
      name: '',
      dob: '',
      mobileNumber: '',
      address: '',
      pancardNo: ''
    })
  }

  function checkBoxOnChange(evt) {
    const value = evt.target.checked;
    setState({
      ...state,
      [evt.target.name]: value
    });
  }

  const defaultState = () => {
    setState({
      ...state,
      name: '',
      number: '',
      email: '',
      address: '',
      clientId: '',
      vEducationStatus: false,
      vEmploymentStatus: false,
      vAddressStatus: false,
      vCriminalStatus: false,
      vIdentityStatus: false,
      vReferenceStatus: false,
      vDatabaseStatus: false,
      vSocialMediaStatus: false,
      vDrugTestStatus: false,
      vCreditCheckStatus: false,
      vPoliceVerificationStatus: false,
      pushId: ''
    })
  }

  const handleNon200Response = (error) => {
    if (error.response.status === 401 && error.response.data.message === "User Not authenticated") {
      sessionExpired();
    }
  }

  const handleSubmit = () => {

    if (state.name === '') {
      alert("Enter Full Name")
      document.getElementById("name").focus();
      return;
    }

    if (state.number === '') {
      alert("Enter Mobile Number")
      document.getElementById("number").focus();
      return;
    }

    if (state.email === '') {
      alert("Enter Email")
      document.getElementById("email").focus();
      return;
    }

    if (state.address === '') {
      alert("Enter Address")
      document.getElementById("address").focus();
      return;
    }

    let clientId = ""

    if (userType == "WEB") {
      if (state.clientId === '') {
        alert("Selct Client")
        document.getElementById("clientId").focus();
        return;
      }
      clientId = state.clientId
    } else {
      clientId = localStorage.getItem("id")
    }

    // if (state.vEducationStatus == true) {
    //   if (educationDetails.courseDegree === '') {
    //     alert("Enter Course Degree")
    //     document.getElementById("courseDegree").focus();
    //     return;
    //   }

    //   if (educationDetails.college === '') {
    //     alert("Enter College")
    //     document.getElementById("college").focus();
    //     return;
    //   }

    //   if (educationDetails.days === '') {
    //     alert("Enter Dates Attended")
    //     document.getElementById("days").focus();
    //     return;
    //   }

    //   if (educationDetails.image1 === '') {
    //     alert("Select Image in Education Details")
    //     return;
    //   }
    // }

    // if (state.vEmploymentStatus == true) {
    //   if (employmentDetails.companyName === '') {
    //     alert("Enter Company Name")
    //     document.getElementById("companyName").focus();
    //     return;
    //   }

    //   if (employmentDetails.tenure === '') {
    //     alert("Enter Tenure")
    //     document.getElementById("tenure").focus();
    //     return;
    //   }

    //   if (employmentDetails.designation === '') {
    //     alert("Enter Designation")
    //     document.getElementById("designation").focus();
    //     return;
    //   }
    // }


    // if (state.vAddressStatus == true) {
    //   if (addressDetails.name === '') {
    //     alert("Enter Name")
    //     document.getElementById("name").focus();
    //     return;
    //   }

    //   if (addressDetails.fatherName === '') {
    //     alert("Enter Father Name")
    //     document.getElementById("fatherName").focus();
    //     return;
    //   }

    //   if (addressDetails.dob === '') {
    //     alert("Enter dob")
    //     document.getElementById("dob").focus();
    //     return;
    //   }
    // }

    // if (state.vIdentityStatus == true) {
    //   if (identityDetails.fullName === '') {
    //     alert("Enter Full Name")
    //     document.getElementById("fullName").focus();
    //     return;
    //   }

    //   if (identityDetails.fatherName === '') {
    //     alert("Enter Father Name")
    //     document.getElementById("fatherName").focus();
    //     return;
    //   }

    //   if (identityDetails.pancardNo === '') {
    //     alert("Enter Pan Card Number")
    //     document.getElementById("pancardNo").focus();
    //     return;
    //   }
    // }

    // if (state.vCreditCheckStatus == true) {
    //   if (creditCheckDetails.name === '') {
    //     alert("Enter Name")
    //     document.getElementById("name").focus();
    //     return;
    //   }

    //   if (creditCheckDetails.dob === '') {
    //     alert("Enter Date of Birth")
    //     document.getElementById("dob").focus();
    //     return;
    //   }

    //   if (creditCheckDetails.pancardNo === '') {
    //     alert("Enter Pan Card Number")
    //     document.getElementById("pancardNo").focus();
    //     return;
    //   }
    // }

    var jsonData = JSON.stringify({
      "name": state.name,
      "number": state.number,
      "email": state.email,
      "address": state.address,
      "clientId": clientId,
      "vEducationStatus": state.vEducationStatus,
      "vEducation": [{
          courseDegree: educationDetails.courseDegree,
          college: educationDetails.college,
          days: educationDetails.days,
          enrollmentNo: educationDetails.enrollmentNo,
          yearOfPassing: educationDetails.yearOfPassing,
          verifier: educationDetails.verifier,
          contactNo: educationDetails.contactNo,
          modeOfVerification: educationDetails.modeOfVerification,
          remarks: educationDetails.remarks,
          image1: educationDetails.image1
        }],
        "vEmploymentStatus": state.vEmploymentStatus,
        "vEmployment": [{
          companyName: employmentDetails.companyName,
          tenure: employmentDetails.tenure,
          designation: employmentDetails.designation,
          employeeCode: employmentDetails.employeeCode,
          salary: employmentDetails.salary,
          reportingManager: employmentDetails.reportingManager,
          duties: employmentDetails.duties,
          issues: employmentDetails.issues,
          performanceAtWork: employmentDetails.performanceAtWork,
          reasonForLeaving: employmentDetails.reasonForLeaving,
          eligibilityToRetire: employmentDetails.eligibilityToRetire,
          verifier: employmentDetails.verifier,
          contactNo: employmentDetails.contactNo,
          modeOfVerification: employmentDetails.modeOfVerification,
          remarks: employmentDetails.remarks,
          image1: employmentDetails.image1
        }],
        "vAddressStatus": state.vAddressStatus,
        "vAddress": [{
          name: addressDetails.name,
          fatherName: addressDetails.fatherName,
          dob: addressDetails.dob,
          confirmationAddress: addressDetails.confirmationAddress,
          typeOfAddress: addressDetails.typeOfAddress,
          contactNumber: addressDetails.contactNumber,
          periodOfStay: addressDetails.periodOfStay,
          typeOfProperty: addressDetails.typeOfProperty,
          idProof: addressDetails.idProof,
          specialComments: addressDetails.specialComments,
          image1: addressDetails.image1
        }],
        "vCriminalStatus": state.vCriminalStatus,
        "vCriminal": [{
          candidateName: '',
          fatherName: criminalDetails.fatherName,
          dob: criminalDetails.dob,
          magistrate: criminalDetails.magistrate,
          sessions: criminalDetails.sessions,
          highCourt: criminalDetails.highCourt,
          durationOfRecordsChecked: criminalDetails.durationOfRecordsChecked,
        }],
        "vIdentityStatus": state.vIdentityStatus,
        "vIdentity": [{
          fullName: identityDetails.fullName,
          fatherName: identityDetails.fatherName,
          dob: identityDetails.dob,
          pancardNo: identityDetails.pancardNo,
          image1: identityDetails.image1
        }],
        "vReferenceStatus": state.vReferenceStatus,
        "vReference": [{
          respondentName: referenceDetails.respondentName,
          designation: referenceDetails.designation,
          organisation: referenceDetails.organisation,
        }],
        "vDatabaseStatus": state.vDatabaseStatus,
        "vSocialMediaStatus": state.vSocialMediaStatus,
        "vDrugTestStatus": state.vDrugTestStatus,
        "vDrugTest": [{
          name: drugTestDetails.name,
          dob: drugTestDetails.dob,
          bloodSamplesCollected: drugTestDetails.bloodSamplesCollected,
          collectionLocation: drugTestDetails.collectionLocation,
          testResult: drugTestDetails.testResult
        }],
        "vCreditCheckStatus": state.vCreditCheckStatus,
        "vCreditCheck": [{
          name: creditCheckDetails.name,
          dob: creditCheckDetails.dob,
          mobileNumber: creditCheckDetails.mobileNumber,
          address: creditCheckDetails.address,
          pancardNo: creditCheckDetails.pancardNo
        }],
        "vPoliceVerificationStatus": state.vPoliceVerificationStatus,
        "vPoliceVerification": [{
          name: policeVerificationDetails.name,
          fatherName: policeVerificationDetails.fatherName,
          dob: policeVerificationDetails.dob,
          address: policeVerificationDetails.address,
          civil: policeVerificationDetails.civil,
          magistrate: policeVerificationDetails.magistrate,
          sessions: policeVerificationDetails.sessions,
          highCourt: policeVerificationDetails.highCourt,
        }],
        "status": "Pending",
      });

      var config = {
        method: 'post',
        url: API_BASE_URL + "/api/verification",
        headers: {
          'apikey': API_KEY,
          'Authorization': 'Bearer ' + getCookie('at'),
          'Content-Type': 'application/json'
        },
        data: jsonData
      };

    axios(config)
      .then(function (response) {
        if (response.status === 201) {
          defaultState()
          defaultEducationDetails()
          defaultEmploymentDetails()
          defaultAddressDetails()
          defaultCriminalDetails()
          defaultPoliceVerificationDetails()
          defaultReferenceDetails()
          defaultIdentityDetails()
          defaultDrugTestDetails()
          defaultCreditCheckDetails()
          alert("Added Successfully")
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });
  }

  const handleUpdate = () => {
    if(state.verificationStatus !== "Pending") {
      alert("Verification already moved to next state")
      return;
    }
    if (state.name === '') {
      alert("Enter Full Name")
      document.getElementById("name").focus();
      return;
    }

    if (state.number === '') {
      alert("Enter Mobile Number")
      document.getElementById("number").focus();
      return;
    }

    if (state.email === '') {
      alert("Enter Email")
      document.getElementById("email").focus();
      return;
    }

    if (state.address === '') {
      alert("Enter Address")
      document.getElementById("address").focus();
      return;
    }

    // let clientId = ""

    // if (userType == "WEB") {
    //   if (state.clientId === '') {
    //     alert("Selct Client")
    //     document.getElementById("clientId").focus();
    //     return;
    //   }
    //   clientId = state.clientId
    // } else {
    //   clientId = localStorage.getItem("id")
    // }

    let pushId = '';
    if(getByVerificarionNumber) {
      pushId = getByVerificarionNumber._id
    } else {
      pushId = state.pushId
    }

    var jsonData = JSON.stringify({
      "name": state.name,
      "number": state.number,
      "email": state.email,
      "address": state.address,
      // "clientId": clientId,
      "vEducationStatus": state.vEducationStatus,
      "vEducation": [{
          courseDegree: educationDetails.courseDegree,
          college: educationDetails.college,
          days: educationDetails.days,
          enrollmentNo: educationDetails.enrollmentNo,
          yearOfPassing: educationDetails.yearOfPassing,
          verifier: educationDetails.verifier,
          contactNo: educationDetails.contactNo,
          modeOfVerification: educationDetails.modeOfVerification,
          remarks: educationDetails.remarks,
          image1: educationDetails.image1
        }],
        "vEmploymentStatus": state.vEmploymentStatus,
        "vEmployment": [{
          companyName: employmentDetails.companyName,
          tenure: employmentDetails.tenure,
          designation: employmentDetails.designation,
          employeeCode: employmentDetails.employeeCode,
          salary: employmentDetails.salary,
          reportingManager: employmentDetails.reportingManager,
          duties: employmentDetails.duties,
          issues: employmentDetails.issues,
          performanceAtWork: employmentDetails.performanceAtWork,
          reasonForLeaving: employmentDetails.reasonForLeaving,
          eligibilityToRetire: employmentDetails.eligibilityToRetire,
          verifier: employmentDetails.verifier,
          contactNo: employmentDetails.contactNo,
          modeOfVerification: employmentDetails.modeOfVerification,
          remarks: employmentDetails.remarks,
          image1: employmentDetails.image1
        }],
        "vAddressStatus": state.vAddressStatus,
        "vAddress": [{
          name: addressDetails.name,
          fatherName: addressDetails.fatherName,
          dob: addressDetails.dob,
          confirmationAddress: addressDetails.confirmationAddress,
          typeOfAddress: addressDetails.typeOfAddress,
          contactNumber: addressDetails.contactNumber,
          periodOfStay: addressDetails.periodOfStay,
          typeOfProperty: addressDetails.typeOfProperty,
          idProof: addressDetails.idProof,
          specialComments: addressDetails.specialComments,
          image1: addressDetails.image1
        }],
        "vCriminalStatus": state.vCriminalStatus,
        "vCriminal": [{
          candidateName: '',
          fatherName: criminalDetails.fatherName,
          dob: criminalDetails.dob,
          magistrate: criminalDetails.magistrate,
          sessions: criminalDetails.sessions,
          highCourt: criminalDetails.highCourt,
          durationOfRecordsChecked: criminalDetails.durationOfRecordsChecked,
        }],
        "vIdentityStatus": state.vIdentityStatus,
        "vIdentity": [{
          fullName: identityDetails.fullName,
          fatherName: identityDetails.fatherName,
          dob: identityDetails.dob,
          pancardNo: identityDetails.pancardNo,
          image1: identityDetails.image1
        }],
        "vReferenceStatus": state.vReferenceStatus,
        "vReference": [{
          respondentName: referenceDetails.respondentName,
          designation: referenceDetails.designation,
          organisation: referenceDetails.organisation,
        }],
        "vDatabaseStatus": state.vDatabaseStatus,
        "vSocialMediaStatus": state.vSocialMediaStatus,
        "vDrugTestStatus": state.vDrugTestStatus,
        "vDrugTest": [{
          name: drugTestDetails.name,
          dob: drugTestDetails.dob,
          bloodSamplesCollected: drugTestDetails.bloodSamplesCollected,
          collectionLocation: drugTestDetails.collectionLocation,
          testResult: drugTestDetails.testResult
        }],
        "vCreditCheckStatus": state.vCreditCheckStatus,
        "vCreditCheck": [{
          name: creditCheckDetails.name,
          dob: creditCheckDetails.dob,
          mobileNumber: creditCheckDetails.mobileNumber,
          address: creditCheckDetails.address,
          pancardNo: creditCheckDetails.pancardNo
        }],
        "vPoliceVerificationStatus": state.vPoliceVerificationStatus,
        "vPoliceVerification": [{
          name: policeVerificationDetails.name,
          fatherName: policeVerificationDetails.fatherName,
          dob: policeVerificationDetails.dob,
          address: policeVerificationDetails.address,
          civil: policeVerificationDetails.civil,
          magistrate: policeVerificationDetails.magistrate,
          sessions: policeVerificationDetails.sessions,
          highCourt: policeVerificationDetails.highCourt,
        }],
    });

    let url = "";
    if(createVerificationLink) {
      url = API_BASE_URL + "/auth/verificationLink/" + pushId
    } else {
      url = API_BASE_URL + "/api/verification/" + pushId
    }

    var config = {
      method: 'put',
      url: url,
      headers: {
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at'),
        'Content-Type': 'application/json'
      },
      data: jsonData
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          defaultState()
          alert("Updated Successfully")
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        handleNon200Response(error)
      });

    setUpdateEnabled(false);
    if(!createVerificationLink) {
      document.getElementById("submit").style.display = "block"
      document.getElementById("update").style.display = "none"
    }
  }

  const editRow = (key) => {
    setUpdateEnabled(true)
    document.getElementById("submit").style.display = "none"
    document.getElementById("update").style.display = "block"

    setState({
      ...state,
      name: key.name,
      number: key.number,
      email: key.email,
      address: key.address,
      clientId: key.clientId,
      vEducationStatus: key.vEducationStatus,
      vEmploymentStatus: key.vEmploymentStatus,
      vAddressStatus: key.vAddressStatus,
      vCriminalStatus: key.vCriminalStatus,
      vIdentityStatus: key.vIdentityStatus,
      vReferenceStatus: key.vReferenceStatus,
      vDatabaseStatus: key.vDatabaseStatus,
      vSocialMediaStatus: key.vSocialMediaStatus,
      vDrugTestStatus: key.vDrugTestStatus,
      vCreditCheckStatus: key.vCreditCheckStatus,
      vPoliceVerificationStatus: key.vPoliceVerificationStatus,
      pushId: key._id
    })
  }

  const deleteRow = (key) => {
    if (window.confirm('Are you sure you want to delete?')) {
      var config = {
        method: 'delete',
        url: API_BASE_URL + '/api/verification/' + key._id,
        headers: {
          'apikey': API_KEY,
          'Authorization': 'Bearer ' + getCookie('at')
        },
      };

      axios(config)
        .then(function (response) {
          if (response.status == 200) {
            defaultState()
            alert("Deleted Successfully")
          }
        })
        .catch(function (error) {
          console.log(error)
          handleNon200Response(error)
        });
    } else { }
  }
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Create Verification"}
          parent={"Verification"}
          title={"Verification"}
          createVerificationLink={createVerificationLink}
        />
        <Col xl={12}>
          <Card className="height-equal">
            {/* <CommonHeader
                title={"BrowserDefaultsHeading"}
                subTitle={subTitle}
                headClass="pb-0"
                /> */}
            <CardBody className="custom-input">
              {/* <Form> */}
              <Row className="g-3">
                <Col xs={12}>
                  <Label htmlFor="first-name"> {"Full Name"}</Label>
                  <Input
                    type="text"
                    placeholder="Name"
                    value={state.name}
                    id="name"
                    name="name"
                    onChange={handleChange}
                    disabled = {createVerificationLink?  true: false}
                    required
                  />
                </Col>
                <Col xs={12}>
                  <Label htmlFor="first-name"> {"Mobile Number"}</Label>
                  <Input
                    type="number"
                    placeholder="Number"
                    value={state.number}
                    id="number"
                    name="number"
                    onChange={handleChange}
                    disabled = {createVerificationLink?  true: false}
                    required
                  />
                </Col>
                <Col xs={12}>
                  <Label>{"Email"}</Label>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={state.email}
                    id="email"
                    name="email"
                    onChange={handleChange}
                    disabled = {createVerificationLink?  true: false}
                    required
                  />
                </Col>
                <Col xs={12}>
                  <Label>{"Address"}</Label>
                  <Input
                    type="text"
                    placeholder="Address"
                    value={state.address}
                    id="address"
                    name="address"
                    onChange={handleChange}
                    disabled = {createVerificationLink?  true: false}
                    required
                  />
                </Col>
                {userType == "WEB" && !getByVerificarionNumber ?
                  <Col xs={12}>
                    <Label>Client</Label>
                    <Input
                      type="select"
                      placeholder="Client"
                      value={state.clientId}
                      id="clientId"
                      name="clientId"
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select</option>
                      {clientData.map((rowData, index) => (
                        <option value={rowData._id}>{rowData.clientName}</option>
                      ))}
                    </Input>
                    </Col> : null}


                {(!createVerificationLink ||
                  getByVerificarionNumber?.vEducationStatus) && (
                    <Col xs={12}>
                      <input
                        type="checkbox"
                        checked={state.vEducationStatus}
                        onChange={checkBoxOnChange}
                        name="vEducationStatus"
                      />&nbsp;
                      <Label>Education</Label>
                    </Col>
                )}

                {state.vEducationStatus && !updateEnabled ? (
                  <Row className="g-3">
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Course Degree"}</Label>
                      <Input
                        type="text"
                        placeholder="Course Degree"
                        value={educationDetails.courseDegree}
                        id="courseDegree"
                        name="courseDegree"
                        onChange={handleEducationChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"College"}</Label>
                      <Input
                        type="text"
                        placeholder="College"
                        value={educationDetails.college}
                        id="college"
                        name="college"
                        onChange={handleEducationChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Dates Attended"}</Label>
                      <Input
                        type="text"
                        placeholder="Dates Attended"
                        value={educationDetails.days}
                        id="days"
                        name="days"
                        onChange={handleEducationChange}
                      />
                    </Col>
                    <Col xs={6}>
                    <Label htmlFor="first-name"> {"Enrollment No/Roll No"}</Label>
                      <Input
                        type="text"
                        placeholder="Enrollment No"
                        value={educationDetails.enrollmentNo}
                        id="enrollmentNo"
                        name="enrollmentNo"
                        onChange={handleEducationChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Year of Passing"}</Label>
                      <Input
                        type="text"
                        placeholder="Year of Passing"
                        value={educationDetails.yearOfPassing}
                        id="yearOfPassing"
                        name="yearOfPassing"
                        onChange={handleEducationChange}
                      />
                    </Col>
                    <Col xs={6}>
                    <Label htmlFor="first-name"> {"Upload Marks Card Image"}</Label>
                      <Input
                        type="file"
                        id="image1"
                        name="image1"
                        onChange={uploadMarksCard}
                      />
                    </Col>
                  </Row>
                ) : null}
                <br />

                {(!createVerificationLink ||
                  getByVerificarionNumber?.vEmploymentStatus) && (
                    <Col xs={12}>
                      <input
                        type="checkbox"
                        checked={state.vEmploymentStatus}
                        onChange={checkBoxOnChange}
                        name="vEmploymentStatus"
                      />
                      &nbsp;
                      <Label>Employemnt</Label>
                    </Col>
                )}

                {state.vEmploymentStatus && !updateEnabled ? (
                  <Row className="g-3">
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Company Name"}</Label>
                      <Input
                        type="text"
                        placeholder="Company Name"
                        value={employmentDetails.companyName}
                        id="companyName"
                        name="companyName"
                        onChange={handleEmploymentChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Tenure"}</Label>
                      <Input
                        type="text"
                        placeholder="Tenure"
                        value={employmentDetails.tenure}
                        id="tenure"
                        name="tenure"
                        onChange={handleEmploymentChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Designation"}</Label>
                      <Input
                        type="text"
                        placeholder="Designation"
                        value={employmentDetails.designation}
                        id="designation"
                        name="designation"
                        onChange={handleEmploymentChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Employee Code"}</Label>
                      <Input
                        type="text"
                        placeholder="Employee Code"
                        value={employmentDetails.employeeCode}
                        id="employeeCode"
                        name="employeeCode"
                        onChange={handleEmploymentChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Salary"}</Label>
                      <Input
                        type="text"
                        placeholder="Salary"
                        value={employmentDetails.salary}
                        id="salary"
                        name="salary"
                        onChange={handleEmploymentChange}
                      />
                    </Col>
                    <Col xs={6}>
                    <Label htmlFor="first-name"> {"Supervisor/Reporting Manager"}</Label>
                      <Input
                        type="text"
                        placeholder="Supervisor/Reporting Manager"
                        value={employmentDetails.reportingManager}
                        id="reportingManager"
                        name="reportingManager"
                        onChange={handleEmploymentChange}
                      />
                    </Col>
                    <Col xs={6}>
                    <Label htmlFor="first-name"> {"Upload Id Card Image"}</Label>
                      <Input
                        type="file"
                        id="image1"
                        name="image1"
                        onChange={uploadIdCard}
                      />
                    </Col>
                  </Row>
                ) : null}
                <br />

                {(!createVerificationLink ||
                  getByVerificarionNumber?.vAddressStatus) && (  
                    <Col xs={12}>
                      <input
                        type="checkbox"
                        checked={state.vAddressStatus}
                        onChange={checkBoxOnChange}
                        name="vAddressStatus"
                      />
                      &nbsp;
                      <Label>Address</Label>
                    </Col>
                )}

                {state.vAddressStatus && !updateEnabled ? (
                  <Row className="g-3">
                    <Col xs={6}>
                    <Label htmlFor="first-name"> {"Name of the Candidate"}</Label>
                      <Input
                        type="text"
                        placeholder="Name of the Candidate"
                        value={addressDetails.name}
                        id="name"
                        name="name"
                        onChange={handleAddressChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Father Name"}</Label>
                      <Input
                        type="text"
                        placeholder="Father Name"
                        value={addressDetails.fatherName}
                        id="fatherName"
                        name="fatherName"
                        onChange={handleAddressChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Date of Birth"}</Label>
                      <Input
                        type="date"
                        placeholder="Date of Birth"
                        value={addressDetails.dob}
                        id="dob"
                        name="dob"
                        onChange={handleAddressChange}
                      />
                    </Col>
                    <Col xs={6}>
                    <Label htmlFor="first-name"> {"Confimation of Address ( Post office, Pin No)"}</Label>
                      <Input
                        type="text"
                        placeholder="Confimation of Address ( Post office, Pin No)"
                        value={addressDetails.confirmationAddress}
                        id="confirmationAddress"
                        name="confirmationAddress"
                        onChange={handleAddressChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Type of Address"}</Label>
                      <Input
                        id="typeOfAddress"
                        name="typeOfAddress"
                        type="select"
                        value={addressDetails.typeOfAddress}
                        onChange={handleAddressChange}
                      >
                        <option value="">Select</option>
                        <option value="Present">Present</option>
                        <option value="Permanent">Permanent</option>
                        <option value="Previous">Previous</option>
                      </Input>
                    </Col>
                    <Col xs={6}>
                    <Label htmlFor="first-name"> {"Upload Id Card Image"}</Label>
                      <Input
                        type="file"
                        id="image1"
                        name="image1"
                        onChange={uploadIdCardForAddress}
                      />
                    </Col>
                  </Row>
                ) : null}
                <br />

                {(!createVerificationLink ||
                  getByVerificarionNumber?.vCriminalStatus) && (
                    <Col xs={12}>
                      <input
                        type="checkbox"
                        checked={state.vCriminalStatus}
                        onChange={checkBoxOnChange}
                        name="vCriminalStatus"
                      />
                      &nbsp;
                      <Label>Criminal</Label>
                    </Col>
                )}

                {state.vCriminalStatus && !updateEnabled ? (
                  <Row className="g-3">
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Father Name"}</Label>
                      <Input
                        type="text"
                        placeholder="Father Name"
                        value={criminalDetails.fatherName}
                        id="fatherName"
                        name="fatherName"
                        onChange={handleCriminalChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Date of Birth"}</Label>
                      <Input
                        type="date"
                        placeholder="Date of Birth"
                        value={criminalDetails.dob}
                        id="dob"
                        name="dob"
                        onChange={handleCriminalChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Magistrate"}</Label>
                      <Input
                        type="text"
                        placeholder="Magistrate"
                        value={criminalDetails.magistrate}
                        id="magistrate"
                        name="magistrate"
                        onChange={handleCriminalChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Sessions"}</Label>
                      <Input
                        type="text"
                        placeholder="Sessions"
                        value={criminalDetails.sessions}
                        id="sessions"
                        name="sessions"
                        onChange={handleCriminalChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"High Court"}</Label>
                      <Input
                        type="text"
                        placeholder="High Court"
                        value={criminalDetails.highCourt}
                        id="highCourt"
                        name="highCourt"
                        onChange={handleCriminalChange}
                      />
                    </Col>
                    <Col xs={6}>
                    <Label htmlFor="first-name"> {"Duration of Records Checked"}</Label>
                      <Input
                        type="text"
                        placeholder="Duration of Records Checked"
                        value={criminalDetails.durationOfRecordsChecked}
                        id="durationOfRecordsChecked"
                        name="durationOfRecordsChecked"
                        onChange={handleCriminalChange}
                      />
                    </Col>
                  </Row>
                ) : null}
                <br />

                {(!createVerificationLink ||
                  getByVerificarionNumber?.vIdentityStatus) && (
                    <Col xs={12}>
                      <input
                        type="checkbox"
                        checked={state.vIdentityStatus}
                        onChange={checkBoxOnChange}
                        name="vIdentityStatus"
                      />
                      &nbsp;
                      <Label>Identity</Label>
                    </Col>
                )}

                {state.vIdentityStatus && !updateEnabled ? (
                  <Row className="g-3">
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Full Name"}</Label>
                      <Input
                        type="text"
                        placeholder="Full Name"
                        value={identityDetails.fullName}
                        id="fullName"
                        name="fullName"
                        onChange={handleIdentityChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Father Name"}</Label>
                      <Input
                        type="text"
                        placeholder="Father Name"
                        value={identityDetails.fatherName}
                        id="fatherName"
                        name="fatherName"
                        onChange={handleIdentityChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Date of Birth"}</Label>
                      <Input
                        type="date"
                        placeholder="Date of Birth"
                        value={identityDetails.dob}
                        id="dob"
                        name="dob"
                        onChange={handleIdentityChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Pancard No"}</Label>
                      <Input
                        type="text"
                        placeholder="Pancard No"
                        value={identityDetails.pancardNo}
                        id="pancardNo"
                        name="pancardNo"
                        onChange={handleIdentityChange}
                      />
                    </Col>
                    <Col xs={6}>
                    <Label htmlFor="first-name"> {"Upload PAN Card Image"}</Label>
                      <Input
                        type="file"
                        id="image1"
                        name="image1"
                        onChange={uploadPancardImage}
                      />
                    </Col>
                  </Row>
                ) : null}
                <br />

                {(!createVerificationLink ||
                  getByVerificarionNumber?.vReferenceStatus) && (
                    <Col xs={12}>
                      <input
                        type="checkbox"
                        checked={state.vReferenceStatus}
                        onChange={checkBoxOnChange}
                        name="vReferenceStatus"
                      />
                      &nbsp;
                      <Label>Reference</Label>
                    </Col>
                )}
    
                {state.vReferenceStatus && !updateEnabled ?
                  <Row className="g-3">
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Respondent Name"}</Label>
                      <Input
                        type="text"
                        placeholder="Responden Name"
                        value={referenceDetails.respondentName}
                        id="respondentName"
                        name="respondentName"
                        onChange={handleReferenceChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Designation"}</Label>
                      <Input
                        type="text"
                        placeholder="Designation"
                        value={referenceDetails.designation}
                        id="designation"
                        name="designation"
                        onChange={handleReferenceChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Organisation"}</Label>
                      <Input
                        type="text"
                        placeholder="Organisation"
                        value={referenceDetails.organisation}
                        id="organisation"
                        name="organisation"
                        onChange={handleReferenceChange}
                      />
                    </Col>
                  </Row> : null}
                {/* <Col xs={12}>
                  <input
                    type="checkbox"
                    checked={state.vDatabaseStatus}
                    onChange={checkBoxOnChange}
                    name="vDatabaseStatus"
                  />&nbsp;
                  <Label>Database</Label>
                </Col> */}
                {/* <Col xs={12}>
                  <input
                    type="checkbox"
                    checked={state.vSocialMediaStatus}
                    onChange={checkBoxOnChange}
                    name="vSocialMediaStatus"
                  />&nbsp;
                  <Label>Social Media</Label>
                </Col> */}

                {(!createVerificationLink ||
                  getByVerificarionNumber?.vDrugTestStatus) && (
                    <Col xs={12}>
                      <input
                        type="checkbox"
                        checked={state.vDrugTestStatus}
                        onChange={checkBoxOnChange}
                        name="vDrugTestStatus"
                      />
                      &nbsp;
                      <Label>Drug Test</Label>
                    </Col>
                )}
    
                {state.vDrugTestStatus && !updateEnabled ? (
                  <Row className="g-3">
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Name"}</Label>
                      <Input
                        type="text"
                        placeholder="Name"
                        value={drugTestDetails.name}
                        id="name"
                        name="name"
                        onChange={handleDrugTestChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Date of Birth"}</Label>
                      <Input
                        type="date"
                        placeholder="Date of Birth"
                        value={drugTestDetails.dob}
                        id="dob"
                        name="dob"
                        onChange={handleDrugTestChange}
                      />
                    </Col>
                    <Col xs={6}>
                    <Label htmlFor="first-name"> {"Blood Samples Collected"}</Label>
                      <Input
                        type="text"
                        placeholder="Blood Samples Collected"
                        value={drugTestDetails.bloodSamplesCollected}
                        id="bloodSamplesCollected"
                        name="bloodSamplesCollected"
                        onChange={handleDrugTestChange}
                      />
                    </Col>
                    <Col xs={6}>
                    <Label htmlFor="first-name"> {"Collection Location"}</Label>
                      <Input
                        type="text"
                        placeholder="Collection Location"
                        value={drugTestDetails.collectionLocation}
                        id="collectionLocation"
                        name="collectionLocation"
                        onChange={handleDrugTestChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Test Result"}</Label>
                      <Input
                        type="text"
                        placeholder="Test Result"
                        value={drugTestDetails.testResult}
                        id="testResult"
                        name="testResult"
                        onChange={handleDrugTestChange}
                      />
                    </Col>
                  </Row>
                ) : null}
                <br />

                {(!createVerificationLink ||
                  getByVerificarionNumber?.vCreditCheckStatus) && (
                    <Col xs={12}>
                      <input
                        type="checkbox"
                        checked={state.vCreditCheckStatus}
                        onChange={checkBoxOnChange}
                        name="vCreditCheckStatus"
                      />
                      &nbsp;
                      <Label>Credit Check</Label>
                    </Col>
                )}

                {state.vCreditCheckStatus && !updateEnabled ? (
                  <Row className="g-3">
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Name"}</Label>
                      <Input
                        type="text"
                        placeholder="Name"
                        value={creditCheckDetails.name}
                        id="name"
                        name="name"
                        onChange={handleCreditCheckChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Date of Birth"}</Label>
                      <Input
                        type="date"
                        placeholder="Date of Birth"
                        value={creditCheckDetails.dob}
                        id="dob"
                        name="dob"
                        onChange={handleCreditCheckChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Mobile Number"}</Label>
                      <Input
                        type="number"
                        placeholder="Mobile Number"
                        value={creditCheckDetails.mobileNumber}
                        id="mobileNumber"
                        name="mobileNumber"
                        onChange={handleCreditCheckChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Address"}</Label>
                      <Input
                        type="text"
                        placeholder="Address"
                        value={creditCheckDetails.address}
                        id="address"
                        name="address"
                        onChange={handleCreditCheckChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Pancard No"}</Label>
                      <Input
                        type="text"
                        placeholder="Pancard No"
                        value={creditCheckDetails.pancardNo}
                        id="pancardNo"
                        name="pancardNo"
                        onChange={handleCreditCheckChange}
                      />
                    </Col>
                  </Row>
                ) : null}
                <br />

                {(!createVerificationLink ||
                  getByVerificarionNumber?.vPoliceVerificationStatus) && (
                    <Col xs={12}>
                      <input
                        type="checkbox"
                        checked={state.vPoliceVerificationStatus}
                        onChange={checkBoxOnChange}
                        name="vPoliceVerificationStatus"
                      />
                      &nbsp;
                      <Label>Police Verification</Label>
                    </Col>
                )}

                {state.vPoliceVerificationStatus && !updateEnabled ? (
                  <Row className="g-3">
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Name"}</Label>
                      <Input
                        type="text"
                        placeholder="Name"
                        value={policeVerificationDetails.name}
                        id="name"
                        name="name"
                        onChange={handlePoliceVerificationChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Father Name"}</Label>
                      <Input
                        type="text"
                        placeholder="Father Name"
                        value={policeVerificationDetails.fatherName}
                        id="fatherName"
                        name="fatherName"
                        onChange={handlePoliceVerificationChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Date of Birth"}</Label>
                      <Input
                        type="date"
                        placeholder="Date of Birth"
                        value={policeVerificationDetails.dob}
                        id="dob"
                        name="dob"
                        onChange={handlePoliceVerificationChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Address"}</Label>
                      <Input
                        type="text"
                        placeholder="Address"
                        value={policeVerificationDetails.address}
                        id="address"
                        name="address"
                        onChange={handlePoliceVerificationChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Civil"}</Label>
                      <Input
                        type="text"
                        placeholder="Civil"
                        value={policeVerificationDetails.civil}
                        id="civil"
                        name="civil"
                        onChange={handlePoliceVerificationChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Magistrate"}</Label>
                      <Input
                        type="text"
                        placeholder="Magistrate"
                        value={policeVerificationDetails.magistrate}
                        id="magistrate"
                        name="magistrate"
                        onChange={handlePoliceVerificationChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"Sessions"}</Label>
                      <Input
                        type="text"
                        placeholder="Sessions"
                        value={policeVerificationDetails.sessions}
                        id="sessions"
                        name="sessions"
                        onChange={handlePoliceVerificationChange}
                      />
                    </Col>
                    <Col xs={6}>
                      <Label htmlFor="first-name"> {"High Court"}</Label>
                      <Input
                        type="text"
                        placeholder="High Court"
                        value={policeVerificationDetails.highCourt}
                        id="highCourt"
                        name="highCourt"
                        onChange={handlePoliceVerificationChange}
                      />
                    </Col>
                  </Row>
                ) : null}
                <br />

                <Col xs={12}>
                {createVerificationLink ?
                   <Button color="primary" type="submit" id="update" onClick={handleUpdate}>
                   Update
                 </Button> : null }
                 {!createVerificationLink ? <>
                <Button color="primary" type="submit" id="submit" onClick={handleSubmit}>
                    Submit
                  </Button>
                  <Button color="primary" type="submit" id="update" onClick={handleUpdate} style={{ display: "none" }}>
                    Update
                  </Button> </> : null }
                </Col>
              </Row>
              {/* </Form> */}
            </CardBody>
          </Card>
        </Col>
        <br />
        {!createVerificationLink &&
          <Col xl={12}>
            <Card className="height-equal">
              <CardBody className="custom-input">
                <Table className="" style={{ overflowX: "scroll" }}>
                  <thead>
                    <tr>
                      <th>Verification Id</th>
                      <th>Name</th>
                      <th>Number</th>
                      <th>Email</th>
                      <th>Address</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                  {apiData.map((rowData, index) => (
                    <tr key={index}>
                      <td>{rowData.verificationNumber}</td>
                      <td>{rowData.name}</td>
                      <td>{rowData.number}</td>
                      <td>{rowData.email}</td>
                      <td>{rowData.address}</td>
                      <td onClick={() => editRow(rowData)}><FontAwesomeIcon style={{ color: "black", fontSize: "12px", cursor: "pointer" }} icon={faEdit} /></td>
                      <td onClick={() => deleteRow(rowData)}><FontAwesomeIcon style={{ color: "red", fontSize: "12px", cursor: "pointer" }} icon={faTrash} /></td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        }
      </Container>
    </>
  );
};

export default CreateVerification;
