import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { H3 } from "../../../../AbstractElements";
import { Table } from "reactstrap";
import { Button } from "reactstrap";
import { recentOrdersBody } from "../../../../Data/Dashboard/ShoppingPlace/RecentOrders";
import { H4, Image } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { Link } from "react-router-dom";
import { Href } from "../../../../utils/Constant";
import { API_BASE_URL, API_KEY, getCookie, sessionExpired } from "../../../Helper/utils";
import axios from "axios";
import { useEffect, useState } from 'react';

const RecentOrders = () => {
    const [clientName, setClientName] = useState([]);
    const [clientId, setClientId] = useState([]);
    const [casesByCompanyData, setcasesByCompanyData] = useState([]);

    const recentOrdersHeader = [
        "Client Name", "Pending Verification", "In Progress Verificaion", "Completed Verifications", "Total Verifications"
    ]

    useEffect(() => {
        fetchClient();
        fetchList();
    }, [])

    const userType = localStorage.getItem("type")

    const fetchClient = () => {
        let clientId = []
        let clientName = [];
        var config = {
            method: 'get',
            url: API_BASE_URL + '/api/client',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
            }
        };

        axios(config)
            .then(function (response) {
                for (let i = 0; i < response.data.length; i++) {
                    clientId.push(response.data[i]._id)
                    clientName.push(response.data[i].clientName)
                }
                setClientId(clientId)
                setClientName(clientName)
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
                handleNon200Response(error);
            });
    }

    const handleNon200Response = (error) => {
        if (error.response.status === 401 && error.response.data.message === "User Not authenticated") {
            sessionExpired();
        }
    }


    const fetchList = () => {
        let jsonData = {}
        if (userType == "CLIENT") {
            jsonData.clientId = localStorage.getItem("id");
        }
        let jsonData1 = JSON.stringify(jsonData)
        let config = {
            method: 'post',
            url: API_BASE_URL + '/api/dashboard/verificationByClient',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
                'Content-Type': 'application/json'
            },
            data: jsonData1
        };

        axios(config)
            .then(function (response) {
                console.log("response.data", response.data);
                setcasesByCompanyData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <Col xl="6">
            <Card className="invoice-card">
                <CardHeader className="pb-0">
                    <H3>Cases By Company</H3>
                </CardHeader>
                <CardBody className="transaction-card">
                    <div className="table-responsive theme-scrollbar recent-wrapper">
                        <Table className="display order-wrapper" id="recent-order">
                            <thead>
                                <tr>
                                    {recentOrdersHeader.map((data, index) => (
                                        <th key={index}>{data}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                            {casesByCompanyData.map((rowData, index) => (
                                <tr key={index}>
                                <td>{clientName[clientId.indexOf(rowData._id)]}</td>
                                <td>{rowData.pendingVerifcation}</td>
                                <td>{rowData.inProgressVerification}</td>
                                <td>{rowData.completedVerification}</td>
                                <td>{rowData.totalVerifications}</td>
                            </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default RecentOrders;
