import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";

const SellerData = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Seller Data"}
          parent={"Report"}
          title={"Seller Data"}
        />
        {/* <Row></Row> */}
      </Container>
    </>
  );
};

export default SellerData;
