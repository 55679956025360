import { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Table, Label, Input, Button } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, sessionExpired } from "../Helper/utils";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const AgentReport = () => {
    const [apiData, setApiData] = useState([]);
    const [state, setState] = useState({
        startDate: '',
        endDate: ''
    })

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }


    useEffect(() => {
        fetchList();
    }, [])

    const fetchList = () => {
        let jsonData = {}
        let jsonData1 = JSON.stringify(jsonData)

        let config = {
            method: 'post',
            url: API_BASE_URL + '/api/agentsList',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
                'Content-Type': 'application/json'
            },
            data: jsonData1
        };

        axios(config)
            .then(function (response) {
                console.log("response.data", response.data);
                setApiData(response.data);
            })
            .catch(function (error) {
                console.log(error);
                handleNon200Response(error);
            });
    }

    const handleFilter = () => {

        if (state.startDate === '') {
            alert("Select Start Date")
            document.getElementById("startDate").focus();
            return;
        }

        if (state.endDate === '') {
            alert("Select End Date")
            document.getElementById("endDate").focus();
            return;
        }

        let jsonData = {};

        jsonData.startDate = state.startDate
        jsonData.endDate = state.endDate

        let jsonData1 = JSON.stringify(jsonData);

        let config = {
            method: 'post',
            url: API_BASE_URL + '/api/agentsList',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
                'Content-Type': 'application/json'
            },
            data: jsonData1
        };

        axios(config)
            .then(function (response) {
                console.log("response.data", response.data);
                setApiData(response.data);
            })
            .catch(function (error) {
                console.log(error);
                handleNon200Response(error);
            });
    }


    const handleNon200Response = (error) => {
        if (error.response.status === 401 && error.response.data.message === "User Not authenticated") {
            sessionExpired();
        }
    }

    const exportToCSV = (fileName) => {
        let config = {
            method: 'get',
            url: API_BASE_URL + '/api/agent',
            headers: {
                apikey: API_KEY,
                Authorization: "Bearer " + getCookie("at"),
                'Content-Type': 'application/json'
            },
        };


        axios(config)
            .then(function (response) {
                let excelData = []
                if (response.status == 200) {
                    for (let i = 0; i < response.data.length; i++) {
                        const excel = {
                            Name: response.data[i].name,
                            Number: response.data[i].number,
                            Email: response.data[i].email,
                            Password: response.data[i].password,
                            IdProof: response.data[i].idProof,
                            AddressProof: response.data[i].addressProof
                        }
                        excelData.push(excel)
                    }
                }

                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(excelData);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, fileName + fileExtension);
            })
            .catch(function (error) {
                console.log(error);
                handleNon200Response(error, "1");
            });
    }

    return (
        <>
            <Container className="basic_table" fluid>
                <Breadcrumbs
                    mainTitle={"Agent Report"}
                    parent={"Report"}
                    title={"Agent"}
                />
                <Col xl={12}>
                    <Card className="height-equal">
                        <CardBody className="custom-input">
                            <Row className="g-3">
                                <Col xs={3}>
                                    <Label htmlFor="first-name"> {"Start Date"}</Label>
                                    <Input
                                        type="date"
                                        placeholder="Start Date"
                                        value={state.startDate}
                                        id="startDate"
                                        name="startDate"
                                        onChange={handleChange}
                                        required
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Label>{"End Date"}</Label>
                                    <Input
                                        type="date"
                                        placeholder="End Date"
                                        value={state.endDate}
                                        id="endDate"
                                        name="endDate"
                                        onChange={handleChange}
                                        required
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs={10}>
                                    <Button color="primary" type="submit" id="submit" onClick={handleFilter}>
                                        Submit
                                    </Button>
                                </Col>
                                <Col xs={2}>
                                    <Button color="primary" type="submit" id="submit" onClick={() => exportToCSV("Agent Report")}>
                                        Excel
                                    </Button>
                                </Col>
                            </Row>
                            <br />
                            <Table responsive className="display order-wrapper" id="recent-order">
                                <thead>
                                    <tr>
                                        <th>Sl.No</th>
                                        <th>Name</th>
                                        <th>Number</th>
                                        <th>Email</th>
                                        <th>ID Proof</th>
                                        <th>Address Proof</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {apiData.map((rowData, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{rowData.name}</td>
                                            <td>{rowData.number}</td>
                                            <td>{rowData.email}</td>
                                            <td>{rowData.idProof}</td>
                                            <td><a href={rowData.adressProofImage} target="_blank">{rowData.adressProofImage}</a></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Container>
        </>
    );
};

export default AgentReport;
