import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import SellerForm from "../Forms/FormsControl/FormValidition/SellerForm/BrowserDefault";
import SellerTable from "./SellerTable/HoverableRowsClass";
import DropdownAddForm from "../Forms/FormsControl/FormValidition/DropdownAddForm/BrowserDefault";
import DropdownTable from "./DropdownMenuTable/HoverableRowsClass";

const PackageFormPage = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Package Form"}
          parent={"Manage"}
          title={"package"}
        />
        {/* <Row></Row> */}
        <DropdownAddForm />
        <DropdownTable data={[{ name: "A", status: "active" }]} />
      </Container>
    </>
  );
};

export default PackageFormPage;
