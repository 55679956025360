import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import BuyerForm from "../Forms/FormsControl/FormValidition/BuyerForm/BrowserDefault";
import BuyerTable from "./BuyerTable/HoverableRowsClass";

const BuyerFormPage = () => {
  return (
    <>
      <Container className="basic_table" fluid>
        <Breadcrumbs
          mainTitle={"Buyer Form"}
          parent={"Manage"}
          title={"Buyer"}
        />
        {/* <Row></Row> */}
        <BuyerForm />
        {/* <BuyerTable /> */}
      </Container>
    </>
  );
};

export default BuyerFormPage;
